/* ------------------------------------------------------------------------------
*
*  # Ladda progress buttons
*
*  Buttons with built-in loading indicators
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */


// Common styles
// ------------------------------

.btn-ladda {
    &,
    .ladda-spinner,
    .ladda-label {
        .transition(all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s);
    }

    // Spinner
    .ladda-spinner {
        position: absolute;
        z-index: 2;
        display: inline-block;
        top: 50%;
        pointer-events: none;
        .opacity(0);
    }

    // Label
    .ladda-label {
        z-index: 3;
    }

    // Progress bar
    .ladda-progress {
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        background-color: fade(#000, 20%);
        visibility: hidden;
        .transition(all linear 0.3s);
        .opacity(0);
    }
    &[data-loading] .ladda-progress{
        visibility: visible;
        .opacity(1);
    }
}


// Zoom animation
// ------------------------------

.btn-ladda {

    // Common
    &[data-style=zoom-in],
    &[data-style=zoom-out] {
        overflow: hidden;

        &,
        .ladda-spinner,
        .ladda-label {
            .transition(0.3s ease all);
        }

        // Label
        .ladda-label {
            position: relative;
            display: inline-block;
        }

        // Spinner
        .ladda-spinner {
            left: 50%;
        }

        // Loading
        &[data-loading] {
            .ladda-label {
                .opacity(0);
            }

            .ladda-spinner {
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
                transform: none;
                .opacity(1);
            }
        }
    }

    // Zoom in
    &[data-style=zoom-in] {
        .ladda-spinner {
            .scale(0.2);
        }

        &[data-loading] {
            .ladda-label {
                .scale(2.2);
            }
        }
    }

    // Zoom out
    &[data-style=zoom-out] {
        .ladda-spinner {
            .scale(2.5);
        }

        &[data-loading] {
            .ladda-label {
                .scale(0.5);
            }
        }
    }
}


// Expand animation
// ------------------------------

.btn-ladda {

    // Left
    &[data-style=expand-left] {
        overflow: hidden;

        .ladda-spinner {
            left: 20px;
        }

        &[data-loading] {
            padding-left: 35px;

            .ladda-spinner {
                .opacity(1);
            }
        }
    }

    // Right
    &[data-style=expand-right] {
        overflow: hidden;

        .ladda-spinner {
            right: 20px;
        }

        &[data-loading] {
            padding-right: 35px;

            .ladda-spinner {
                .opacity(1);
            }
        }
    }

    // Up
    &[data-style=expand-up] {
        overflow: hidden;

        .ladda-spinner {
            top: -16px;
            left: 50%;
        }

        &[data-loading] {
            padding-top: 40px;

            .ladda-spinner {
                top: 12px;
                margin-top: 8px;
                .opacity(1);
            }
        }
    }

    // Down
    &[data-style=expand-down] {
        overflow: hidden;

        .ladda-spinner {
            top: 44px;
            left: 50%;
        }
        &[data-loading] {
            padding-bottom: 40px;

            .ladda-spinner {
                .opacity(1);
            }
        }
    }
}


// Slide animation
// ------------------------------

.btn-ladda {

    // Left
    &[data-style=slide-left] {
        overflow: hidden;

        .ladda-label {
            left: 0;
            position: relative;
        }

        .ladda-spinner {
            right: 0;
        }

        &[data-loading] {
            .ladda-label {
                left: -100%;
                .opacity(0);
            }

            .ladda-spinner {
                right: 50%;
                .opacity(1);
            }
        }
    }

    // Right
    &[data-style=slide-right] {
        overflow: hidden;

        .ladda-label {
            right: 0;
            position: relative;
        }

        .ladda-spinner {
        left: 0;
        }

        &[data-loading] {
            .ladda-label {
                right: -100%;
                .opacity(0);
            }

            .ladda-spinner {
                left: 50%;
                .opacity(1);
            }
        }
    }

    // Up
    &[data-style=slide-up] {
        overflow: hidden;

        .ladda-label {
        top: 0;
        position: relative;
        }

        .ladda-spinner {
            top: 100%;
            left: 50%;
        }

        &[data-loading] {
            .ladda-label {
                top: -16px;
                .opacity(0);
            }

            .ladda-spinner {
                top: 16px;
                .opacity(1);
            }
        }
    }

    // Down
    &[data-style=slide-down] {
        overflow: hidden;

        .ladda-label {
            top: 0;
            position: relative;
        }

        .ladda-spinner {
            top: -100%;
            left: 50%;
        }

        &[data-loading] {
            .ladda-label {
                top: 16px;
                .opacity(0);
            }

            .ladda-spinner {
                top: 16px;
                .opacity(1);
            }
        }
    }
}


// Fade animation
// ------------------------------

.btn-ladda {
    &[data-style=fade] {
        overflow: hidden;

        .ladda-spinner {
            left: 50%;
        }

        &[data-loading] {
            .ladda-label {
                .opacity(0);
            }

            .ladda-spinner {
                .opacity(1);
            }
        }
    }
}


// Radius animation
// ------------------------------

.btn-ladda {
    &[data-style=radius] {
        overflow: hidden;
        border-radius: @border-radius-small;
        .transition(all ease-in-out 0.5s);

        .ladda-spinner {
            left: 50%;
        }

        &[data-loading] {
            border-radius: @border-radius-large;

            .ladda-label {
                .opacity(0);
            }

            .ladda-spinner {
                .opacity(1);
            }
        }
    }
}
