/* ------------------------------------------------------------------------------
 *
 *  # Sparklines library
 *
 *  Generate sparklines (small inline charts) directly in the browser
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */


// Core
.jqsfield {
	font-size: @font-size-small!important;
	font-family: 'Roboto', sans-serif!important;
}


// Tooltip
.jqstooltip {
	font-size: @font-size-small !important;
	font-family: 'Roboto', sans-serif !important;
	padding: @padding-base-vertical !important;
	line-height: @line-height-small !important;
	border: 0 !important;
	border-radius: @border-radius-base !important;
	.box-sizing(content-box);
}
