/* ------------------------------------------------------------------------------
 *
 *  # Google maps base
 *
 *  Styles related to Google Maps: containers, sizing etc.
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */


// Maps wrapper
.map-wrapper {
	width: 100%;
	height: 400px;

	.sidebar & {
		height: 200px;
		border: 1px solid @panel-inner-border;
	}
}
