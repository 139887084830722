/* ------------------------------------------------------------------------------
*
*  # Fancybox lightbox
*
*  Mac-style "lightbox" plugin that floats overtop of web page.
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */


// Core
// ------------------------------

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
}

// Wrapper
.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}

// Skin
.fancybox-skin {
    position: relative;
    background-color: #fff;
    color: @text-color;
    border-radius: @border-radius-base;
}

// Opened lightbox
.fancybox-opened {
    z-index: 8030;

    .fancybox-skin {
        .box-shadow(0 1px 10px fade(#000, 20%));
    }
}


// Content
// ------------------------------

// Image containers
.fancybox-outer,
.fancybox-inner {
    position: relative;
}
.fancybox-inner {
    overflow: hidden;

    .fancybox-type-iframe & {
        -webkit-overflow-scrolling: touch;
    }
}

// Image
.fancybox-image,
.fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}
.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

// Loading indicator
#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    cursor: pointer;
    z-index: 8060;
    color: #fff;
    .opacity(0.8);

    // Icon container
    div {

        // Icon
        &:after {
            content: '\eb55';
            font-family: 'icomoon';
            font-size: @icon-font-size;
            margin-top: -(@icon-font-size / 2);
            margin-left: -(@icon-font-size / 2);
            display: block;
            line-height: 1;
            width: @icon-font-size;
            height: @icon-font-size;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            .animation(rotation 1s ease infinite);
        }
    }
}

// Close button
.fancybox-close {
    position: fixed;
    right: 0;
    top: 0;
    padding: @content-padding-base;
    z-index: 8061;
    cursor: pointer;
    line-height: 1;
    color: #fff;
    border-radius: @border-radius-base;

    // Hover state
    &:hover, 
    &:focus {
        background-color: fade(#000, 25%);
        color: #fff;
    }

    // Icon
    &:after {
        content: '\ed6a';
        font-family: 'icomoon';
        font-size: @icon-font-size;
        display: block;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

// Error
.fancybox-error {
    color: @brand-danger;  
    font-weight: 500;  
    margin: 0;  
    padding: @panel-body-padding;  
    white-space: nowrap;
}


//
// Title
//

// Helper
.fancybox-title {
    visibility: hidden;
    font-weight: 500;
    position: relative;
    text-shadow: none;
    z-index: 8050;

    .fancybox-opened & {
        visibility: visible;
    }
}

// Wrappers
.fancybox-title-float-wrap {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-bottom: -45px;
    z-index: 8050;
    text-align: center;

    .child {
        display: inline-block;
        margin-right: -100%;
        padding: @padding-base-vertical @padding-base-horizontal;
        background: fade(#000, 50%);
        color: #fff;
        white-space: nowrap;
    }
}
.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}
.fancybox-title-inside-wrap {
    padding-top: 10px;
}
.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    background-color: transparent;
}


// Navigation
// ------------------------------

// Container
.fancybox-nav {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    z-index: 8040;

    span {
        position: absolute;
        top: 50%;
        width: (@icon-font-size * 2);
        height: (@icon-font-size * 2);
        margin-top: -(@icon-font-size);
        cursor: pointer;
        padding: (@icon-font-size / 2);
        color: #fff;
        background-color: fade(#000, 50%);
        display: block;
        text-align: center;
        z-index: 8040;
        visibility: hidden;
        border-radius: @border-radius-base;
        .opacity(0);

        &:after {
            font-family: 'icomoon';
            display: inline-block;
            font-size: @icon-font-size;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        } 
    }

    &:hover span {
        visibility: visible;
        .opacity(1);
    }
}

// Previous button
.fancybox-prev {
    left: 0;

    span {
        left: 10px;

        &:after {
            content: '\e9c8';
        } 
    }
}

// Next button
.fancybox-next {
    right: 0;

    span {
        right: 10px;

        &:after {
            content: '\e9cb';
        } 
    }
}


// Overlays
// ------------------------------

// Helper
.fancybox-lock {
    overflow: visible !important;
    width: auto;

    .fancybox-overlay {
        overflow: auto;
        overflow-y: scroll;
    }

    body {
        overflow: hidden !important;
    }
}
.fancybox-lock-test {
    overflow-y: hidden !important;
}

// Overlays
.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 8010;
    background-color: fade(#000, 80%);
}
.fancybox-overlay-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
}
