/* ------------------------------------------------------------------------------
 *
 *  # Bootbox modal extension
 *
 *  Styles for bootbox.min.js - advanced Bootstrap modals
 *
 *  Version: 1.0
 *  Latest update: Oct 9, 2015
 *
 * ---------------------------------------------------------------------------- */


// Base
// ------------------------------

.bootbox {

	// Change modal title size
	.modal-title {
		font-size: @font-size-h6;
	}
}