/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap maxlength
 *
 *  Styles for maxlength.min.js - input characters counter
 *
 *  Version: 1.0
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */


// Give it a look of help block
.bootstrap-maxlength {
	margin-top: @padding-base-vertical;
	font-size: @font-size-small;
	font-weight: 500;
}

