/* ------------------------------------------------------------------------------
 *
 *  # Ace code editor
 *
 *  An embeddable code editor written in JavaScript
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */


// Base
.ace_editor {
	height: 400px;
	position: relative;
}
