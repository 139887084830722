/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.3
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global less file
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.0
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.no-transitions * {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.btn,
.navbar-link,
.nav-tabs > li > a,
.nav-tabs > li > a:after,
.nav-pills > li > a,
.nav li > a > .label,
.nav li > a > .badge,
.breadcrumb > li > a,
.breadcrumb-elements > li > a,
.tt-suggestion,
.tokenfield .token,
.selectboxit-btn,
.bootstrap-select .btn-default,
.select2-results__option,
.select2-selection__choice__remove,
.dropdown-menu > li > a,
.dropdown-menu > li > label,
.wizard .actions a,
.checker span:after,
.choice span:after,
.selectboxit-option-anchor,
.dt-autofill-list ul li,
.dt-autofill-button .btn,
.dataTable .select-checkbox:before,
.dataTable .select-checkbox:after,
.pika-button,
.sp-input,
.navbar-nav > li > a,
.dropdown-content-footer a,
.icons-list > li > a,
.picker__nav--prev,
.picker__nav--next,
.multiselect.btn-default,
.list-group-item,
.pagination > li > a,
.pagination > li > span,
.pager > li > a,
.pager > li > span,
.datepicker-dropdown .day,
a.label,
a.badge,
.ui-datepicker-buttonpane > button,
.ui-button,
.ui-menu-item,
.ui-selectmenu-button,
.ui-datepicker a,
.media-link,
.menu-list li > a,
.plupload_file_action > a,
.dataTables_paginate .paginate_button,
.dataTables_filter input,
.dt-button,
.picker__list-item,
.picker__day,
.picker__footer,
.sp-replacer,
.sp-cancel,
.sp-choose,
.sp-palette-toggle,
.daterangepicker td,
.daterangepicker th,
.noUi-handle,
.fc-button,
.plupload_button,
.picker__footer button,
.picker__list button,
.AnyTime-btn,
.plupload_filelist li,
.password-indicator-group.input-group-addon,
.password-indicator-label-absolute,
.select2-selection--single:not([class*=bg-]),
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice,
.bootstrap-select.btn-group .dropdown-menu > li > a .check-mark {
  -webkit-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
  transition: all ease-in-out 0.15s;
}
.close,
.tag [data-role="remove"] {
  -webkit-transition: opacity ease-in-out 0.15s;
  -o-transition: opacity ease-in-out 0.15s;
  transition: opacity ease-in-out 0.15s;
}
.checker span {
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}
.table tr,
.table th,
.table td,
.htContextMenu td,
.ranges ul li {
  -webkit-transition: background-color ease-in-out 0.15s;
  -o-transition: background-color ease-in-out 0.15s;
  transition: background-color ease-in-out 0.15s;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Styles for sidebar components, main navigation and sidebar itself
 *
 *  Version: 1.2
 *  Latest update: Nov 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
  background-color: #263238;
  color: #fff;
  position: relative;
  width: 100%;
  display: none;
}
@media (min-width: 769px) {
  .sidebar {
    display: table-cell;
    vertical-align: top;
    width: 260px;
  }
}
.sidebar-main {
  z-index: 99;
}
.sidebar-secondary {
  z-index: 98;
}
.sidebar-opposite {
  display: none;
  z-index: 97;
}
@media (min-width: 769px) {
  .sidebar-opposite-visible .sidebar-opposite {
    display: table-cell;
  }
}
.sidebar:not(.sidebar-default) + .sidebar:not(.sidebar-default) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-default {
  background-color: #fff;
  color: #333333;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 769px) {
  .sidebar-default {
    border-bottom: 0;
    border-right: 1px solid #ddd;
  }
  .content-wrapper + .sidebar-default {
    border-left: 1px solid #ddd;
  }
}
.sidebar-content {
  position: relative;
  padding-bottom: 8px;
}
.sidebar-all-hidden .sidebar-main,
.sidebar-all-hidden .sidebar-secondary,
.sidebar-main-hidden .sidebar-main,
.sidebar-detached-hidden .sidebar-detached > .sidebar,
.sidebar-secondary-hidden .sidebar-secondary {
  display: none;
}
@media (max-width: 768px) {
  .sidebar-mobile-main .sidebar-main,
  .sidebar-mobile-secondary .sidebar-secondary,
  .sidebar-mobile-opposite .sidebar-opposite,
  .sidebar-mobile-detached .sidebar-detached > .sidebar {
    display: block;
  }
}
.category-title {
  position: relative;
  margin: 0;
  padding: 12px 20px;
  padding-right: 46px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.category-title > span {
  display: block;
  margin-top: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.82;
}
.category-title > i {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.category-title .icons-list {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.sidebar-default .category-title {
  border-bottom-color: #ddd;
}
.category-content {
  position: relative;
  padding: 20px;
}
.navigation {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  position: relative;
}
.sidebar-user + .sidebar-category .navigation {
  padding-top: 0;
}
.navigation .hidden-ul {
  display: none;
}
.navigation li {
  position: relative;
}
.navigation li + li {
  margin-top: 1px;
}
.navigation li + .navigation-header {
  margin-top: 10px;
}
.navigation li a {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  -webkit-transition: background 0.15s linear, color 0.15s linear;
  -o-transition: background 0.15s linear, color 0.15s linear;
  transition: background 0.15s linear, color 0.15s linear;
}
.navigation li a:hover,
.navigation li a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.navigation li a > i {
  float: left;
  top: 0;
  margin-top: 2px;
  margin-right: 15px;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.navigation li a > i.pull-right {
  margin-right: 0;
  margin-left: 15px;
}
.navigation li.disabled > a,
.navigation li.disabled > a:hover,
.navigation li.disabled > a:focus {
  color: inherit;
  background-color: transparent;
  cursor: not-allowed;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.navigation li > .has-ul {
  position: relative;
  padding-right: 36px;
}
.navigation li > .has-ul:after {
  content: "\e9c7";
  font-family: "icomoon";
  font-size: 16px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
}
.navigation li.active > .has-ul:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navigation li.navigation-divider {
  margin: 10px 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-default .navigation li.navigation-divider {
  background-color: #eeeeee;
}
.navigation > li > a {
  padding: 12px 20px;
  min-height: 44px;
  font-weight: 500;
}
.navigation > li.active > a,
.navigation > li.active > a:hover,
.navigation > li.active > a:focus {
  background-color: #F44336;
  color: #fff;
}
.navigation > li.active > a > [class*=text-] {
  color: #fff;
}
.navigation > li.active > a .label,
.navigation > li.active > a .badge {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  -webkit-transition: background-color ease-in-out 0.2s;
  -o-transition: background-color ease-in-out 0.2s;
  transition: background-color ease-in-out 0.2s;
}
.navigation > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
.navigation > li ul li a {
  padding: 10px 20px;
  padding-left: 51px;
  min-height: 40px;
}
.navigation > li ul .navigation-header {
  padding-left: 51px;
}
.navigation > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > .navigation-header {
  padding-left: 71px;
}
.navigation > li > ul > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > li > ul .navigation-header {
  padding-left: 91px;
}
.navigation > li > ul li:first-child {
  padding-top: 10px;
}
.navigation > li > ul li:last-child {
  padding-bottom: 10px;
}
.navigation > li > ul li.active > a,
.navigation > li > ul li.active > a:hover,
.navigation > li > ul li.active > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.navigation .navigation-header {
  min-height: 30px;
  padding: 10px 20px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
}
.navigation .navigation-header > span {
  display: block;
  margin-top: 2px;
}
.navigation .navigation-header > i {
  display: none;
}
.navigation .navigation-header > i.pull-right {
  margin-top: 2px;
}
.navigation .navigation-header,
.navigation .navigation-header a {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.navigation .navigation-header a:hover,
.navigation .navigation-header a:focus {
  color: #fff;
}
.navigation .navigation-header a i {
  float: none;
  margin: 0;
}
.sidebar-default .navigation .navigation-header,
.sidebar-default .navigation .navigation-header a {
  color: #999999;
}
.sidebar-default .navigation .navigation-header a:hover,
.sidebar-default .navigation .navigation-header a:focus {
  color: #333333;
}
.navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.navigation .label,
.navigation .badge {
  float: right;
}
.sidebar-default .navigation li > a {
  color: #333333;
}
.sidebar-default .navigation li > a:hover,
.sidebar-default .navigation li > a:focus {
  background-color: #f8f8f8;
}
.sidebar-default .navigation li.active > a,
.sidebar-default .navigation li.active > a:hover,
.sidebar-default .navigation li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation li.active > a > [class*=text-] {
  color: #333333;
}
.sidebar-default .navigation li.disabled > a,
.sidebar-default .navigation li.disabled > a:hover,
.sidebar-default .navigation li.disabled > a:focus {
  background-color: transparent;
}
.sidebar-default .navigation li .label-transparent {
  color: #333333;
}
.sidebar-default .navigation > li ul {
  background-color: transparent;
}
.sidebar-default .navigation > li.active > a,
.sidebar-default .navigation > li.active > a:hover,
.sidebar-default .navigation > li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation > li.active > a .label-transparent {
  color: #333333;
}
.navigation-icons-right > li > a,
.navigation-icons-right > li > a.has-ul {
  padding-right: 20px;
}
.navigation-icons-right > li > a > i {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}
.navigation-icons-right > li > a.has-ul:after,
.navigation-icons-right > li.active > .has-ul:after {
  content: none;
}
.navigation-bordered > li {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li.navigation-header {
  background-color: rgba(0, 0, 0, 0.1);
}
.navigation-bordered > li + li {
  margin-top: 0;
}
.navigation-bordered > li ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navigation-bordered li + .navigation-header {
  margin-top: 0;
}
.sidebar-default .navigation-bordered > li {
  border-top: 1px solid #eeeeee;
}
.sidebar-default .navigation-bordered > li.navigation-header {
  background-color: #fafafa;
}
.navigation-lg > li > a,
.navigation-lg > li > a > span {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 48px;
}
.navigation-lg > li ul li a {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li > a,
.navigation-sm > li > a > span {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
.navigation-xs > li > a,
.navigation-xs > li > a > span {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 36px;
}
.navigation-xs > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
@media (min-width: 769px) {
  .sidebar-fixed .sidebar-content {
    position: fixed;
    width: 260px;
    max-height: 100%;
    overflow: auto;
    top: 48px;
    bottom: 48px;
    margin-bottom: -48px;
  }
  .sidebar-xs .sidebar-fixed.sidebar-main .sidebar-content {
    width: 56px;
  }
  .sidebar-fixed.sidebar-default .sidebar-content {
    width: 259px;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    position: fixed;
    display: block;
    height: 100%;
    z-index: 1001;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main .sidebar-content {
    position: fixed;
  }
  .sidebar-xs .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    width: 260px;
  }
  .sidebar-fixed-expanded .content-wrapper {
    padding-left: 56px;
  }
}
.sidebar-xs-indicator .sidebar-fixed .sidebar-content {
  top: 0!important;
}
@media (min-width: 769px) {
  .has-detached-left .container-detached {
    float: right;
    margin-left: -260px;
    width: 100%;
  }
  .has-detached-left .content-detached {
    margin-left: 280px;
  }
  .has-detached-left .sidebar-detached {
    float: left;
  }
  .has-detached-right .container-detached {
    float: left;
    margin-right: -260px;
    width: 100%;
  }
  .has-detached-right .content-detached {
    margin-right: 280px;
  }
  .has-detached-right .sidebar-detached {
    float: right;
  }
  .has-detached-right .sidebar-detached.affix {
    right: 20px;
  }
  .sidebar-detached-hidden .container-detached {
    float: none;
    margin: 0;
  }
  .sidebar-detached-hidden .content-detached {
    margin: 0;
  }
  .sidebar-detached-hidden .sidebar-detached {
    float: none;
  }
}
.sidebar-detached .navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .sidebar-detached .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.sidebar-detached.affix {
  position: static;
}
@media (min-width: 769px) {
  .sidebar-detached {
    display: block;
    position: relative;
    margin-bottom: 0;
  }
  .sidebar-detached > .sidebar-default {
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .sidebar-detached > .sidebar {
    margin-bottom: 0;
    display: block;
    border-radius: 3px;
  }
  .sidebar-detached.affix {
    position: fixed;
    top: 20px;
    bottom: 20px;
    -webkit-transition: bottom ease-in-out 0.15s;
    -o-transition: bottom ease-in-out 0.15s;
    transition: bottom ease-in-out 0.15s;
  }
  .sidebar-detached.affix > .sidebar {
    max-height: 100%;
    overflow-y: auto;
  }
  .sidebar-detached.fixed-sidebar-space {
    bottom: 80px;
  }
  .navbar-bottom .sidebar-detached.fixed-sidebar-space {
    bottom: 86px;
  }
  .navbar-bottom-lg .sidebar-detached.fixed-sidebar-space {
    bottom: 90px;
  }
  .navbar-bottom-sm .sidebar-detached.fixed-sidebar-space {
    bottom: 84px;
  }
  .navbar-bottom-xs .sidebar-detached.fixed-sidebar-space {
    bottom: 82px;
  }
  .navbar-fixed .sidebar-detached {
    top: 86px;
  }
  .navbar-fixed-lg .sidebar-detached {
    top: 90px;
  }
  .navbar-fixed-sm .sidebar-detached {
    top: 84px;
  }
  .navbar-fixed-xs .sidebar-detached {
    top: 82px;
  }
}
@media (min-width: 769px) {
  .sidebar-separate {
    background-color: transparent;
  }
  .sidebar-separate .sidebar-category {
    background-color: #263238;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .sidebar-separate.sidebar-default {
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sidebar-separate.sidebar-default .sidebar-category {
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 769px) {
  .sidebar-xs .sidebar-main {
    width: 56px;
  }
  .sidebar-xs .sidebar-main .sidebar-category {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-category-visible {
    display: block;
  }
  .sidebar-xs .sidebar-main .category-title {
    padding: 0;
  }
  .sidebar-xs .sidebar-main .category-title > i {
    padding: 13px 0;
    float: none;
    display: block;
    top: 0;
  }
  .sidebar-xs .sidebar-main .category-title > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list {
    position: static;
    text-align: center;
    margin-top: 0;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li {
    display: block;
    margin-left: 0;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li + li {
    margin-top: 10px;
  }
  .sidebar-xs .sidebar-main .category-title.h6 .icons-list {
    padding-top: 23.538462px;
    padding-bottom: 23.538462px;
  }
  .sidebar-xs .sidebar-main .category-title.h5 .icons-list {
    padding-top: 25.0769236px;
    padding-bottom: 25.0769236px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span {
    display: none;
    position: absolute;
    top: 0;
    right: -260px;
    background-color: #F44336;
    border: 1px solid #F44336;
    padding: 11px 20px;
    width: 260px;
    text-align: left;
    color: #fff;
    cursor: pointer;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main .navigation > li > a > span .badge {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > i {
    margin: 2px 0;
    display: block;
    float: none;
  }
  .sidebar-xs .sidebar-main .navigation > li > .has-ul:after,
  .sidebar-xs .sidebar-main .navigation > li.active > .has-ul:after {
    content: none;
  }
  .sidebar-xs .sidebar-main .navigation > li .has-ul > span {
    border-radius: 0 3px 0 0;
    cursor: default;
  }
  .sidebar-xs .sidebar-main .navigation > li.active > ul {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > ul,
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > a > span {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > ul {
    display: block!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > a > span {
    display: block;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul {
    position: absolute;
    right: -260px;
    top: 44px;
    width: 260px;
    display: none;
    background-color: #263238;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 0 3px 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > a {
    padding-left: 30px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > ul > li > a {
    padding-left: 60px;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header {
    padding: 0;
    text-align: center;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > i {
    display: block;
    top: 0;
    padding: 13px 0;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-user .category-content {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    padding: 0;
    text-align: center;
    display: block;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > img,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > img {
    max-width: 100%;
    height: auto!important;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-sm,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-sm {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-xs,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-xs {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-body,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    display: none;
  }
  .sidebar-xs .sidebar-main .navigation-lg > li > ul {
    top: 48px;
  }
  .sidebar-xs .sidebar-main .navigation-sm > li > ul {
    top: 40px;
  }
  .sidebar-xs .sidebar-main .navigation-xs > li > ul {
    top: 36px;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span {
    background-color: #f5f5f5;
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .badge {
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > ul {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: 0;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li:hover:not(.active) > a {
    background-color: #f5f5f5;
    color: #333333;
  }
}
.sidebar .row {
  margin-left: -5px;
  margin-right: -5px;
}
.sidebar .row [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .sidebar .sp-container {
    display: block;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
.sidebar .sp-flat .sp-picker-container {
  display: block;
  width: 218px;
}
.sidebar .panel-group .panel {
  border-radius: 0;
  border-width: 0 0 1px 0;
}
.sidebar .panel-group .panel:first-child {
  border-top-width: 1px;
}
.sidebar .panel-group .panel + .panel {
  margin-top: 0;
}
.sidebar .media-list-bordered > li {
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
}
.sidebar:not(.sidebar-default) .media .text-muted,
.sidebar:not(.sidebar-default) .media .media-annotation {
  color: rgba(255, 255, 255, 0.8);
}
.sidebar:not(.sidebar-default) .media .media-left > a,
.sidebar:not(.sidebar-default) .media .media-body > a,
.sidebar:not(.sidebar-default) .media .media-right > a {
  color: #fff;
}
.sidebar:not(.sidebar-default) .media .media-link:hover,
.sidebar:not(.sidebar-default) .media .media-link:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar:not(.sidebar-default) .media .media-badge {
  border-color: #263238;
}
.sidebar:not(.sidebar-default) .media-list-bordered > li {
  border-color: rgba(255, 255, 255, 0.1);
}
.sidebar .thumbnail {
  margin-bottom: 10px;
}
.sidebar .thumbnail:last-child {
  margin-bottom: 0;
}
.sidebar .thumbnail .zoom-image i {
  font-size: 16px;
  margin-top: -8px;
  margin-left: -8px;
}
.sidebar .sidebar-category .checkbox,
.sidebar .sidebar-category .radio {
  margin-top: 0;
}
.sidebar .sidebar-category .checkbox:last-child,
.sidebar .sidebar-category .radio:last-child {
  margin-bottom: 0;
}
.sidebar .form-group:last-child {
  margin-bottom: 0;
}
.sidebar .nav-tabs {
  border-width: 0 0 1px 0;
}
@media (min-width: 769px) {
  .sidebar .nav-tabs {
    border-width: 1px;
  }
  .sidebar .nav-tabs > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.6);
    border-top: 0;
    border-radius: 0;
  }
  .sidebar .nav-tabs > li > a:hover,
  .sidebar .nav-tabs > li > a:focus {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .sidebar .nav-tabs > .active > a,
  .sidebar .nav-tabs > .active > a:hover,
  .sidebar .nav-tabs > .active > a:focus {
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .sidebar .nav-tabs > .active:first-child > a {
    border-left-color: transparent!important;
  }
  .sidebar .nav-tabs > .active:last-child > a {
    border-right-color: transparent!important;
  }
  .sidebar .nav-tabs > .open > a {
    color: #fff;
  }
  .sidebar .nav-tabs > .open:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-tabs > li > a {
    background-color: #fafafa;
    border-bottom-color: #ddd;
    color: #999999;
  }
  .sidebar-default .nav-tabs > li > a:hover,
  .sidebar-default .nav-tabs > li > a:focus {
    color: #333333;
    border-bottom-color: #ddd;
  }
  .sidebar-default .nav-tabs > .active > a,
  .sidebar-default .nav-tabs > .active > a:hover,
  .sidebar-default .nav-tabs > .active > a:focus {
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open > a {
    border-bottom-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open:not(.active) > a {
    background-color: #fafafa;
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-justified > li:first-child .dropdown-menu:not(.dropdown-menu-right) {
    left: -1px;
  }
  .sidebar-default .nav-justified > li:last-child .dropdown-menu-right {
    right: -1px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.page-container {
  position: relative;
}
@media (min-width: 769px) {
  .page-container {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  @-moz-document url-prefix() {
    .page-container {
      height: 1px;
    }
  }
}
@media (min-width: 769px) {
  .page-content {
    display: table-row;
  }
}
.content-wrapper {
  width: 100%;
}
@media (min-width: 769px) {
  .content-wrapper {
    display: table-cell;
    vertical-align: top;
  }
}
.content {
  padding: 0 20px 60px 20px;
}
.content:after {
  content: '';
  display: table;
  clear: both;
}
.content:first-child {
  padding-top: 20px;
}
body[class*=navbar-bottom] .content {
  padding-bottom: 20px;
}
/* ------------------------------------------------------------------------------
*
*  # Boxed layout
*
*  Styles for main structure of content area in boxed layout
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.layout-boxed {
  background: url(../images/backgrounds/boxed_bg.png) repeat;
}
.layout-boxed > .navbar {
  margin-left: auto;
  margin-right: auto;
}
.layout-boxed .page-container {
  background-color: #f5f5f5;
  margin-left: auto;
  margin-right: auto;
  min-width: 0;
}
@media (min-width: 769px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 769px;
  }
}
@media (min-width: 1025px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 1025px;
  }
}
@media (min-width: 1200px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 1200px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .layout-boxed {
    background: url(../images/backgrounds/boxed_bg_retina.png) repeat;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Footer
*
*  Main footer styles, currently 1 version only
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.footer {
  position: absolute;
  bottom: 30px;
}
.footer:not(.navbar-fixed-bottom) {
  z-index: 1000;
}
.footer.navbar {
  left: 0;
  right: 0;
  bottom: 0;
}
body[class*=navbar-bottom] .footer:not(.navbar) {
  display: none;
}
.footer-boxed {
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.rotate-45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rotate-90-inverse {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.rotate-180-inverse {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.spinner-reverse {
  display: inline-block;
  -webkit-animation: rotation_reverse 1s linear infinite;
  -o-animation: rotation_reverse 1s linear infinite;
  animation: rotation_reverse 1s linear infinite;
}
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotation {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes rotation {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes rotation {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes rotation_reverse {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-ms-keyframes rotation_reverse {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(-360deg);
  }
}
@-o-keyframes rotation_reverse {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(-360deg);
  }
}
@keyframes rotation_reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bounceOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Demo styles
*
*  Styles used for demostration purposes only
*
*  Version: 1.1
*  Latest update: Nov 25, 2015
*
* ---------------------------------------------------------------------------- */
.jqueryui-demo-container {
  font-size: 0;
}
.jqueryui-demo-element {
  width: 90px;
  height: 90px;
  border-radius: 100px;
  background-color: #fafafa;
  border: 2px dashed #ddd;
  color: #777;
  font-size: 12px;
  vertical-align: middle;
  margin: auto;
  position: relative;
  z-index: 10;
}
.jqueryui-demo-element + .jqueryui-demo-element {
  margin: 20px auto 0 auto;
}
.jqueryui-demo-element > span {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 0;
  right: 0;
}
@media (min-width: 769px) {
  .jqueryui-demo-element {
    display: inline-block;
  }
  .jqueryui-demo-element + .jqueryui-demo-element {
    margin: 0 0 0 20px;
  }
}
.selectable-demo-list {
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 200px;
}
.selectable-demo-list > .ui-sortable-handle,
.selectable-demo-list > .ui-selectee,
.ui-handle-excluded {
  padding: 6px 12px;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 2px;
  cursor: pointer;
  color: #777;
  font-size: 12px;
}
.selectable-demo-list > .ui-sortable-handle.ui-selecting,
.selectable-demo-list > .ui-selectee.ui-selecting {
  background-color: #29B6F6;
  border-color: #039BE5;
  color: #fff;
}
.selectable-demo-list > .ui-sortable-handle.ui-selected,
.selectable-demo-list > .ui-selectee.ui-selected {
  background-color: #03A9F4;
  border-color: #0288D1;
  color: #fff;
}
.selectable-demo-list > li + li {
  margin-top: 5px;
}
.selectable-demo-list > .ui-sortable-helper:first-child + li {
  margin-top: 0;
}
.selectable-demo-connected + .selectable-demo-connected {
  margin-top: 20px;
}
@media (min-width: 769px) {
  .selectable-demo-connected + .selectable-demo-connected {
    margin-top: 0;
    margin-left: 20px;
  }
}
.droppable-demo-target {
  width: 110px;
  height: 110px;
  background-color: #E3F2FD;
  border-color: #2196F3;
  color: #1565C0;
  z-index: 9;
}
.droppable-demo-drop {
  background-color: #E8F5E9;
  border-color: #4CAF50;
  color: #2E7D32;
}
.demo-color {
  height: 150px;
  position: relative;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.demo-color > span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
  color: #fff;
}
.overlay-demo {
  display: inline-block;
  background-color: #333;
  border-radius: 3px;
}
.overlay-demo-light {
  background-color: #f5f5f5;
}
.glyphs > div {
  padding: 7px 12px;
  cursor: pointer;
}
.glyphs > div > i {
  margin-right: 10px;
  width: 1em;
  text-align: center;
}
.glyphs > div:hover {
  background-color: #009688;
  color: #fff;
  border-radius: 2px;
}
.glyphs > div:hover span {
  color: #fff;
}
.grid-demo [class*="col-"] > div:not(.row) {
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
  background-color: #F7F7FC;
  border: 1px solid #ddd;
  color: #333333;
}
.demo-velocity-box {
  padding: 12px 15px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #ccc;
  text-align: center;
  background-color: #fcfcfc;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 0 #2196F3;
  box-shadow: 0 0 0 0 #2196F3;
}
.demo-velocity-box > span {
  float: left;
}
.demo-velocity-box span {
  vertical-align: middle;
}
.demo-velocity-box .icons-list {
  float: right;
  margin-top: 2px;
}
.demo-velocity-box .icons-list a {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.demo-velocity-box .icons-list a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.blockui-growl {
  display: none;
  text-align: left;
  padding: 15px;
  background-color: #455A64;
  color: #fff;
  border-radius: 3px;
}
.blockui-growl h6 {
  margin-top: 2px;
  margin-bottom: 8px;
}
.blockui-message {
  display: none;
  background-color: #455A64;
  color: #fff;
  border-radius: 3px;
  padding: 15px 15px 10px 15px;
}
.blockui-message > i {
  display: block;
  margin-bottom: 10px;
}
.multiple-messages-container,
.blockui-animation-container {
  display: none;
}
.blockui-animation-container {
  background-color: #555;
  padding: 10px;
  line-height: 1;
  border-radius: 3px;
}
.val-demo {
  display: block;
  margin-top: 20px;
}
.val-demo > span {
  font-weight: 500;
}
/* ------------------------------------------------------------------------------
 *
 *  # Pace. Default theme
 *
 *  Minimal preloader theme, used as a default theme
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pace .pace-progress {
  background: #4FC3F7;
  position: fixed;
  z-index: 2000;
  top: 0;
  width: 100%;
  right: 100%;
  height: 2px;
}
.pace-inactive {
  display: none;
}
/* ------------------------------------------------------------------------------
 *
 *  # Progress bars
 *
 *  Static demo of Pace themes. For demonstration purposes only. DO NOT use it in live project
 *
 *  Version: 1.0
 *  Latest update: Mar 2, 2015
 *
 * ---------------------------------------------------------------------------- */
.pace-demo {
  display: inline-block;
  background-color: #37474F;
  border-radius: 3px;
  margin-top: 1px;
  margin-bottom: 1px;
  vertical-align: middle;
  padding: 20px;
  position: relative;
}
.pace-demo .pace_progress {
  display: none;
}
.theme_xbox_xs,
.theme_xbox_sm,
.theme_xbox {
  position: relative;
  width: 44px;
  height: 44px;
}
.theme_xbox_xs .pace_activity,
.theme_xbox_sm .pace_activity,
.theme_xbox .pace_activity {
  width: 44px;
  height: 44px;
  -webkit-animation: rotation 1.5s ease-in-out infinite;
  -o-animation: rotation 1.5s ease-in-out infinite;
  animation: rotation 1.5s ease-in-out infinite;
}
.theme_xbox_xs .pace_activity,
.theme_xbox_xs .pace_activity:before,
.theme_xbox_xs .pace_activity:after,
.theme_xbox_sm .pace_activity,
.theme_xbox_sm .pace_activity:before,
.theme_xbox_sm .pace_activity:after,
.theme_xbox .pace_activity,
.theme_xbox .pace_activity:before,
.theme_xbox .pace_activity:after {
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #fff;
}
.theme_xbox_xs .pace_activity:before,
.theme_xbox_sm .pace_activity:before,
.theme_xbox .pace_activity:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  -webkit-animation: rotation 2s ease-in-out infinite;
  -o-animation: rotation 2s ease-in-out infinite;
  animation: rotation 2s ease-in-out infinite;
}
.theme_xbox_xs .pace_activity:after,
.theme_xbox_sm .pace_activity:after,
.theme_xbox .pace_activity:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  -webkit-animation: rotation 1s ease-in-out infinite;
  -o-animation: rotation 1s ease-in-out infinite;
  animation: rotation 1s ease-in-out infinite;
}
.theme_xbox_with_text span {
  display: block;
  margin-top: 7px;
  color: #fff;
}
.theme_xbox_sm .pace_activity,
.theme_xbox_sm .pace_activity:before,
.theme_xbox_sm .pace_activity:after {
  border-width: 2px;
}
.theme_xbox .pace_activity,
.theme_xbox .pace_activity:before,
.theme_xbox .pace_activity:after {
  border-width: 3px;
}
.theme_perspective {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 30px;
  height: 30px;
}
.theme_perspective .pace_activity {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-animation: perspective 1.2s infinite ease-in-out;
  -o-animation: perspective 1.2s infinite ease-in-out;
  animation: perspective 1.2s infinite ease-in-out;
}
@keyframes perspective {
  0% {
    transform: perspective(120px);
  }
  50% {
    transform: perspective(120px) rotateY(180deg);
  }
  100% {
    transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@-webkit-keyframes perspective {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@-moz-keyframes perspective {
  0% {
    -moz-transform: perspective(120px);
  }
  50% {
    -moz-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -moz-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
.theme_perspective_with_text span,
.theme_squares_with_text span,
.theme_tail_with_text span,
.theme_corners_with_text span,
.theme_radar_with_text span {
  display: block;
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 100%;
  color: #fff;
}
.theme_squares {
  width: 30px;
  height: 30px;
}
.theme_squares .pace_activity:before,
.theme_squares .pace_activity:after {
  position: absolute;
  content: "";
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: 5px;
}
.theme_squares .pace_activity:before {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: 0;
  -webkit-animation: rotation_reverse 2s linear infinite;
  -o-animation: rotation_reverse 2s linear infinite;
  animation: rotation_reverse 2s linear infinite;
}
.theme_squares .pace_activity:after {
  -webkit-animation: rotation 2s linear infinite;
  -o-animation: rotation 2s linear infinite;
  animation: rotation 2s linear infinite;
}
.theme_tail {
  width: 30px;
  height: 30px;
}
.theme_tail .pace_activity {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-left-color: #ffffff;
  -webkit-animation: rotation 1.1s infinite linear;
  -o-animation: rotation 1.1s infinite linear;
  animation: rotation 1.1s infinite linear;
}
.theme_tail_circle {
  width: 30px;
  height: 30px;
}
.theme_tail_circle .pace_activity {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  -webkit-animation: rotation 1.1s infinite linear;
  -o-animation: rotation 1.1s infinite linear;
  animation: rotation 1.1s infinite linear;
}
.theme_corners {
  height: 30px;
  width: 30px;
}
.theme_corners .pace_activity {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: 9px;
  margin-bottom: 3px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-animation: corners 1.5s ease infinite;
  -o-animation: corners 1.5s ease infinite;
  animation: corners 1.5s ease infinite;
}
@keyframes corners {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(180deg);
    border-radius: 0;
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes corners {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: scale(2) rotate(180deg);
    border-radius: 0;
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes corners {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: scale(2) rotate(180deg);
    border-radius: 0;
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
.theme_radar {
  width: 30px;
  height: 30px;
}
.theme_radar .pace_activity {
  display: inline-block;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #fff;
  border-bottom-color: #fff;
  -webkit-animation: rotation 1.1s infinite linear;
  -o-animation: rotation 1.1s infinite linear;
  animation: rotation 1.1s infinite linear;
}
.theme_bar,
.theme_bar_sm,
.theme_bar_xs {
  position: relative;
  width: 200px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  -webkit-box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
}
.theme_bar .pace_progress,
.theme_bar_sm .pace_progress,
.theme_bar_xs .pace_progress {
  max-width: 198px;
  display: block;
  position: absolute;
  left: 1px;
  top: 1px;
  height: 4px;
  background: #3FCF96;
  color: #fff;
  line-height: 32px;
  border-radius: 100px;
}
.theme_bar_sm {
  height: 5px;
}
.theme_bar_sm .pace_progress {
  height: 3px;
}
.theme_bar_xs {
  height: 4px;
}
.theme_bar_xs .pace_progress {
  height: 2px;
}
/* ------------------------------------------------------------------------------
*
*  # Uniform form inputs plugin
*
*  Styles for uniform.min.js - form components styling
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.checker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.checker,
.checker span,
.checker input {
  width: 18px;
  height: 18px;
}
.checker span {
  color: #455A64;
  border: 2px solid #607D8B;
  display: inline-block;
  text-align: center;
  position: relative;
  border-radius: 2px;
}
.checker span:after {
  content: "\e600";
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  position: absolute;
  top: -1px;
  left: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checker span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checker input[type=checkbox],
.choice input[type=radio] {
  border: none;
  background: none;
  display: -moz-inline-box;
  display: inline-block;
  margin: 0;
  vertical-align: top;
  cursor: pointer;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox .checker,
.checkbox-inline .checker {
  position: absolute;
  top: 1px;
  left: 0;
}
.form-horizontal .checkbox .checker,
.form-horizontal .checkbox-inline .checker {
  top: 9px;
}
.checkbox-right .checker {
  left: auto;
  right: 0;
}
.checker.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.checker.disabled,
.checker.disabled input[type=checkbox] {
  cursor: not-allowed;
}
.checkbox > label:active .checker.disabled span:after,
.checkbox-inline:active .checker.disabled span:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox > label:active .checker.disabled span.checked:after,
.checkbox-inline:active .checker.disabled span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checker[class*=border-] span {
  border-color: inherit;
  color: inherit;
}
.dropdown-menu > .active:not(.disabled) .checker span,
.dropdown-menu[class*=bg-] .checker span,
.page-header-inverse .form-group > .checkbox .checker span,
.page-header-inverse .form-group > .checkbox-inline .checker span,
.navbar-inverse .navbar-form .form-group > .checkbox .checker span,
.navbar-inverse .navbar-form .form-group > .checkbox-inline .checker span,
.sidebar:not(.sidebar-default) .checkbox .checker span,
.sidebar:not(.sidebar-default) .checkbox-inline .checker span {
  border-color: #fff;
  color: #fff;
}
.choice {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 100%;
}
.choice,
.choice span,
.choice input {
  width: 18px;
  height: 18px;
}
.choice span {
  border: 2px solid #607D8B;
  display: -moz-inline-box;
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  position: relative;
}
.choice span:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  border: 4px solid;
  border-color: inherit;
  width: 0;
  height: 0;
  border-radius: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
}
.choice span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.radio .choice,
.radio-inline .choice {
  position: absolute;
  top: 1px;
  left: 0;
}
.form-horizontal .radio .choice,
.form-horizontal .radio-inline .choice {
  top: 9px;
}
.radio-right .choice {
  left: auto;
  right: 0;
}
.choice.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.choice.disabled,
.choice.disabled input[type=radio] {
  cursor: not-allowed;
}
.radio > label:active .choice.disabled span:after,
.radio-inline:active .choice.disabled span:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.radio > label:active .choice.disabled span.checked:after,
.radio-inline:active .choice.disabled span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.choice[class*=border-] span {
  border-color: inherit;
}
.choice[class*=border-] span:after {
  border-color: inherit;
}
.dropdown-menu > .active .choice span,
.dropdown-menu[class*=bg-] .choice span,
.page-header-inverse .form-group > .radio .choice span,
.page-header-inverse .form-group > .radio-inline .choice span,
.navbar-inverse .navbar-form .form-group > .radio .choice span,
.navbar-inverse .navbar-form .form-group > .radio-inline .choice span,
.sidebar:not(.sidebar-default) .radio .choice span,
.sidebar:not(.sidebar-default) .radio-inline .choice span {
  border-color: #fff;
}
.uploader {
  width: 100%;
  position: relative;
  display: table;
}
.uploader .filename {
  color: #999999;
  height: 36px;
  padding: 7px 12px;
  cursor: pointer;
  width: 100%;
  display: table-cell;
  cursor: default;
  border: 1px solid #ddd;
  border-right: 0;
  background-color: #fff;
  text-align: left;
  word-break: break-word;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.uploader .action {
  display: table-cell;
  cursor: pointer;
  z-index: 1;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.uploader .action.btn {
  margin-top: 0;
}
.uploader:hover .action,
.uploader:focus .action {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.uploader:hover .action.btn-default,
.uploader:focus .action.btn-default {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.uploader:active .action {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.uploader:active .action.btn-default {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.uploader input[type=file] {
  width: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 36px;
  border: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  filter: alpha(opacity=0);
}
.uploader.disabled .filename {
  background-color: #fafafa;
  cursor: not-allowed;
}
.uploader-lg input[type=file],
.uploader-lg .action,
.uploader-lg .filename {
  height: 40px;
}
.uploader-lg .filename {
  padding: 9px 15px;
  font-size: 14px;
  line-height: 1.4285715;
}
.uploader-sm input[type=file],
.uploader-sm .action,
.uploader-sm .filename {
  height: 34px;
}
.uploader-sm .filename {
  padding: 6px 11px;
  font-size: 12px;
  line-height: 1.6666667;
}
.uploader-xs input[type=file],
.uploader-xs .action,
.uploader-xs .filename {
  height: 32px;
}
.uploader-xs .filename {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.6666667;
}
/* ------------------------------------------------------------------------------
*
*  # Switchery toggles
*
*  Styles for switchery.min.js - toggle switches
*
*  Version: 1.0
*  Latest update: Mar 25, 2015
*
* ---------------------------------------------------------------------------- */
.switchery {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  width: 44px;
  height: 22px;
  vertical-align: middle;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.checkbox-switchery .switchery {
  position: absolute;
  left: 0;
  margin-top: -2px;
}
.switchery > small {
  background-color: #fff;
  border-radius: 100px;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.checkbox.checkbox-switchery {
  margin-bottom: 14px;
  padding-left: 0;
}
.checkbox.checkbox-switchery.disabled .switchery {
  cursor: not-allowed;
}
.checkbox[class*=switchery-],
.checkbox-inline[class*=switchery-] {
  padding-left: 0;
  padding-right: 0;
}
.switchery-lg.checkbox-switchery {
  margin-bottom: 18px;
}
.switchery-lg.checkbox-switchery .switchery {
  margin-top: -4px;
}
label.switchery-lg {
  margin-bottom: 0;
}
.switchery-lg .switchery {
  height: 26px;
  width: 52px;
}
.switchery-lg .switchery > small {
  height: 26px;
  width: 26px;
}
.switchery-sm.checkbox-switchery {
  margin-bottom: 10px;
}
.switchery-sm.checkbox-switchery .switchery {
  margin-top: 0px;
}
label.switchery-sm {
  margin-bottom: 0;
}
.switchery-sm .switchery {
  height: 18px;
  width: 36px;
}
.switchery-sm .switchery > small {
  height: 18px;
  width: 18px;
}
.switchery-xs.checkbox-switchery {
  margin-bottom: 6px;
}
.switchery-xs.checkbox-switchery .switchery {
  margin-top: 2px;
}
label.switchery-xs {
  margin-bottom: 0;
}
.switchery-xs .switchery {
  height: 14px;
  width: 28px;
}
.switchery-xs .switchery > small {
  height: 14px;
  width: 14px;
}
.checkbox-switchery label,
label.checkbox-switchery {
  position: relative;
  padding-left: 56px;
  margin: 0;
  cursor: pointer;
}
.checkbox-switchery.switchery-lg label,
label.checkbox-switchery.switchery-lg {
  padding-left: 64px;
}
.checkbox-switchery.switchery-sm label,
label.checkbox-switchery.switchery-sm {
  padding-left: 48px;
}
.checkbox-switchery.switchery-xs label,
label.checkbox-switchery.switchery-xs {
  padding-left: 40px;
}
.checkbox-switchery.checkbox-inline {
  margin-bottom: 0;
}
.checkbox-switchery.checkbox-right .switchery {
  left: auto;
  right: 0;
}
.checkbox-switchery.checkbox-right label,
label.checkbox-switchery.checkbox-right {
  padding-left: 0;
  padding-right: 56px;
}
.checkbox-switchery.checkbox-right.switchery-lg label,
label.checkbox-switchery.checkbox-right.switchery-lg {
  padding-left: 0;
  padding-right: 64px;
}
.checkbox-switchery.checkbox-right.switchery-sm label,
label.checkbox-switchery.checkbox-right.switchery-sm {
  padding-left: 0;
  padding-right: 48px;
}
.checkbox-switchery.checkbox-right.switchery-xs label,
label.checkbox-switchery.checkbox-right.switchery-xs {
  padding-left: 0;
  padding-right: 40px;
}
.switchery-double .switchery {
  position: relative;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: -7px;
  margin-bottom: -5px;
}
.switchery-double.checkbox-switchery label,
label.switchery-double.checkbox-switchery {
  padding: 0;
}
.switchery-double.switchery-lg .switchery {
  margin-top: -8px;
  margin-bottom: -6px;
}
.switchery-double.switchery-sm .switchery {
  margin-top: -6px;
  margin-bottom: -4px;
}
.switchery-double.switchery-xs .switchery {
  margin-top: -5px;
  margin-bottom: -3px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap switches
*
*  Styles for switch.min.js - checkbox/radio toggle switches
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-switch {
  display: inline-block;
  cursor: pointer;
  margin-top: -2px;
  margin-right: 7px;
  border-radius: 3px;
  border: 1px solid transparent;
  position: relative;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all ease-in-out 0.05s;
  -o-transition: all ease-in-out 0.05s;
  transition: all ease-in-out 0.05s;
}
.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch.bootstrap-switch-focused {
  outline: 0;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  padding: 7px 12px;
}
.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  cursor: default !important;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-on,
.bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
}
.bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #333333;
  background-color: #eeeeee;
}
.bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background-color: #2196F3;
}
.bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background-color: #F44336;
}
.bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background-color: #4CAF50;
}
.bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch-handle-off.bootstrap-switch-warning {
  color: #fff;
  background-color: #FF5722;
}
.bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background-color: #00BCD4;
}
.bootstrap-switch-handle-on {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-off {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background-color: #fff;
  position: relative;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
.bootstrap-switch-label:hover {
  background-color: #fcfcfc;
}
.bootstrap-switch-label:active {
  background-color: #fafafa;
}
.bootstrap-switch-label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 10px;
  margin-top: -5px;
  margin-left: -1px;
  display: inline-block;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch-large .bootstrap-switch-label {
  padding: 9px 15px;
}
.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 5px 10px;
}
.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch-small .bootstrap-switch-label {
  padding: 6px 11px;
}
.checkbox-switch > label,
label.checkbox-switch.checkbox-inline {
  padding: 0;
}
.checkbox-switch.checkbox-right .bootstrap-switch {
  margin-right: 0;
  margin-left: 7px;
}
/* ------------------------------------------------------------------------------
*
*  # Select2 selects
*
*  Styles for select2.js - custom select plugin
*
*  Version: 1.3
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.select2-container {
  outline: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.select2-selection--single {
  cursor: pointer;
  outline: 0;
  display: block;
  height: 36px;
  padding: 7px 0;
  line-height: 1.5384616;
  position: relative;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-selection--single:hover,
.select2-selection--single:focus,
.select2-container--open .select2-selection--single {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.select2-selection--single:not([class*=bg-]) {
  background-color: #fff;
  color: #333333;
}
.select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-color: #ddd;
}
.select2-selection--single[class*=bg-]:hover,
.select2-selection--single[class*=bg-]:focus,
.select2-container--open .select2-selection--single[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.025) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.025) inset;
}
.select2-selection--single[class*=bg-] .select2-selection__placeholder {
  color: #fff;
}
.select2-container--disabled .select2-selection--single[class*=bg-] .select2-selection__placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 12px;
  padding-right: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-selection--single .select2-selection__rendered > i {
  margin-right: 10px;
}
.select2-selection--single .select2-selection__clear {
  position: relative;
  cursor: pointer;
  float: right;
  font-size: 0;
  line-height: 1;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.select2-selection--single .select2-selection__clear:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.select2-selection--single .select2-selection__clear:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-selection--single .select2-selection__placeholder {
  color: #999999;
}
.select2-selection--single .select2-selection__arrow:after {
  content: '\e9c5';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  content: '\e9c6';
}
.select2-selection--single .select2-selection__arrow b {
  display: none;
}
.select2-container--disabled .select2-selection--single {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container--disabled .select2-selection--single:not([class*=bg-]) {
  background-color: #fafafa;
  color: #999999;
}
.select2-container--disabled .select2-selection--single[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
}
.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-selection--multiple {
  display: block;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: text;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-selection--multiple:not([class*=bg-]) {
  background-color: #fff;
}
.select2-selection--multiple:not([class*=bg-]):not([class*=border-]) {
  border-color: #ddd;
}
.select2-selection--multiple .select2-selection__rendered {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0 2px 2px 2px;
  width: 100%;
}
.select2-selection--multiple .select2-selection__placeholder {
  color: #999999;
}
.select2-container--disabled .select2-selection--multiple:not([class*=bg-]) {
  background-color: #fafafa;
  color: #999999;
}
.select2-container--disabled .select2-selection--multiple[class*=bg-] {
  -webkit-box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
  box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.25) inset;
}
.select2-container--disabled .select2-selection--multiple[class*=bg-] .select2-selection__choice {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.select2-container--disabled .select2-selection--multiple,
.select2-container--disabled .select2-selection--multiple .select2-selection__choice,
.select2-container--disabled .select2-selection--multiple .select2-search__field {
  cursor: not-allowed;
}
.select2-selection--multiple .select2-selection__choice {
  background-color: #455A64;
  color: #fff;
  border-radius: 3px;
  cursor: default;
  float: left;
  margin-right: 2px;
  margin-top: 2px;
  padding: 7px 12px;
}
.select2-selection--multiple .select2-selection__choice > i {
  margin-right: 10px;
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  cursor: pointer;
  float: right;
  font-size: 14px;
  margin-top: 3px;
  line-height: 1;
  margin-left: 7px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}
.select2-selection--multiple .select2-search--inline {
  float: left;
}
.select2-selection--multiple .select2-search--inline .select2-search__field {
  font-size: 100%;
  margin-top: 2px;
  padding: 7px 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  margin-left: 7px;
  -webkit-appearance: textfield;
}
.select2-selection--multiple .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: #fff;
  color: #333333;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results > .select2-results__options {
  padding-bottom: 7px;
  max-height: 250px;
  overflow-y: auto;
}
.select2-search--hide + .select2-results > .select2-results__options {
  padding-top: 7px;
}
.select2-results:first-child > .select2-results__options {
  padding-top: 7px;
}
.select2-results__option {
  padding: 7px 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-results__option + .select2-results__option {
  margin-top: 1px;
}
.select2-results__option i {
  margin-right: 10px;
}
.select2-results__option i.icon-undefined {
  display: none;
}
.select2-results__option[role=group] {
  padding: 0;
}
.select2-results__option.select2-results__option--highlighted {
  background-color: #f5f5f5;
}
.select2-results__option[aria-disabled=true] {
  color: #999999;
  cursor: not-allowed;
}
.select2-dropdown[class*=bg-] .select2-results__option[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.6);
}
.select2-results__option[aria-selected=true] {
  background-color: #2196F3;
  color: #fff;
}
.select2-results__options--nested > .select2-results__option {
  padding-left: 24px;
  padding-right: 24px;
}
.select2-results__group {
  display: block;
  padding: 7px 12px;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  cursor: default;
  margin-top: 7px;
  margin-bottom: 7px;
}
.select2-results__option:first-child > .select2-results__group {
  margin-top: 0;
}
.select2-results__message {
  color: #999999;
  cursor: default;
}
.select2-dropdown[class*=bg-] .select2-results__message {
  color: rgba(255, 255, 255, 0.75);
}
.select2-results__option.loading-results {
  padding-top: 0;
}
.select2-results__option.loading-results + .select2-results__option {
  margin-top: 7px;
}
.select2-results__option--load-more {
  text-align: center;
  margin-top: 7px;
  cursor: default;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--above[class*=bg-] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--open .select2-dropdown--below[class*=bg-] {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-search--dropdown {
  display: block;
  position: relative;
  padding: 12px;
}
.select2-search--dropdown:after {
  content: '\e98e';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  left: 24px;
  color: inherit;
  display: block;
  font-size: 12px;
  margin-top: -6px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-search--dropdown + .select2-results .select2-results__message:first-child {
  padding-top: 0;
}
.select2-search--dropdown .select2-search__field {
  height: 36px;
  padding: 7px 12px;
  padding-left: 36px;
  border-radius: 3px;
  border: 1px solid #ddd;
  outline: 0;
  width: 100%;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select-lg.select2-selection--single {
  height: 40px;
  padding: 9px 0;
  font-size: 14px;
}
.select-lg.select2-selection--single .select2-selection__rendered {
  padding-left: 15px;
  padding-right: 34px;
}
.select-lg.select2-selection--single .select2-selection__arrow:after {
  right: 15px;
}
.select-lg.select2-selection--multiple .select2-selection__choice {
  padding: 9px 15px;
  font-size: 14px;
}
.select-lg.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 9px 0;
  font-size: 14px;
}
.select-sm.select2-selection--single {
  height: 34px;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-sm.select2-selection--single .select2-selection__rendered {
  padding-left: 11px;
  padding-right: 26px;
}
.select-sm.select2-selection--single .select2-selection__arrow:after {
  right: 11px;
}
.select-sm.select2-selection--multiple .select2-selection__choice {
  padding: 6px 11px;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 6px 0;
}
.select-xs.select2-selection--single {
  height: 32px;
  padding: 5px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs.select2-selection--single .select2-selection__rendered {
  padding-left: 10px;
  padding-right: 24px;
}
.select-xs.select2-selection--single .select2-selection__arrow:after {
  right: 10px;
}
.select-xs.select2-selection--multiple .select2-selection__choice {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 5px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select2-selection--multiple[class*=bg-] .select2-selection__choice {
  background-color: rgba(0, 0, 0, 0.25);
}
.select2-dropdown[class*=bg-] .select2-search--dropdown .select2-search__field {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
  color: #fff;
}
.select2-dropdown[class*=bg-] .select2-results__option[aria-selected=true] {
  background-color: rgba(0, 0, 0, 0.2);
}
.select2-dropdown[class*=bg-] .select2-results__option--highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 99;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important;
  width: 1px !important;
}
.select2-result-repository {
  padding-top: 7px;
  padding-bottom: 7px;
}
.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 15px;
}
.select2-result-repository__avatar img {
  width: 100%;
  height: auto;
  border-radius: 100px;
}
.select2-result-repository__meta {
  margin-left: 70px;
}
.select2-result-repository__title {
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 2px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
  display: inline-block;
  font-size: 12px;
}
.select2-result-repository__description {
  font-size: 12px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers {
  margin-right: 15px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap multiselect
*
*  Styles for multiselect.js - custom multiple select plugin
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.multiselect {
  width: 100%;
  min-width: 100%;
  text-align: left;
  padding-left: 12px;
  padding-right: 29px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.multiselect.btn-default,
.multiselect.btn-default.disabled {
  background-color: #fff;
  border-color: #ddd;
}
.multiselect.btn-default:active,
.btn-group.open .multiselect.btn-default {
  border-color: #ddd;
}
.multiselect .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -8px;
  width: 16px;
  text-align: right;
}
.multiselect.btn-lg {
  padding-left: 15px;
  padding-right: 30px;
}
.multiselect.btn-lg .caret {
  right: 15px;
}
.multiselect.btn-sm {
  padding-left: 11px;
  padding-right: 22px;
}
.multiselect.btn-sm .caret {
  right: 11px;
}
.multiselect.btn-xs {
  padding-left: 10px;
  padding-right: 20px;
}
.multiselect.btn-xs .caret {
  right: 10px;
}
.multiselect-container {
  min-width: 180px;
  max-height: 250px;
  overflow-y: auto;
}
.multiselect-container > li {
  padding: 0;
}
.multiselect-container > li > a {
  padding: 0;
}
.multiselect-container > li > a > label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  padding: 8px 12px;
  padding-left: 40px;
}
.navbar-form .multiselect-container > li > a > label {
  display: block;
}
.multiselect-container > li > a .checker,
.multiselect-container > li > a .choice {
  top: 50%;
  margin-top: -9px;
  left: 12px;
}
.multiselect-container > li > a.multiselect-all label {
  font-weight: 500;
}
.multiselect-container > li.disabled > a {
  background-color: transparent;
}
.multiselect-container > li.disabled > a > label {
  cursor: not-allowed;
}
.multiselect-container > li.multiselect-group > label,
.multiselect-container > li.multiselect-group.active > label {
  margin: 0;
  padding: 8px 15px;
  font-size: 11px;
  line-height: 1.82;
  color: #999999;
  text-transform: uppercase;
  margin-top: 14px;
  background-color: transparent;
  cursor: default;
}
.multiselect-container > li.multiselect-group.disabled {
  color: #999999;
}
.multiselect-container > li.multiselect-group:first-child > label,
.multiselect-container > li.multiselect-group:first-child.active > label {
  margin-top: 7px;
}
.multiselect-container > li.multiselect-group.multiselect-group-clickable > label {
  cursor: pointer;
}
.multiselect-container > li.multiselect-group.disabled label,
.multiselect-container > li.multiselect-group.disabled:hover label,
.multiselect-container > li.multiselect-group.disabled:focus label {
  background-color: transparent;
  cursor: not-allowed;
}
.multi-select-full > .btn-group {
  width: 100%;
}
.multi-select-full > .btn-group .multiselect-container {
  width: inherit;
}
.multi-select-fixed > .btn-group > .btn {
  width: 250px;
}
.multi-select-auto > .btn-group > .btn {
  width: auto;
}
div[class*=border-] > .btn-group {
  border-color: inherit;
}
div[class*=border-] > .btn-group > .multiselect {
  border-color: inherit;
}
li.multiselect-filter {
  position: relative;
  padding: 5px 12px;
  margin-bottom: 5px;
}
li.multiselect-filter i {
  font-size: 12px;
  position: absolute;
  left: 24px;
  top: 50%;
  margin-top: -6px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
li.multiselect-filter .form-control {
  padding-left: 36px;
}
.input-group-btn + .multi-select-full .multiselect:first-child,
.input-group-addon + .multi-select-full .multiselect:first-child,
.input-group-btn + .multi-select-auto .multiselect:first-child,
.input-group-addon + .multi-select-auto .multiselect:first-child,
.input-group-btn + .multi-select-fixed .multiselect:first-child,
.input-group-addon + .multi-select-fixed .multiselect:first-child,
.input-group-btn + .btn-group .multiselect:first-child,
.input-group-addon + .btn-group .multiselect:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.btn-group-multiselect {
  width: 100%;
}
.btn-group-multiselect .btn {
  float: none;
}
.btn-group-multiselect .multiselect {
  min-width: 0;
}
.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
/* ------------------------------------------------------------------------------
*
*  # SelectBoxIt
*
*  Styles for selectbox.js - custom select boxes plugin
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  border-radius: 3px;
}
.selectboxit-container * {
  -webkit-touch-callout: none;
  outline: 0;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectboxit-container .selectboxit {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.selectboxit-container .selectboxit:focus {
  outline: 0;
}
.selectboxit-container span,
.selectboxit-container .selectboxit-options a {
  display: block;
}
.selectboxit-container .selectboxit-options {
  width: 100%;
  max-height: 250px;
  padding: 7px 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999;
  text-align: left;
}
.selectboxit.selectboxit-disabled,
.selectboxit-options .selectboxit-disabled,
.selectboxit.selectboxit-disabled:hover,
.selectboxit-options .selectboxit-disabled:hover,
.selectboxit.selectboxit-disabled:focus,
.selectboxit-options .selectboxit-disabled:focus {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.selectboxit-rendering {
  display: inline-block !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
.selectboxit-list {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 2px 0 0;
  width: 100%;
  min-width: 200px;
  list-style: none;
  position: absolute;
  cursor: pointer;
  display: none;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.selectboxit-list .selectboxit-option-anchor {
  color: #333333;
}
.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  display: block;
  background-color: #f5f5f5;
  color: #333333;
}
.selectboxit-list > .selectboxit-selected > .selectboxit-option-anchor {
  background-color: #2196F3;
  color: #fff;
}
.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999;
  cursor: not-allowed;
}
.selectbox-container[class*=border-] > .selectboxit-list {
  border-color: inherit;
}
.selectbox-container[class*=bg-] > .selectboxit-list {
  background-color: inherit;
  border-color: inherit;
}
.selectbox-container[class*=bg-] > .selectboxit-list .selectboxit-option-anchor {
  color: #fff;
}
.selectbox-container[class*=bg-] > .selectboxit-list > .selectboxit-focus .selectboxit-option-anchor {
  background-color: rgba(0, 0, 0, 0.1);
}
.selectboxit-btn {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #333333;
  border-radius: 3px;
  height: 36px;
  padding: 7px 12px;
  padding-right: 29px;
}
.selectboxit-btn:hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.selectboxit-btn.selectboxit-open,
.selectboxit-btn:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.selectboxit-container[class*=bg-] .selectboxit-btn {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.selectboxit-container[class*=border-] .selectboxit-btn {
  border-color: inherit;
}
.selectboxit-btn[class*=bg-]:hover,
.selectboxit-container[class*=bg-] .selectboxit-btn:hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.selectboxit-btn[class*=bg-].selectboxit-open,
.selectboxit-container[class*=bg-] .selectboxit-btn.selectboxit-open,
.selectboxit-btn[class*=bg-]:focus,
.selectboxit-container[class*=bg-] .selectboxit-btn:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.input-group .selectboxit-container > .selectboxit-btn {
  border-radius: 0;
}
.input-group .selectboxit-container:last-child > .selectboxit-btn {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.input-group > select:first-child + .selectboxit-container > .selectboxit-btn {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.selectboxit-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.selectboxit-default-arrow {
  position: relative;
  font-style: normal;
}
.selectboxit-default-arrow:after {
  content: '\e9c5';
  display: block;
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.selectboxit-open .selectboxit-default-arrow:after {
  content: '\e9c6';
}
.selectboxit-option {
  margin: 0;
  list-style-type: none;
}
.selectboxit-option .selectboxit-option-anchor {
  padding: 7px 12px;
}
.selectboxit-optgroup-header {
  margin: 0;
  list-style-type: none;
  padding: 7px 12px;
  font-weight: 700;
  color: #333333;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
}
.selectboxit-optgroup-header:hover {
  cursor: default;
}
.selectboxit-optgroup-option .selectboxit-option-anchor {
  padding-left: 24px;
  padding-right: 24px;
}
.selectboxit-arrow-container {
  position: absolute;
  top: 50%;
  width: 16px;
  right: 12px;
  margin-top: -8px;
  text-align: right;
  line-height: 1;
}
.selectboxit-arrow-container .selectboxit-arrow[class*=icon-] {
  top: 0;
}
.selectboxit-arrow-container .caret:after {
  text-align: right;
}
.selectboxit-option-icon-container {
  float: left;
}
.selectboxit-option-icon-container > [class*=icon-] {
  margin-right: 10px;
  width: auto;
}
.selectboxit-option-icon-container .selectboxit-option-icon-url {
  height: 20px;
  width: 20px;
  background-size: 20px 20px;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.selectboxit-option-first {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.selectboxit-optgroup-header + .selectboxit-option-first {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.selectboxit-option-last {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
/* # Sizing
-------------------------------------------------- */
.selectbox-fixed + .selectboxit-container,
.selectbox-fixed + .selectboxit-options {
  width: 250px;
}
.selectbox-auto + .selectboxit-container,
.selectbox-auto + .selectboxit-options {
  width: auto;
}
.selectbox-lg + .selectboxit-container .selectboxit {
  padding: 9px 15px;
  padding-right: 30px;
  height: 40px;
  font-size: 14px;
}
.selectbox-lg + .selectboxit-container .selectboxit .selectboxit-arrow-container {
  right: 15px;
}
.selectbox-sm + .selectboxit-container .selectboxit {
  padding: 6px 11px;
  padding-right: 22px;
  height: 34px;
}
.selectbox-sm + .selectboxit-container .selectboxit .selectboxit-arrow-container {
  right: 11px;
}
.selectbox-xs + .selectboxit-container .selectboxit {
  padding: 5px 10px;
  padding-right: 25px;
  height: 32px;
  font-size: 12px;
  line-height: 1.6666667;
}
.selectbox-xs + .selectboxit-container .selectboxit .selectboxit-arrow-container {
  right: 10px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap select
*
*  Styles for bootstrap_select.js - custom select boxes plugin
*
*  Version: 1.2
*  Latest update: Aug 20, 2016
*
* ---------------------------------------------------------------------------- */
.bootstrap-select {
  width: 100%;
}
.bootstrap-select > .btn {
  width: 100%;
  padding-right: 34px;
}
.bootstrap-select > .btn.btn-default,
.bootstrap-select > .btn.btn-default.disabled {
  background-color: #fff;
  border-color: #ddd;
}
.bootstrap-select > .btn.btn-default:active,
.btn-group.open .bootstrap-select > .btn.btn-default {
  border-color: #ddd;
}
.bootstrap-select.form-control {
  padding: 0;
  border: 0;
}
.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}
.bootstrap-select .popover-title {
  font-size: 11px;
  line-height: 1.82;
  margin: 7px 0;
  padding: 7px 12px;
}
.bootstrap-select[class*=border-] .btn,
.bootstrap-select[class*=border-] .dropdown-menu {
  border-color: inherit;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}
.bootstrap-select.fit-width {
  width: auto !important;
}
.bootstrap-select.btn-group.bs-container {
  position: absolute;
}
.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}
.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.btn-group > .disabled:focus {
  outline: 0;
}
.bootstrap-select.btn-group .btn .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
}
.bootstrap-select.btn-group .btn .filter-option > i,
.bootstrap-select.btn-group .dropdown-menu > li > a [class*=icon-] {
  margin-right: 7px;
  float: left;
  margin-top: 2px;
  top: 0;
}
.bootstrap-select.btn-group .btn .caret {
  position: absolute;
  top: 50%;
  width: 16px;
  text-align: right;
  right: 12px;
  margin-top: -8px;
}
.bootstrap-select.btn-group[class*="col-"] .btn {
  width: 100%;
}
.bootstrap-select.btn-group .btn-lg > .caret {
  right: 15px;
}
.bootstrap-select.btn-group .btn-sm > .caret {
  right: 11px;
}
.bootstrap-select.btn-group .btn-xs > .caret {
  right: 10px;
}
.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu > li > a .check-mark {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 12px;
  margin-right: 0;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.bootstrap-select.btn-group .dropdown-menu > li > a .text {
  display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu > li > a > .label,
.bootstrap-select.btn-group .dropdown-menu > li > a > .badge {
  float: none;
}
.bootstrap-select.btn-group .dropdown-menu > li > a.special-class,
.bootstrap-select.btn-group .dropdown-menu > li > a.special-class:hover {
  font-weight: 700;
  color: #fff;
  background: #F44336;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.bootstrap-select.btn-group .dropdown-menu > li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu > li small {
  padding-left: 10px;
}
.bootstrap-select.btn-group .dropdown-menu > .selected > a {
  background-color: #2196F3;
  color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu > .selected > a small {
  color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 7px 12px;
  background-color: #f8f8f8;
  border-top: 1px solid #ddd;
  pointer-events: none;
}
.bootstrap-select.btn-group .dropdown-header ~ li > a {
  padding-left: 24px;
}
.bootstrap-select.btn-group .no-results {
  padding: 7px 12px;
  background: #f8f8f8;
  margin-bottom: -7px;
  border-top: 1px solid #ddd;
}
.bootstrap-select.btn-group.fit-width .btn .filter-option,
.bootstrap-select.btn-group.fit-width .btn .caret {
  position: static;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu > li a .text {
  margin-right: 40px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu > li.selected a .check-mark {
  opacity: 1;
  filter: alpha(opacity=100);
}
.input-group .bootstrap-select > .btn {
  border-radius: 0;
}
.input-group > .bs-select-hidden:first-child + .bootstrap-select > .btn,
.input-group > .bootstrap-select:first-child > .btn {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.input-group > .bootstrap-select:last-child > .btn {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.error .bootstrap-select .btn {
  border: 1px solid #F44336;
}
.control-group.error .bootstrap-select .dropdown-toggle {
  border-color: #F44336;
}
.bs-searchbox,
.bs-actionsbox {
  padding: 5px 12px;
  margin-bottom: 5px;
}
.bs-searchbox {
  position: relative;
}
.bs-searchbox > .form-control {
  padding-left: 36px;
}
.bs-searchbox:after {
  content: '\e98e';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  left: 24px;
  color: inherit;
  display: block;
  font-size: 12px;
  margin-top: -6px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.bs-actionsbox {
  float: left;
  width: 100%;
  position: relative;
}
.bs-actionsbox .btn-group button {
  width: 50%;
}
.bs-searchbox + .bs-actionsbox {
  padding: 0 12px 7px;
}
.mobile-device {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}
.bootstrap-select > select {
  position: absolute!important;
  bottom: 0;
  left: 50%;
  display: block!important;
  width: .5px!important;
  height: 100%!important;
  padding: 0!important;
  opacity: 0!important;
  border: none;
}
/* ------------------------------------------------------------------------------
*
*  # Passy
*
*  Styles for passy.min.js - password length checker and password generator
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.visual-password-indicator {
  display: inline-block;
  background-color: #999999;
  width: 40px;
  height: 4px;
}
.password-indicator-group {
  min-width: 80px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.label-indicator-absolute {
  position: relative;
}
.label-indicator-absolute .password-indicator-label-absolute {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 7px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.form-horizontal .label-indicator-absolute .label {
  right: 27px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Twiter Typeahead
 *
 *  Styles for typeahead.bundle.min.js - input suggestion engine
 *
 *  Version: 1.3
 *  Latest update: Jul 4, 2016
 *
 * ---------------------------------------------------------------------------- */
.twitter-typeahead {
  width: 100%;
}
.typeahead,
.tt-query,
.tt-hint {
  outline: 0;
}
.tt-hint {
  color: #999;
}
.tt-menu {
  width: 100%;
  margin-top: 1px;
  min-width: 180px;
  padding: 7px 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  max-height: 300px;
  overflow-y: auto;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.typeahead-scrollable .tt-menu {
  max-height: 250px;
}
.typeahead-rtl .tt-menu {
  text-align: right;
}
.tt-suggestion {
  padding: 8px 15px;
  cursor: pointer;
}
.tt-suggestion:hover,
.tt-suggestion:focus,
.tt-suggestion.tt-cursor {
  background-color: #f5f5f5;
}
.tt-dataset-group .tt-suggestion {
  padding-left: 24px;
  padding-right: 24px;
}
.tt-heading {
  font-size: 11px;
  line-height: 1.82;
  padding: 8px 15px;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 2px;
}
.typeahead-template .empty-message {
  padding: 8px 15px;
  text-align: center;
}
/* ------------------------------------------------------------------------------
 *
 *  # Form Validation
 *
 *  jQuery plugin for simple clientside form validation
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.validation-error-label,
.validation-valid-label {
  margin-top: 7px;
  margin-bottom: 7px;
  display: block;
  color: #F44336;
  position: relative;
  padding-left: 26px;
}
.validation-valid-label {
  color: #4CAF50;
}
.validation-error-label:before,
.validation-valid-label:before {
  font-family: 'icomoon';
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
.validation-error-label:empty,
.validation-valid-label:empty {
  display: none;
}
.validation-error-label:before {
  content: '\ed63';
}
.validation-valid-label:before {
  content: '\ee73';
}
/* ------------------------------------------------------------------------------
*
*  # X-editable
*
*  In-place editing with Twitter Bootstrap, jQuery UI or pure jQuery
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.editable {
  background-color: transparent;
}
.form-horizontal .editable {
  padding-top: 8px;
  display: inline-block;
}
.editable-pre-wrapped {
  white-space: pre-wrap;
}
.editableform .form-group:last-child {
  margin-bottom: 0;
}
.editableform .form-control {
  min-width: 200px;
}
.editableform .checkbox,
.editableform .radio {
  margin-top: 0;
  margin-bottom: 0;
}
.editableform .checkbox > div,
.editableform .radio > div {
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
}
.editable-buttons {
  display: inline-block;
  vertical-align: top;
  margin-left: 7px;
}
.editable-buttons.editable-buttons-bottom {
  display: block;
  margin-top: 20px;
  margin-left: 0;
  text-align: center;
}
.editable-buttons .editable-cancel {
  margin-left: 7px;
}
.editable-input {
  vertical-align: top;
  display: inline-block;
  width: auto;
  white-space: normal;
}
.editable-input .help-block {
  margin-bottom: 0;
}
.editable-input .input-group {
  width: 250px;
}
.editable-input .elastic {
  height: 100px;
}
.editableform-loading {
  width: 16px;
  height: 16px;
  margin: auto;
}
.editableform-loading:after {
  content: '\eb55';
  font-family: 'icomoon';
  font-size: 16px;
  display: block;
  line-height: 1;
  margin: auto;
  width: 16px;
  height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s ease infinite;
  -o-animation: rotation 1s ease infinite;
  animation: rotation 1s ease infinite;
}
.editable-error {
  color: #F44336;
}
.editable-error-block {
  max-width: 300px;
  margin: 7px 0 0 0;
  width: auto;
  white-space: normal;
}
.editable-bg-transition {
  color: inherit;
  background-color: transparent;
  -webkit-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}
.editable-clear {
  clear: both;
  text-decoration: none;
  text-align: right;
}
.editable-clear-x {
  position: absolute;
  right: 9px;
  top: 10px;
  line-height: 1;
  cursor: pointer;
  z-index: 100;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.editable-clear-x:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.editable-clear-x:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editable-click,
a.editable-click,
.editable-click:hover,
a.editable-click:hover {
  border-bottom: 1px dashed #1E88E5;
}
.editable-click.editable-disabled,
a.editable-click.editable-disabled,
.editable-click.editable-disabled:hover,
a.editable-click.editable-disabled:hover {
  color: #999999;
  cursor: default;
  border-bottom: 0;
}
.editable-click .label {
  margin-top: 1px;
  margin-bottom: 1px;
}
.editable-empty,
.editable-empty:hover,
.editable-empty:focus {
  font-style: italic;
  color: #F44336;
}
a.editable-empty,
a.editable-empty:hover,
a.editable-empty:focus {
  border-bottom-color: #F44336;
}
.combodate {
  white-space: nowrap;
}
.combodate .day,
.combodate .year,
.combodate .hour,
.combodate .minute {
  min-width: 80px;
}
.combodate .form-control {
  display: inline-block;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.input-daterange input:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.editable-address {
  display: block;
}
.editable-address > label > span {
  width: 70px;
  display: inline-block;
  margin-bottom: 7px;
}
.editable-date {
  padding: 0;
  margin: 0;
  float: left;
}
.editable-checklist label {
  white-space: nowrap;
}
.editable-checklist label > input[type="checkbox"] {
  vertical-align: middle;
  margin: 0;
}
.editable-wysihtml5 {
  width: 566px;
  height: 250px;
}
.editable-container.editable-popup {
  max-width: none !important;
}
.editable-container.editable-inline {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}
.editable-container.editable-inline .editableform-loading {
  background-position: left 5px;
}
.editable-container.editable-inline .add-on .icon-th {
  margin-top: 3px;
  margin-left: 1px;
}
.editable-container.popover {
  width: auto;
  min-width: 200px;
}
/* ------------------------------------------------------------------------------
*
*  # Alpaca forms
*
*  Alpaca provides the easiest way to generate interactive HTML5 forms for web applications
*
*  Version: 1.1
*  Latest update: Jul 4, 2016
*
* ---------------------------------------------------------------------------- */
.alpaca-hidden {
  display: none;
}
.alpaca-field-select .multiselect-container {
  width: 100%;
}
.has-error .multiselect-container > .active .checkbox {
  color: #fff;
}
.has-error .btn-group.open .multiselect.btn,
.has-error .multiselect.btn-default:active {
  color: #D84315;
  border-color: #D84315;
}
.alpaca-disabled .checkbox label,
.alpaca-disabled .checkbox .switchery,
.alpaca-disabled .radio label,
.alpaca-disabled .radio .switchery {
  cursor: not-allowed;
  color: #999999;
}
.alpaca-invalid .form-control {
  color: #D84315;
}
.alpaca-clear {
  clear: both;
}
.alpaca-float-right {
  float: right;
}
.alpaca-field .glyphicon {
  font-family: 'icomoon';
  font-size: 16px;
  vertical-align: middle;
  top: -1px;
  display: inline-block;
  margin-right: 7px;
}
.alpaca-field .glyphicon-info-sign:before {
  content: '\e9ba';
}
.alpaca-field.has-error .glyphicon-info-sign:before,
.alpaca-field .glyphicon-exclamation-sign:before {
  content: '\ed63';
}
.alpaca-icon-required {
  font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
  float: right;
  margin-right: 0;
  margin-left: 5px;
}
.alpaca-icon-required:before {
  content: '*';
}
.alpaca-controlfield-editor {
  position: relative;
  width: 100%;
  height: 300px;
  border: 1px #ccc solid;
}
.alpaca-controlfield-editor .control-field-editor-el {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.ace_editor {
  border: 1px solid #cccccc;
}
.alpaca-field-ckeditor.alpaca-invalid > .cke {
  border-color: #D84315;
}
.alpaca-field-optiontree .optiontree + .form-control {
  margin-left: 10px;
}
.alpaca-field-optiontree.optiontree-horizontal .optiontree {
  display: inline-block;
}
.alpaca-field-optiontree.optiontree-horizontal .optiontree .optiontree-selector {
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
}
.alpaca-field-optiontree.optiontree-horizontal .optiontree .optiontree-selector:empty {
  display: none;
}
.alpaca-field-optiontree.optiontree-horizontal .optiontree .optiontree-selector + .optiontree-selector {
  margin-left: 10px;
}
.alpaca-field-optiontree.optiontree-horizontal input {
  display: inline-block;
  width: auto;
}
.alpaca-field-optiontree.optiontree-horizontal label {
  display: block;
}
.alpaca-field-radio.disabled .alpaca-control.radio {
  color: grey;
}
.alpaca-field-radio.disabled .alpaca-control.radio label {
  cursor: inherit;
}
.alpaca-control.radio {
  min-height: inherit;
  height: inherit;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 10px;
}
.has-error .multiselect {
  border-color: #D84315;
  color: #D84315;
}
.alpaca-container-label {
  margin-top: 20px;
}
legend.alpaca-container-label {
  margin-top: 0px;
}
.alpaca-array-toolbar {
  margin-bottom: 10px;
}
.alpaca-array-actionbar {
  overflow: hidden;
}
.alpaca-array-actionbar.alpaca-array-actionbar-top {
  padding-bottom: 10px;
}
.alpaca-array-actionbar.alpaca-array-actionbar-bottom {
  padding-top: 10px;
}
.alpaca-array-actionbar,
.alpaca-array-actionbar.btn-group {
  width: 100%;
}
.alpaca-field-object,
.alpaca-field-array {
  border: 1px #eee solid;
  border-radius: 3px;
  padding: 10px;
}
.alpaca-field-object .alpaca-top,
.alpaca-field-array .alpaca-top,
.alpaca-field-object .alpaca-top .alpaca-container,
.alpaca-field-array .alpaca-top .alpaca-container {
  border: 0;
}
.alpaca-container {
  border: 0px;
}
.alpaca-display h3 {
  margin-top: 0;
}
.alpaca-control-buttons-container {
  margin-top: 10px;
}
.alpaca-container-item > .alpaca-container > .alpaca-field-object {
  border: 0;
}
.alpaca-form-buttons-container {
  margin-top: 20px;
  text-align: right;
}
.alpaca-container > .form-group {
  margin-left: 0;
  margin-right: 0;
}
.alpaca-field-hidden {
  display: none;
}
.alpaca-container-item:not(:first-child) {
  margin-top: 10px;
}
.alpaca-container .alpaca-container-item:last-child {
  margin-bottom: 0;
}
.alpaca-field-select .btn-group {
  width: 100%;
}
.alpaca-field.alpaca-field-upload .fileupload-active-zone {
  margin-top: 50px;
  margin-bottom: 50px;
}
.alpaca-field.alpaca-field-upload .template-download TD.error,
.alpaca-field.alpaca-field-upload .template-upload TD.error {
  color: #D84315;
  word-break: break-all;
}
.alpaca-field.alpaca-field-address .alpaca-field-address-mapcanvas {
  height: 250px;
}
.alpaca-field.alpaca-field-image .alpaca-image-display {
  margin-top: 20px;
}
.alpaca-field-text-max-length-indicator {
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: 7px;
}
/* ------------------------------------------------------------------------------
*
*  # Tokenfield for Bootstrap
*
*  Styles for tokenfield.js - Advanced tagging/tokenizing plugin for Bootstrap
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.tokenfield {
  height: auto;
}
.tokenfield.form-control {
  height: auto;
  padding: 0 0 2px 0;
}
.tokenfield:after {
  content: '';
  display: table;
  clear: both;
}
.tokenfield .token {
  margin: 2px 0 0 2px;
  cursor: default;
  float: left;
  position: relative;
  border-radius: 2px;
}
.tokenfield .token:not([class*=bg-]) {
  background-color: #eee;
  color: #333333;
}
.tokenfield .token:not([class*=bg-]):hover,
.tokenfield .token:not([class*=bg-]):focus {
  background-color: #03A9F4;
  color: #fff;
}
.tokenfield .token.invalid {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  border-bottom: 1px dotted #F44336;
}
.tokenfield .token.invalid.active {
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
}
.tokenfield .token .token-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 11px;
  padding-right: 30px;
  font-size: 12px;
  line-height: 1.6666667;
}
.tokenfield .token > .close {
  font-size: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  color: inherit;
  right: 11px;
  line-height: 1;
  margin-top: -5.5px;
}
.tokenfield .token > .close:after {
  content: '\ed6a';
  font-family: 'icomoon';
  display: block;
  font-size: 11px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tokenfield .token > .close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.tokenfield .twitter-typeahead {
  width: auto;
}
.tokenfield .token-input {
  direction: ltr;
  background: none;
  width: 90px!important;
  max-width: 50%;
  min-width: 60px;
  padding: 5px 11px;
  margin-top: 2px;
  margin-left: 2px;
  font-size: 12px;
  line-height: 1.6666667;
  border: 0;
  outline: 0;
}
.tokenfield.readonly .token .token-label {
  padding-right: 11px;
}
.tokenfield.readonly .token .close {
  display: none;
}
.tokenfield.disabled {
  cursor: not-allowed;
}
.tokenfield.disabled .token,
.tokenfield.disabled .token .close {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.tokenfield.disabled .token:hover,
.tokenfield.disabled .token:focus {
  background-color: #eee;
  color: #333333;
}
.tokenfield.disabled,
.tokenfield.disabled .token-input,
.tokenfield.disabled .token,
.tokenfield.disabled .token .close {
  cursor: not-allowed;
}
.input-group-lg .token > .token-label,
.tokenfield.input-lg .token > .token-label,
.input-group-lg .token > span,
.tokenfield.input-lg .token > span,
.input-group-lg .token-input,
.tokenfield.input-lg .token-input {
  font-size: 13px;
  line-height: 20px;
  padding: 7px 12px;
}
.input-group-lg .token > .token-label,
.tokenfield.input-lg .token > .token-label,
.input-group-lg .token > span,
.tokenfield.input-lg .token > span {
  padding-right: 29px;
}
.input-group-lg .token > .close,
.tokenfield.input-lg .token > .close {
  right: 12px;
}
.input-group-lg.readonly .token > .token-label,
.tokenfield.input-lg.readonly .token > .token-label {
  padding-right: 12px;
}
.input-group-sm .token > .token-label,
.tokenfield.input-sm .token > .token-label,
.input-group-sm .token > span,
.tokenfield.input-sm .token > span {
  padding: 4px 11px;
  padding-right: 27.5px;
}
.input-group-sm .token-input,
.tokenfield.input-sm .token-input {
  padding: 4px 11px;
}
.input-group-sm .token > .close,
.tokenfield.input-sm .token > .close {
  right: 11px;
}
.input-group-sm.readonly .token > .token-label,
.tokenfield.input-sm.readonly .token > .token-label {
  padding-right: 11px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap tags input
*
*  Styles for tagsinput.js - tags input for Bootstrap
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 0 0 2px 0;
  vertical-align: middle;
  border-radius: 3px;
  width: 100%;
}
.bootstrap-tagsinput input {
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 5px 11px;
  margin-top: 2px;
  margin-left: 2px;
  width: auto !important;
  min-width: 100px;
  font-size: 12px;
  line-height: 1.6666667;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .twitter-typeahead {
  width: auto;
}
.bootstrap-tagsinput .tt-dropdown-menu {
  margin-top: 5px;
  min-width: 200px;
}
.bootstrap-tagsinput .tag {
  margin: 2px 0 0 2px;
  border: 0;
  border-radius: 2px;
  padding: 5px 11px;
  padding-right: 30px;
  float: left;
  font-size: 12px;
  line-height: 1.6666667;
  font-weight: 400;
  text-transform: none;
  position: relative;
}
.bootstrap-tagsinput .tag:not([class*=bg-]) {
  background-color: #546E7A;
  color: #fff;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  cursor: pointer;
  color: inherit;
  position: absolute;
  top: 50%;
  right: 11px;
  line-height: 1;
  margin-top: -5.5px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: '\ed6a';
  font-family: 'icomoon';
  display: block;
  font-size: 11px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ------------------------------------------------------------------------------
*
*  # Touchspin spinners
*
*  Styles for touchspin.min.js - spinner form control library
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-touchspin .input-group-btn-vertical {
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  height: 36px;
  line-height: 1.5384616;
  margin-left: -1px;
  border-radius: 0;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  font-weight: normal;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border: 1px solid #ddd;
  border-radius: 0;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.bootstrap-touchspin.input-group-lg .input-group-btn-vertical > .btn {
  height: 40px;
  line-height: 1.4285715;
  padding-left: 15px;
  padding-right: 15px;
}
.bootstrap-touchspin.input-group-sm .input-group-btn-vertical > .btn {
  height: 34px;
  line-height: 1.6666667;
  padding-left: 11px;
  padding-right: 11px;
}
.bootstrap-touchspin-prefix {
  border-right: 0;
}
.bootstrap-touchspin-postfix {
  border-left: 0;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap Duallistbox
*
*  Styles for listbox.js - A responsive dual listbox widget optimized for Bootstrap
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.bootstrap-duallistbox-container .box1 {
  margin-bottom: 20px;
}
.bootstrap-duallistbox-container select.form-control {
  padding: 7px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.bootstrap-duallistbox-container option {
  display: block;
  padding: 7px 12px;
  border-radius: 2px;
}
.bootstrap-duallistbox-container.moveonselect .move,
.bootstrap-duallistbox-container.moveonselect .remove {
  display: none;
}
.bootstrap-duallistbox-container.moveonselect .moveall,
.bootstrap-duallistbox-container.moveonselect .removeall {
  width: 100%;
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
}
.bootstrap-duallistbox-container.moveonselect .btn-group .btn + .btn {
  border-left-width: 1px;
}
.bootstrap-duallistbox-container .buttons {
  width: 100%;
  margin-bottom: -1px;
}
.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear2 {
  display: none;
  margin-top: 20px;
}
.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear2,
.bootstrap-duallistbox-container .clear1:hover,
.bootstrap-duallistbox-container .clear2:hover,
.bootstrap-duallistbox-container .clear1:focus,
.bootstrap-duallistbox-container .clear2:focus {
  background-color: transparent;
  border: 0;
  color: #1E88E5;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-duallistbox-container .box1.filtered .clear1,
.bootstrap-duallistbox-container .box2.filtered .clear2 {
  display: inline-block;
}
.bootstrap-duallistbox-container .move,
.bootstrap-duallistbox-container .remove,
.bootstrap-duallistbox-container .moveall,
.bootstrap-duallistbox-container .removeall {
  width: 50%;
}
.bootstrap-duallistbox-container .btn-group .btn {
  margin: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-duallistbox-container .btn-group .btn + .btn {
  border-left-width: 0;
}
.bootstrap-duallistbox-container .btn-group .btn > i {
  float: none;
  text-align: center;
}
.bootstrap-duallistbox-container .btn-group .btn > i + i {
  margin-left: -14px;
}
.bootstrap-duallistbox-container .filter {
  margin-bottom: 20px;
  position: relative;
}
.bootstrap-duallistbox-container .filter.placeholder {
  color: #999;
}
.bootstrap-duallistbox-container .info-container .info {
  display: inline-block;
  margin-top: 20px;
}
.bootstrap-duallistbox-container .info-container .info .label {
  margin-right: 7px;
}
.sidebar .bootstrap-duallistbox-container {
  text-align: center;
}
.sidebar .bootstrap-duallistbox-container .box1,
.sidebar .bootstrap-duallistbox-container .box2 {
  float: none;
  width: 100%;
}
.sidebar .bootstrap-duallistbox-container .box1 {
  padding-bottom: 5px;
}
.sidebar .bootstrap-duallistbox-container .box1:after {
  content: '\e9c9';
  font-size: 16px;
  font-family: 'icomoon';
  display: block;
  text-align: center;
  line-height: 1;
  margin: 10px 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
@media (min-width: 1025px) {
  .bootstrap-duallistbox-container .box1 {
    margin-bottom: 0;
  }
  .sidebar .bootstrap-duallistbox-container .box1 {
    margin-bottom: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap maxlength
 *
 *  Styles for maxlength.min.js - input characters counter
 *
 *  Version: 1.0
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.bootstrap-maxlength {
  margin-top: 7px;
  font-size: 12px;
  font-weight: 500;
}
/* ------------------------------------------------------------------------------
*
*  # Steps wizard
*
*  An all-in-one wizard plugin that is extremely flexible, compact and feature-rich
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.wizard {
  width: 100%;
}
.wizard > .steps .current-info,
.wizard > .content > .title {
  position: absolute;
  left: -99999px;
}
.wizard > .content {
  position: relative;
  width: auto;
  padding: 0;
}
.wizard > .content > .body {
  padding: 0 20px;
}
.wizard > .content > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.wizard > .steps > ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
}
.wizard > .steps > ul > li {
  display: table-cell;
  width: auto;
  vertical-align: top;
  text-align: center;
  position: relative;
}
.wizard > .steps > ul > li a {
  position: relative;
  padding-top: 48px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}
.wizard > .steps > ul > li:before,
.wizard > .steps > ul > li:after {
  content: '';
  display: block;
  position: absolute;
  top: 43px;
  width: 50%;
  height: 2px;
  background-color: #00BCD4;
  z-index: 9;
}
.wizard > .steps > ul > li:before {
  left: 0;
}
.wizard > .steps > ul > li:after {
  right: 0;
}
.wizard > .steps > ul > li:first-child:before,
.wizard > .steps > ul > li:last-child:after {
  content: none;
}
.wizard > .steps > ul > li.current:after,
.wizard > .steps > ul > li.current ~ li:before,
.wizard > .steps > ul > li.current ~ li:after {
  background-color: #eeeeee;
}
.wizard > .steps > ul > li.current > a {
  color: #333333;
  cursor: default;
}
.wizard > .steps > ul > li.current .number {
  font-size: 0;
  border-color: #00BCD4;
  background-color: #fff;
  color: #00BCD4;
}
.wizard > .steps > ul > li.current .number:after {
  content: '\e913';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 34px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.wizard > .steps > ul > li.disabled a,
.wizard > .steps > ul > li.disabled a:hover,
.wizard > .steps > ul > li.disabled a:focus {
  color: #999999;
  cursor: default;
}
.wizard > .steps > ul > li.done a,
.wizard > .steps > ul > li.done a:hover,
.wizard > .steps > ul > li.done a:focus {
  color: #999999;
}
.wizard > .steps > ul > li.done .number {
  font-size: 0;
  background-color: #00BCD4;
  border-color: #00BCD4;
  color: #fff;
}
.wizard > .steps > ul > li.done .number:after {
  content: '\ed6f';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 34px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.wizard > .steps > ul > li.error .number {
  border-color: #F44336;
  color: #F44336;
}
@media (max-width: 768px) {
  .wizard > .steps > ul {
    margin-bottom: 20px;
  }
  .wizard > .steps > ul > li {
    display: block;
    float: left;
    width: 50%;
  }
  .wizard > .steps > ul > li > a {
    margin-bottom: 0;
  }
  .wizard > .steps > ul > li:first-child:before,
  .wizard > .steps > ul > li:last-child:after {
    content: '';
  }
  .wizard > .steps > ul > li:last-child:after {
    background-color: #00BCD4;
  }
}
@media (max-width: 480px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
  .wizard > .steps > ul > li.current:after {
    background-color: #00BCD4;
  }
}
.wizard > .steps .number {
  background-color: #fff;
  color: #ccc;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -19px;
  width: 38px;
  height: 38px;
  border: 2px solid #eeeeee;
  font-size: 14px;
  border-radius: 50%;
  z-index: 10;
  line-height: 34px;
  text-align: center;
}
.panel-flat > .wizard > .steps > ul {
  border-top: 1px solid #ddd;
}
.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  padding: 20px;
  padding-top: 0;
}
.wizard > .actions > ul {
  float: right;
  list-style: none;
  padding: 0;
  margin: 0;
}
.wizard > .actions > ul:after {
  content: '';
  display: table;
  clear: both;
}
.wizard > .actions > ul > li {
  float: left;
}
.wizard > .actions > ul > li + li {
  margin-left: 10px;
}
.wizard > .actions > ul > li > a {
  background: #2196F3;
  color: #fff;
  display: block;
  padding: 7px 12px;
  border-radius: 3px;
  border: 1px solid transparent;
}
.wizard > .actions > ul > li > a:hover,
.wizard > .actions > ul > li > a:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.wizard > .actions > ul > li > a:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.wizard > .actions > ul > li > a[href="#previous"] {
  background-color: #fcfcfc;
  color: #333;
  border: 1px solid #ddd;
}
.wizard > .actions > ul > li > a[href="#previous"]:hover,
.wizard > .actions > ul > li > a[href="#previous"]:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.wizard > .actions > ul > li > a[href="#previous"]:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.wizard > .actions > ul > li.disabled > a,
.wizard > .actions > ul > li.disabled > a:hover,
.wizard > .actions > ul > li.disabled > a:focus {
  color: #999999;
}
.wizard > .actions > ul > li.disabled > a[href="#previous"],
.wizard > .actions > ul > li.disabled > a[href="#previous"]:hover,
.wizard > .actions > ul > li.disabled > a[href="#previous"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* ------------------------------------------------------------------------------
*
*  # Form wizard
*
*  jQuery plugin which turns a form into a multistep wizard
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.panel-flat > .ui-formwizard {
  border-top: 1px solid #ddd;
}
.form-wizard-title {
  margin: 0 0 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.form-wizard-count {
  float: left;
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid #4CAF50;
  color: #4CAF50;
  text-align: center;
  line-height: 34px;
  margin-right: 10px;
}
.ui-formwizard .step {
  padding: 20px;
  padding-bottom: 0;
}
.form-wizard-actions {
  text-align: right;
  padding: 20px;
  padding-top: 0;
}
.panel-body .form-wizard-actions {
  padding: 0;
}
.form-wizard-actions .btn + .btn {
  margin-left: 5px;
}
/* ------------------------------------------------------------------------------
*
*  # Stepy wizard
*
*  jQuery plugin which generates a customizable wizard from form fieldsets
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.stepy-step {
  padding: 20px;
  padding-top: 0;
}
.stepy-header {
  list-style: none;
  padding: 0;
  margin: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.panel-flat > .stepy-header {
  border-top: 1px solid #ddd;
}
.stepy-header li {
  cursor: pointer;
  display: table-cell;
  vertical-align: top;
  width: auto;
  padding: 20px 0;
  text-align: center;
  position: relative;
}
.stepy-header li span {
  display: block;
  margin-top: 10px;
  color: #999999;
}
.stepy-header li:before,
.stepy-header li:after {
  content: '';
  display: block;
  position: absolute;
  top: 43px;
  width: 50%;
  height: 2px;
  background-color: #00BCD4;
  z-index: 9;
}
.stepy-header li:before {
  left: 0;
}
.stepy-header li:after {
  right: 0;
}
.stepy-header li:first-child:before,
.stepy-header li:last-child:after {
  content: none;
}
.stepy-header li div {
  background-color: #00BCD4;
  font-size: 0;
  position: relative;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 38px;
  height: 38px;
  border: 2px solid #00BCD4;
  z-index: 10;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
}
.stepy-header li div:after {
  content: '\ed6c';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 34px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.stepy-header li.stepy-active:after,
.stepy-header li.stepy-active ~ li:before,
.stepy-header li.stepy-active ~ li:after {
  background-color: #f5f5f5;
}
.stepy-header li.stepy-active ~ li div {
  border-color: #eeeeee;
  background-color: #fff;
  color: #ccc;
  font-size: 14px;
}
.stepy-header li.stepy-active ~ li div:after {
  content: none;
}
.stepy-header li.stepy-active div {
  cursor: auto;
  border-color: #00BCD4;
  background-color: #fff;
  color: #00BCD4;
}
.stepy-header li.stepy-active div:after {
  content: '\e913';
}
.stepy-header li.stepy-active span {
  color: #333333;
}
@media (max-width: 769px) {
  .stepy-header {
    margin-bottom: 20px;
  }
  .stepy-header li {
    display: block;
    float: left;
    width: 50%;
    padding-bottom: 0;
  }
  .stepy-header li:first-child:before,
  .stepy-header li:last-child:after {
    content: '';
  }
  .stepy-header li.stepy-active:last-child:after {
    background-color: #00BCD4;
  }
}
@media (max-width: 480px) {
  .stepy-header li {
    width: 100%;
  }
  .stepy-header li.stepy-active:after {
    background-color: #00BCD4;
  }
}
.stepy-navigator {
  text-align: right;
}
.stepy-navigator .btn + .btn {
  margin-left: 5px;
}
/* ------------------------------------------------------------------------------
*
*  # Summernote editor
*
*  Super simple WYSIWYG Editor for Bootstrap framework
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("../css/icons/summernote//summernote.eot?ad8d7e2d177d2473aecd9b35d16211fb");
  src: url("../css/icons/summernote//summernote.eot?#iefix") format("embedded-opentype"), url("../css/icons/summernote//summernote.woff?ad8d7e2d177d2473aecd9b35d16211fb") format("woff"), url("../css/icons/summernote//summernote.ttf?ad8d7e2d177d2473aecd9b35d16211fb") format("truetype");
}
[class^="note-icon-"]:before,
[class*=" note-icon-"]:before {
  display: inline-block;
  font: normal normal normal 14px summernote;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.note-icon-align-center:before {
  content: "\f101";
}
.note-icon-align-indent:before {
  content: "\f102";
}
.note-icon-align-justify:before {
  content: "\f103";
}
.note-icon-align-left:before {
  content: "\f104";
}
.note-icon-align-outdent:before {
  content: "\f105";
}
.note-icon-align-right:before {
  content: "\f106";
}
.note-icon-align:before {
  content: "\f107";
}
.note-icon-arrows-alt:before {
  content: "\f108";
}
.note-icon-bold:before {
  content: "\f109";
}
.note-icon-caret:before {
  content: "\f10a";
}
.note-icon-chain-broken:before {
  content: "\f10b";
}
.note-icon-circle:before {
  content: "\f10c";
}
.note-icon-close:before {
  content: "\f10d";
}
.note-icon-code:before {
  content: "\f10e";
}
.note-icon-eraser:before {
  content: "\f10f";
}
.note-icon-font:before {
  content: "\f110";
}
.note-icon-frame:before {
  content: "\f111";
}
.note-icon-italic:before {
  content: "\f112";
}
.note-icon-link:before {
  content: "\f113";
}
.note-icon-magic:before {
  content: "\f114";
}
.note-icon-menu-check:before {
  content: "\f115";
}
.note-icon-minus:before {
  content: "\f116";
}
.note-icon-orderedlist:before {
  content: "\f117";
}
.note-icon-pencil:before {
  content: "\f118";
}
.note-icon-picture:before {
  content: "\f119";
}
.note-icon-question:before {
  content: "\f11a";
}
.note-icon-redo:before {
  content: "\f11b";
}
.note-icon-special-character:before {
  content: "\f11c";
}
.note-icon-square:before {
  content: "\f11d";
}
.note-icon-strikethrough:before {
  content: "\f11e";
}
.note-icon-subscript:before {
  content: "\f11f";
}
.note-icon-summernote:before {
  content: "\f120";
}
.note-icon-superscript:before {
  content: "\f121";
}
.note-icon-table:before {
  content: "\f122";
}
.note-icon-text-height:before {
  content: "\f123";
}
.note-icon-trash:before {
  content: "\f124";
}
.note-icon-underline:before {
  content: "\f125";
}
.note-icon-undo:before {
  content: "\f126";
}
.note-icon-unorderedlist:before {
  content: "\f127";
}
.note-icon-video:before {
  content: "\f128";
}
.note-editor {
  border: 1px solid #ddd;
  border-radius: 3px;
}
.note-editor .note-dropzone {
  position: absolute;
  z-index: 1;
  display: none;
  color: #2196F3;
  background-color: #fff;
  border: 2px dashed #2196F3;
  pointer-events: none;
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
}
.note-editor .note-dropzone.hover {
  color: #2196F3;
  border: 2px dashed #2196F3;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-toolbar {
  background-color: #fcfcfc;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
  padding-top: 0;
  margin: 0;
  border-radius: 3px;
}
.note-editor.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
}
.note-editor.fullscreen .note-editable {
  background-color: #fff;
}
.note-editor.fullscreen .note-resizebar {
  display: none;
}
.note-editor.codeview .note-editable {
  display: none;
}
.note-editor.codeview .note-codable {
  display: block;
}
.note-editor .note-statusbar {
  background-color: #fcfcfc;
}
.note-editor .note-statusbar .note-resizebar {
  width: 100%;
  height: 8px;
  cursor: s-resize;
  border-top: 1px solid #ddd;
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid #aaaaaa;
}
.note-editor .note-editable {
  padding: 20px;
  overflow: auto;
  outline: 0;
  min-height: 150px;
}
.note-editor .note-editable[contenteditable="false"] {
  background-color: #eeeeee;
}
.note-editor .note-codable {
  display: none;
  width: 100%;
  padding: 20px;
  margin-bottom: 0;
  font-family: Menlo, Monaco, monospace, sans-serif;
  color: #fff;
  background-color: #333333;
  border: 0;
  border-radius: 0;
  resize: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-editor .modal-title {
  font-size: 17px;
}
.note-air-editor {
  outline: 0;
}
.note-popover {
  max-width: none;
}
.note-popover .popover-content {
  padding: 15px;
  padding-top: 0;
  margin: 0;
}
.note-popover .popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.note-popover .popover-content > .btn-group:last-child {
  margin-right: 0;
}
.note-popover .arrow {
  left: 20px;
}
.note-popover .popover-content > .note-btn-group,
.note-toolbar > .note-btn-group {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 0;
}
.note-popover .popover-content > .note-btn-group .note-icon-caret:before,
.note-toolbar > .note-btn-group .note-icon-caret:before {
  width: 9px;
  margin-left: 2px;
  margin-right: 2px;
}
.note-popover .popover-content > .note-btn-group i + .note-icon-caret,
.note-toolbar > .note-btn-group i + .note-icon-caret {
  margin-left: 2px;
  margin-right: -5px;
}
.note-popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
  margin: 0;
}
.note-popover .popover-content .note-table,
.note-toolbar .note-table {
  min-width: 0;
}
.note-popover .popover-content .note-table.dropdown-menu,
.note-toolbar .note-table.dropdown-menu {
  padding: 10px;
}
.note-popover .popover-content .note-table .note-dimension-picker,
.note-toolbar .note-table .note-dimension-picker {
  font-size: 18px;
  margin-bottom: 10px;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute!important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative!important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute!important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover-content .note-table .note-dimension-display,
.note-toolbar .note-table .note-dimension-display {
  text-align: center;
}
.note-popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
  min-width: 330px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 7px 12px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin-bottom: 10px;
  font-weight: 500;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  padding: 6px 11px;
  cursor: pointer;
  background-color: #fcfcfc;
  text-align: center;
  margin-bottom: 10px;
}
.note-popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
  min-width: 290px;
  padding: 10px;
  text-align: center;
}
.note-popover .popover-content .note-para .dropdown-menu > div:first-child,
.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 10px;
}
.note-popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
  min-width: 90px;
}
.note-popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}
.note-popover .popover-content .dropdown-menu.right:before,
.note-toolbar .dropdown-menu.right:before {
  right: 9px;
  left: auto!important;
}
.note-popover .popover-content .dropdown-menu.right:after,
.note-toolbar .dropdown-menu.right:after {
  right: 10px;
  left: auto!important;
}
.note-popover .popover-content .dropdown-menu li a i,
.note-toolbar .dropdown-menu li a i {
  display: none;
}
.note-popover .popover-content .dropdown-menu li a.checked,
.note-toolbar .dropdown-menu li a.checked {
  background-color: #f5f5f5;
}
.note-popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
  line-height: 1;
}
.note-popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
  width: 17px;
  height: 17px;
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
}
.note-popover .popover-content .note-color-palette div .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #333333;
}
.note-popover .popover-content > .btn-group {
  margin-top: 15px;
  margin-right: 15px;
}
.note-dialog > div {
  display: none;
}
.note-dialog .note-image-dialog .mote-dropzone {
  min-height: 100px;
  margin-bottom: 10px;
  font-size: 25px;
  color: #eeeeee;
  text-align: center;
  border: 4px dashed #eeeeee;
}
.note-dialog .note-help-dialog {
  color: #ccc;
  background-color: transparent;
  background-color: #333333 !important;
  border: 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.note-dialog .note-help-dialog a {
  color: #fff;
}
.note-dialog .note-help-dialog .title {
  padding-bottom: 7px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border-bottom: 1px solid #fff;
}
.note-dialog .note-help-dialog .modal-content {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-dialog .note-help-dialog .modal-close {
  color: #999999;
  cursor: pointer;
}
.note-dialog .note-help-dialog .note-shortcut-layout {
  width: 100%;
}
.note-dialog .note-help-dialog .note-shortcut-layout td {
  vertical-align: top;
}
.note-dialog .note-help-dialog .note-shortcut {
  margin-top: 8px;
}
.note-dialog .note-help-dialog .note-shortcut th {
  color: #eeeeee;
  text-align: left;
}
.note-dialog .note-help-dialog .note-shortcut td:first-child {
  min-width: 110px;
  padding-right: 10px;
  font-family: "Courier New";
  color: #eeeeee;
  text-align: right;
}
.note-group-select-from-files label {
  display: block;
}
.note-handle {
  position: relative;
}
.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid #333333;
  z-index: 10;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: #333333;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder,
.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  border: 1px solid #333333;
}
.note-handle .note-control-selection .note-control-sizing {
  background-color: #fff;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: 0;
  border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: 0;
  border-left: 0;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: 0;
  border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  color: #fff;
  background-color: #333333;
  border-radius: 3px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
/* ------------------------------------------------------------------------------
*
*  # WYSIHTML5 editor
*
*  An open source rich text editor based on HTML5 technology
*
*  Version: 1.2
*  Latest update: Jun 8, 2016
*
* ---------------------------------------------------------------------------- */
.wysihtml5 {
  max-height: 450px;
  resize: vertical;
  background-color: #fff;
  overflow: auto;
  padding: 20px;
  outline: 0;
}
.wysihtml5-editor {
  border-width: 0;
  padding: 0;
}
.wysihtml5-sandbox {
  width: 100%!important;
}
.bootstrap-wysihtml5-insert-link-modal .modal-dialog,
.bootstrap-wysihtml5-insert-image-modal .modal-dialog {
  width: 566px;
}
.wysihtml5-toolbar {
  margin: 0;
  padding: 15px 20px;
  padding-bottom: 0;
  display: block;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  border-bottom: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.wysihtml5-toolbar > li {
  display: inline-block;
  list-style: none;
  margin: 0 15px 15px 0;
}
.wysihtml5-toolbar .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.wysihtml5-toolbar .dropdown-menu .color-select > li > a:hover,
.wysihtml5-toolbar .dropdown-menu .color-select > li > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.wysihtml5-commands-disabled .wysihtml5-toolbar .dropdown-menu {
  display: none !important;
}
.wysihtml5-toolbar .dropdown-toggle > [class*=icon-] {
  margin-right: 7px;
}
.wysihtml5-toolbar .dropdown-toggle .caret {
  margin-left: 7px;
}
.wysihtml5-toolbar a[data-wysihtml5-command=bold] {
  font-weight: bold;
}
.wysihtml5-toolbar a[data-wysihtml5-command=italic] {
  font-style: italic;
}
.wysihtml5-toolbar a[data-wysihtml5-command=underline] {
  text-decoration: underline;
}
.wysihtml5-toolbar a.btn.wysihtml5-command-active {
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.wysihtml5-toolbar a.wysihtml5-colors-title {
  padding-left: 40px;
}
.wysihtml5-toolbar .wysihtml5-colors {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  border-radius: 100px;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="black"] {
  background: black !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="silver"] {
  background: silver !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="gray"] {
  background: gray !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="maroon"] {
  background: maroon !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="red"] {
  background: red !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="purple"] {
  background: purple !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="green"] {
  background: green !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="olive"] {
  background: olive !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="navy"] {
  background: navy !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="blue"] {
  background: blue !important;
}
.wysihtml5-toolbar div[data-wysihtml5-command-value="orange"] {
  background: orange !important;
}
.wysiwyg-color-black {
  color: black;
}
.wysiwyg-color-silver {
  color: silver;
}
.wysiwyg-color-gray {
  color: gray;
}
.wysiwyg-color-white {
  color: white;
}
.wysiwyg-color-maroon {
  color: maroon;
}
.wysiwyg-color-red {
  color: red;
}
.wysiwyg-color-purple {
  color: purple;
}
.wysiwyg-color-fuchsia {
  color: fuchsia;
}
.wysiwyg-color-green {
  color: green;
}
.wysiwyg-color-lime {
  color: lime;
}
.wysiwyg-color-olive {
  color: olive;
}
.wysiwyg-color-yellow {
  color: yellow;
}
.wysiwyg-color-navy {
  color: navy;
}
.wysiwyg-color-blue {
  color: blue;
}
.wysiwyg-color-teal {
  color: teal;
}
.wysiwyg-color-aqua {
  color: aqua;
}
.wysiwyg-color-orange {
  color: orange;
}
/* ------------------------------------------------------------------------------
 *
 *  # Ace code editor
 *
 *  An embeddable code editor written in JavaScript
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.ace_editor {
  height: 400px;
  position: relative;
}
/* ------------------------------------------------------------------------------
 *
 *  # Daterange picker
 *
 *  Date range picker component for Bootstrap
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.daterangepicker {
  position: absolute;
  left: 0;
  margin-top: 5px;
  width: auto;
  padding: 0;
}
.daterangepicker.dropdown-menu {
  max-width: none;
  background-color: transparent;
  border: 0;
  z-index: 1000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.daterangepicker.dropup {
  margin-top: -7px;
}
.daterangepicker .ranges,
.daterangepicker .calendar {
  float: left;
}
.daterangepicker.opensleft .calendars {
  float: left;
}
.daterangepicker.opensright .calendars {
  float: right;
}
.daterangepicker.single .calendar {
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.daterangepicker.single .ranges {
  display: none;
}
.daterangepicker.show-calendar .calendar {
  display: block;
}
.daterangepicker .calendar {
  display: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 7px;
  padding: 14px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.daterangepicker table {
  width: 100%;
  margin: 0;
}
.daterangepicker table tbody th,
.daterangepicker table tbody td {
  cursor: pointer;
}
.daterangepicker th,
.daterangepicker td {
  white-space: nowrap;
  text-align: center;
}
.daterangepicker th.week,
.daterangepicker td.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker th {
  color: #999999;
  font-weight: normal;
  font-size: 12px;
}
.daterangepicker th > i {
  top: 0;
}
.daterangepicker th.prev,
.daterangepicker th.next {
  cursor: pointer;
}
.daterangepicker th.available:hover,
.daterangepicker th.available:focus {
  color: #333333;
}
.daterangepicker td.available:hover,
.daterangepicker td.available:focus {
  background-color: #f5f5f5;
}
.daterangepicker td.off,
.daterangepicker td.disabled {
  color: #ccc;
}
.daterangepicker td.disabled {
  cursor: not-allowed;
}
.daterangepicker td.in-range {
  background-color: #f5f5f5;
}
.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker td.active:focus {
  background-color: #26A69A;
  color: #fff;
  border-radius: 3px;
}
.daterangepicker .table-condensed tr > th,
.daterangepicker .table-condensed tr > td {
  padding: 10px;
  line-height: 1;
}
.daterangepicker .table-condensed thead tr:last-child th {
  padding-top: 14px;
}
.daterangepicker .table-condensed .month {
  font-size: 15px;
  line-height: 1;
  color: #333333;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
}
.daterangepicker select {
  display: inline-block;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 60px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.daterangepicker .daterangepicker_input {
  position: relative;
}
.daterangepicker .daterangepicker_input i {
  position: absolute;
  right: 11px;
  top: auto;
  bottom: 10px;
  color: #999999;
}
.daterangepicker .daterangepicker_input input {
  padding-left: 11px;
  padding-right: 34px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 12px 0;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.ranges {
  background-color: #fff;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 200px;
  margin-top: 7px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.opensright .ranges {
  margin-left: 0;
}
.opensleft .ranges {
  margin-right: 0;
}
.ranges ul {
  list-style: none;
  margin: 0;
  padding: 7px 0;
}
.ranges ul + .daterangepicker-inputs {
  border-top: 1px solid #e5e5e5;
}
.ranges ul li {
  color: #333333;
  padding: 8px 12px;
  cursor: pointer;
  margin-top: 1px;
}
.ranges ul li:first-child {
  margin-top: 0;
}
.ranges ul li:hover,
.ranges ul li:focus {
  background-color: #f5f5f5;
}
.ranges ul li.active {
  color: #fff;
  background-color: #26A69A;
}
.ranges .daterangepicker-inputs {
  padding: 12px;
  padding-top: 19px;
}
.ranges .daterangepicker-inputs .daterangepicker_input + .daterangepicker_input {
  margin-top: 19px;
}
.ranges .daterangepicker-inputs .daterangepicker_input > span {
  display: block;
  font-size: 12px;
  margin-bottom: 7px;
  color: #999999;
}
.ranges .daterangepicker-inputs + .range_inputs {
  border-top: 1px solid #e5e5e5;
}
.ranges .range_inputs {
  padding: 12px;
}
.ranges .range_inputs .btn {
  display: block;
  width: 100%;
}
.ranges .range_inputs .btn + .btn {
  margin-top: 12px;
}
@media (min-width: 769px) {
  .ranges {
    margin: 7px;
  }
}
.daterange-custom {
  cursor: pointer;
}
.daterange-custom:after {
  content: '';
  display: table;
  clear: both;
}
.daterange-custom .label,
.daterange-custom .badge {
  margin: 4px 0 0 7px;
  vertical-align: top;
}
.daterange-custom .label-icon {
  margin-top: 0;
  margin-right: 5px;
}
.daterange-custom-display {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  line-height: 1;
}
.daterange-custom-display:after {
  content: '\e9c9';
  font-family: 'icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}
.daterange-custom.is-opened .daterange-custom-display:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.daterange-custom-display > i {
  display: inline-block;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.015em;
}
.daterange-custom-display b {
  display: inline-block;
  margin-left: 4px;
  font-weight: 400;
}
.daterange-custom-display b > i {
  font-size: 11px;
  display: block;
  line-height: 12px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
}
.daterange-custom-display em {
  line-height: 30px;
  vertical-align: top;
  margin: 0 4px;
}
@media (max-width: 769px) {
  .opensleft,
  .opensright {
    left: 0!important;
    right: 0;
  }
  .opensleft .calendars,
  .opensright .calendars {
    float: none;
  }
  .daterangepicker.opensleft .ranges,
  .daterangepicker.opensright .ranges,
  .daterangepicker.opensleft .calendar,
  .daterangepicker.opensright .calendar,
  .daterangepicker.opensleft .calendars,
  .daterangepicker.opensright .calendars {
    float: none;
  }
  .daterangepicker {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .daterangepicker .calendar {
    margin-left: 0;
    margin-right: 0;
  }
  .daterangepicker .ranges {
    width: 100%;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Picker base
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
*  Version: 1.2
*  Latest update: Jul 5, 2016
*
* ---------------------------------------------------------------------------- */
.picker {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 100%;
  margin-top: -1px;
  z-index: 990;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.picker__input {
  cursor: default;
}
.picker__holder {
  width: 100%;
  overflow-y: auto;
  position: absolute;
  display: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top-width: 0;
  border-bottom-width: 0;
  max-width: 290px;
  max-height: 0;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.picker--opened .picker__holder {
  max-height: 480px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  display: block;
}
/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Date picker
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.picker__box {
  padding: 14px;
}
.picker__header {
  text-align: center;
  position: relative;
  font-size: 15px;
  line-height: 1;
  padding-top: 15px;
  padding-bottom: 15px;
}
.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.picker__year {
  color: #999999;
  font-size: 12px;
  font-weight: normal;
}
.picker__select--month,
.picker__select--year {
  border-color: #ddd;
  height: 32px;
  font-size: 13px;
  line-height: 1.6666667;
  margin-left: 5px;
  margin-right: 5px;
  outline: 0;
}
.picker__select--month {
  width: 35%;
}
.picker__select--year {
  width: 22.5%;
}
.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  padding: 8px;
  top: 50%;
  margin-top: -16px;
  border-radius: 2px;
  line-height: 1;
}
.picker__nav--prev:before,
.picker__nav--next:before {
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  width: 16px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.picker__nav--prev {
  left: 0;
}
.picker__nav--prev:before {
  content: '\e9c8';
}
.picker__nav--next {
  right: 0;
}
.picker__nav--next:before {
  content: '\e9cb';
}
.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #f5f5f5;
  border-left-color: #f5f5f5;
}
.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-bottom: 14px;
}
.picker__table td {
  margin: 0;
  padding: 0;
}
.picker__weekday {
  width: 14.285714286%;
  font-size: 12px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 14px;
  color: #999999;
  font-weight: 400;
}
.picker__day {
  padding: 7px;
}
.picker__day--today {
  position: relative;
  background-color: #f5f5f5;
}
.picker__day--today:before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 6px solid #26A69A;
  border-left: 6px solid transparent;
}
.picker__day--outfocus {
  color: #ccc;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  color: #333333;
  background-color: #f5f5f5;
}
.picker__day--highlighted:before {
  border-top-color: #fff;
}
.picker__day--highlighted,
.picker__day--selected {
  border-radius: 3px;
}
.picker__day--highlighted,
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #fff;
  background-color: #26A69A;
}
.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background-color: #26A69A;
  color: #fff;
}
.picker__day--disabled,
.picker__day--disabled:hover {
  background: #fafafa;
  color: #999999;
  cursor: default;
}
.picker__day--disabled:before {
  border-top-color: #999;
}
.picker__day--highlighted .picker__day--disabled,
.picker__day--highlighted .picker__day--disabled:hover {
  background-color: #bbbbbb;
}
.picker__footer {
  text-align: center;
}
.picker__footer button {
  border: 0;
  background: #fff;
  padding: 6px 11px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}
.picker__footer button:hover,
.picker__footer button:focus {
  outline: 0;
  background-color: #f5f5f5;
}
.picker__footer button:before {
  height: 0;
}
.picker__button--today:before {
  content: '';
  margin-right: 5px;
  position: relative;
  display: inline-block;
  top: -1px;
  width: 0;
  border-top: 6px solid #2196F3;
  border-left: 6px solid transparent;
}
.picker__button--close:before {
  content: '\D7';
  display: inline-block;
  position: relative;
  margin-right: 5px;
  top: 1px;
  line-height: 1;
  font-size: 16px;
}
.picker__button--clear:before {
  content: '';
  display: inline-block;
  position: relative;
  top: -3px;
  width: 8px;
  margin-right: 5px;
  border-top: 2px solid #F44336;
}
/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Time picker
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.picker--time {
  min-width: 256px;
  max-width: 320px;
}
.picker--time .picker__box {
  padding: 0;
}
.picker__list {
  list-style: none;
  padding: 7px 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
}
.picker__list-item {
  position: relative;
  padding: 7px 12px;
}
.picker__list-item:hover,
.picker__list-item:focus {
  cursor: pointer;
  background-color: #f5f5f5;
  z-index: 10;
}
.picker--time .picker__button--clear {
  display: block;
  width: 100%;
  margin: 0;
  padding: 6px 11px;
  background-color: #fcfcfc;
  margin-top: 1px;
  outline: 0;
  border: 0;
  border-top: 1px solid #eeeeee;
  text-align: center;
  margin-bottom: -7px;
  margin-top: 7px;
}
.picker--time .picker__button--clear:hover,
.picker--time .picker__button--clear:focus {
  background-color: #f5f5f5;
}
.picker__list-item--highlighted {
  z-index: 10;
}
.picker__list-item--highlighted,
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted {
  cursor: pointer;
  color: #fff;
  background-color: #26A69A;
}
.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected {
  background-color: #26A69A;
  color: #fff;
  z-index: 10;
}
.picker__list-item--disabled,
.picker__list-item--disabled:hover,
.picker--focused .picker__list-item--disabled {
  background-color: #fafafa;
  color: #999999;
  cursor: not-allowed;
  z-index: auto;
}
/* ------------------------------------------------------------------------------
*
*  # Anytime picker
*
*  jQuery datepicker/timepicker and a Date/String parse/format utility
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.AnyTime-pkr {
  text-align: center;
}
.AnyTime-pkr * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.AnyTime-pkr .AnyTime-date {
  float: left;
}
.AnyTime-pkr .AnyTime-date + .AnyTime-time {
  margin-left: 50px;
  float: left;
}
.AnyTime-win {
  padding: 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  border-radius: 3px;
  z-index: 10;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.AnyTime-cloak {
  position: absolute;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.AnyTime-hdr {
  font-size: 15px;
  margin: 15px 10px;
  line-height: 1;
}
.AnyTime-x-btn {
  display: none;
  font-size: 0;
  cursor: pointer;
  float: right;
  border-radius: 2px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.AnyTime-x-btn:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.AnyTime-x-btn:after {
  content: '\ed6b';
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.AnyTime-lbl {
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 25px;
}
.AnyTime-lbl:first-child {
  margin-top: 7px;
}
.AnyTime-yrs .ui-state-highlight,
.AnyTime-yrs .ui-state-highlight:hover {
  background-color: #F44336;
  color: #fff;
}
.AnyTime-mons .ui-state-highlight,
.AnyTime-mons .ui-state-highlight:hover {
  background-color: #26A69A;
  color: #fff;
}
.AnyTime-time .ui-state-highlight,
.AnyTime-time .ui-state-highlight:hover {
  background-color: #607D8B;
  color: #fff;
}
.AnyTime-dom-table .ui-state-highlight,
.AnyTime-body-yr-selector .ui-state-highlight,
.AnyTime-dom-table .ui-state-highlight:hover,
.AnyTime-body-yr-selector .ui-state-highlight:hover {
  background-color: #2196F3;
  color: #fff;
}
.AnyTime-yrs-past-btn,
.AnyTime-yrs-ahead-btn {
  display: inline-block;
}
.AnyTime-yr-prior-btn,
.AnyTime-yr-cur-btn,
.AnyTime-yr-next-btn {
  display: inline-block;
  min-width: 50px;
}
.AnyTime-mons:after {
  content: "";
  display: table;
  clear: both;
}
.AnyTime-dom-table {
  width: 100%;
}
.AnyTime-pkr th.AnyTime-dow {
  color: #999999;
  font-size: 12px;
  padding: 7px;
  font-weight: 400;
}
.AnyTime-mon-btn {
  float: left;
}
.AnyTime-mon7-btn {
  clear: left;
}
.AnyTime-dom-btn:hover {
  background-color: #f5f5f5;
}
.AnyTime-dom-btn-empty {
  display: none;
}
.AnyTime-time,
.AnyTime-hrs-am,
.AnyTime-hrs-pm,
.AnyTime-mins-tens,
.AnyTime-mins-ones,
.AnyTime-secs-tens,
.AnyTime-secs-ones,
.AnyTime-hrs,
.AnyTime-mins,
.AnyTime-secs,
.AnyTime-offs {
  display: inline-block;
}
.AnyTime-btn {
  padding: 7px 12px;
  cursor: pointer;
  border-radius: 2px;
}
.AnyTime-btn:hover {
  background-color: #f5f5f5;
}
.AnyTime-hr-btn,
.AnyTime-min-ten-btn,
.AnyTime-min-one-btn,
.AnyTime-sec-ten-btn,
.AnyTime-sec-one-btn {
  min-width: 40px;
}
.AnyTime-hrs-pm,
.AnyTime-mins-ones,
.AnyTime-secs-ones {
  margin-left: 2px;
}
.AnyTime-mins-tens,
.AnyTime-secs-tens {
  margin-left: 20px;
}
.AnyTime-off-cur-btn {
  display: inline-block;
  overflow: hidden;
}
.AnyTime-off-select-btn {
  display: inline-block;
  vertical-align: top;
}
.AnyTime-off-selector {
  margin: 10px;
  position: absolute;
}
.AnyTime-body-off-selector {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
}
.AnyTime-off-off-btn {
  text-align: left;
}
.AnyTime-yr-selector {
  position: absolute;
  width: 90%;
}
.AnyTime-body-yr-selector {
  margin: 0;
}
.AnyTime-yr-mil,
.AnyTime-yr-cent,
.AnyTime-yr-dec,
.AnyTime-yr-yr,
.AnyTime-yr-era {
  display: inline-block;
  vertical-align: top;
  width: 20%;
}
.AnyTime-era-btn {
  margin-left: 10px;
}
@media (max-width: 769px) {
  .AnyTime-pkr .AnyTime-date {
    float: none;
  }
  .AnyTime-mon-btn {
    float: none;
    display: inline-block;
  }
  .AnyTime-date + .AnyTime-time {
    margin-left: 0;
    margin-top: 25px;
    float: none;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Spectrum color picker
*
*  Flexible and powerful jQuery colorpicker library
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.sp-sat,
.sp-val,
.sp-top-inner,
.sp-color,
.sp-hue,
.sp-clear-enabled .sp-clear,
.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sp-container.sp-input-disabled .sp-input-container,
.sp-container.sp-buttons-disabled .sp-button-container,
.sp-container.sp-palette-buttons-disabled .sp-palette-button-container,
.sp-palette-only .sp-picker-container,
.sp-palette-disabled .sp-palette-container,
.sp-initial-disabled .sp-initial {
  display: none;
}
.sp-hidden {
  display: none!important;
}
.sp-cf:before,
.sp-cf:after {
  content: "";
  display: table;
}
.sp-cf:after {
  clear: both;
}
.sp-preview,
.sp-alpha,
.sp-thumb-el {
  position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}
.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  display: block;
}
.sp-container {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.sp-container.sp-flat {
  position: relative;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
}
.sp-picker-container,
.sp-palette-container {
  display: block;
  white-space: nowrap;
  vertical-align: top;
  position: relative;
  padding: 10px;
  padding-bottom: 300px;
  margin-bottom: -290px;
}
@media (min-width: 769px) {
  .sp-picker-container,
  .sp-palette-container {
    display: inline-block;
  }
}
.sp-picker-container {
  width: 220px;
}
.sp-container,
.sp-replacer,
.sp-preview,
.sp-dragger,
.sp-slider,
.sp-alpha,
.sp-clear,
.sp-alpha-handle,
.sp-container.sp-dragging .sp-input,
.sp-container button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sp-top {
  position: relative;
  width: 100%;
  display: block;
}
.sp-color {
  right: 20%;
}
.sp-hue {
  left: 85%;
  height: 100%;
}
.sp-clear-enabled .sp-hue {
  top: 40px;
  height: 75%;
}
.sp-fill {
  padding-top: 80%;
}
.sp-alpha-enabled .sp-top {
  margin-bottom: 26px;
}
.sp-alpha-enabled .sp-alpha {
  display: block;
}
.sp-alpha-handle {
  position: absolute;
  top: -4px;
  bottom: -4px;
  width: 5px;
  left: 50%;
  cursor: pointer;
  border: 1px solid #999999;
  background-color: #fff;
  border-radius: 5px;
}
.sp-alpha {
  display: none;
  position: absolute;
  bottom: -16px;
  right: 0;
  left: 0;
  height: 6px;
}
.sp-clear {
  display: none;
}
.sp-clear-display {
  cursor: pointer;
}
.sp-clear-display:after {
  content: '\ee6e';
  display: block;
  font-family: 'icomoon';
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 30px;
}
.sp-preview .sp-clear-display:after,
.sp-initial .sp-clear-display:after {
  content: none;
}
.sp-clear-enabled .sp-clear {
  display: block;
  left: 85%;
  height: 30px;
  text-align: center;
  color: #999;
  -webkit-box-shadow: 0 0 0 1px #ddd inset;
  box-shadow: 0 0 0 1px #ddd inset;
}
.sp-input-container {
  margin-top: 10px;
}
.sp-initial-disabled .sp-input-container {
  width: 100%;
}
.sp-input {
  border: 1px solid #ddd;
  padding: 7px 12px;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  outline: 0;
  color: #333333;
}
.sp-initial {
  margin-top: 10px;
}
.sp-initial span {
  width: 50%;
  height: 25px;
  display: block;
  float: left;
}
.sp-initial span .sp-thumb-inner {
  height: 25px;
  width: 100%;
  display: block;
}
.sp-dragger {
  height: 5px;
  width: 5px;
  border: 1px solid #fff;
  background-color: #333;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.sp-slider {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 4px;
  left: -2px;
  right: -2px;
  border: 1px solid #999999;
  background-color: #fff;
  border-radius: 3px;
}
.sp-replacer {
  overflow: hidden;
  cursor: pointer;
  padding: 5px;
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #fafafa;
  vertical-align: middle;
  border-radius: 3px;
}
.sp-replacer:hover,
.sp-replacer.sp-active {
  border-color: #c4c4c4;
  color: #333333;
}
.sp-replacer:hover .sp-dd,
.sp-replacer.sp-active .sp-dd {
  color: #333333;
}
.sp-replacer[class*=bg-],
.sp-replacer[class*=bg-]:hover,
.sp-replacer[class*=bg-]:focus {
  border-color: transparent;
}
.sp-replacer[class*=bg-] .sp-dd {
  color: #fff;
}
.sp-replacer[class*=bg-].sp-active .sp-dd {
  color: #fff;
}
.sp-replacer.sp-disabled {
  cursor: default;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.sp-replacer.sp-disabled:not([class*=bg-]):hover,
.sp-replacer.sp-disabled:not([class*=bg-]):focus {
  border-color: #ddd;
}
.sp-replacer.sp-disabled .sp-dd,
.sp-replacer.sp-disabled[class*=bg-] .sp-dd {
  color: #999999;
}
.sp-preview {
  position: relative;
  width: 26px;
  height: 24px;
  margin-right: 5px;
  float: left;
  z-index: 0;
}
.sp-preview .sp-preview-inner,
.sp-preview .sp-clear-display {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.sp-replacer[class*=bg-] .sp-preview .sp-preview-inner {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.sp-dd {
  float: left;
  font-size: 0;
  position: relative;
  margin: 3px 1px;
  color: #999999;
}
.sp-dd:after {
  content: '\e9c5';
  display: block;
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sp-sat {
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#FFF), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(left, #FFF, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to right, #fff, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81');
  -webkit-box-shadow: 0 0 0 1px #ccc inset;
  box-shadow: 0 0 0 1px #ccc inset;
}
.sp-val {
  background-image: -webkit-gradient(linear, 0 100%, 0 0, from(#000000), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to top, #000, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000');
}
.sp-hue {
  background: -moz-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: linear-gradient(to bottom, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}
.sp-1 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ffff00');
}
.sp-2 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
}
.sp-3 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
}
.sp-4 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
}
.sp-5 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
}
.sp-6 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='#ff0000');
}
.sp-palette {
  max-width: 220px;
}
.sp-palette-only .sp-palette-container {
  border: 0;
}
@media (min-width: 769px) {
  .sp-palette-container {
    border-right: 1px solid #ddd;
  }
}
.sp-thumb-el {
  position: relative;
}
.sp-palette .sp-thumb-el {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.sp-thumb-el .sp-thumb-inner {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
.sp-thumb-el .sp-thumb-inner:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
}
.sp-palette .sp-thumb-el {
  width: 16px;
  height: 16px;
}
.sp-palette .sp-thumb-el + .sp-thumb-el {
  margin-left: 5px;
}
.sp-palette .sp-thumb-el.sp-thumb-active {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
}
.sp-palette .sp-thumb-el.sp-thumb-active .sp-thumb-inner {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
}
.sp-palette .sp-thumb-active.sp-thumb-dark .sp-thumb-inner:after,
.sp-palette .sp-thumb-active.sp-thumb-light .sp-thumb-inner:after {
  content: '\e600';
  display: block;
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sp-palette .sp-thumb-active.sp-thumb-light .sp-thumb-inner:after {
  color: #333333;
}
.sp-palette-row {
  font-size: 0;
}
.sp-palette-row + .sp-palette-row {
  margin-top: 5px;
}
.sp-palette-row:empty {
  margin-top: 0;
}
.sp-cancel,
.sp-choose,
.sp-palette-toggle {
  border: 0;
  border-radius: 2px;
  padding: 6px 11px;
  float: left;
  width: 48%;
  text-align: center;
  outline: 0;
}
.sp-palette-button-container,
.sp-button-container {
  margin-top: 10px;
  text-align: center;
}
.sp-container[class*=bg-] .sp-palette-button-container a,
.sp-container[class*=bg-] .sp-button-container a,
.sp-container[class*=bg-] .sp-palette-button-container button,
.sp-container[class*=bg-] .sp-button-container button {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  border-color: transparent;
}
.sp-cancel,
.sp-palette-toggle {
  border: 1px solid transparent;
}
.sp-cancel:hover,
.sp-palette-toggle:hover,
.sp-cancel:focus,
.sp-palette-toggle:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.sp-cancel:active,
.sp-palette-toggle:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.sp-choose {
  border: 1px solid transparent;
}
.sp-choose:hover,
.sp-choose:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.sp-choose:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.sp-cancel,
.sp-palette-toggle {
  color: #333;
  margin-right: 5px;
  background-color: #fcfcfc;
  margin-right: 4%;
  border-color: #ddd;
}
.sp-cancel:hover,
.sp-palette-toggle:hover,
.sp-cancel:focus,
.sp-palette-toggle:focus {
  color: #333;
}
.sp-palette-toggle {
  margin-right: 0;
  width: auto;
  float: none;
}
.sp-choose {
  color: #fff;
  background-color: #607D8B;
}
/* ------------------------------------------------------------------------------
 *
 *  # Datepicker for Bootstrap
 *
 *  Styles for bootstrap datepicker. Comes as a part of datepaginator.js plugin
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.datepicker > div {
  display: none;
}
.datepicker.datepicker-dropdown {
  top: 0;
  left: 0;
  margin: 0;
  padding: 14px;
}
.datepicker table {
  margin: 0;
}
.datepicker table tr td.day:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999999;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background-color: transparent;
  color: #999999;
  cursor: default;
}
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:hover {
  background-color: #f5f5f5;
}
.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:hover {
  background-color: #f5f5f5;
  border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.active:hover {
  background-color: #f5f5f5;
}
.datepicker table tr td.active,
.datepicker table tr td span.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td span.active.disabled,
.datepicker table tr td.active:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover {
  background-color: #26A69A;
  color: #fff;
}
.datepicker table tr td span {
  display: block;
  width: 31%;
  padding: 7px 12px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 3px;
}
.datepicker table tr td span:hover {
  background-color: #f5f5f5;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background-color: transparent;
  color: #999999;
  cursor: default;
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999999;
}
.datepicker table th.datepicker-switch {
  font-size: 15px;
  font-weight: 400;
}
.datepicker table thead tr:first-child th,
.datepicker table tfoot tr th {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 1;
}
.datepicker table .cw {
  width: 16px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.datepicker table thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent;
}
.datepicker .table-condensed td,
.datepicker .table-condensed th {
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  border: 0;
  line-height: 1;
}
.datepicker .table-condensed th.dow {
  padding-top: 14px;
  color: #999999;
}
.table-striped .datepicker table tr th,
.table-striped .datepicker table tr td {
  background-color: transparent;
}
/* ------------------------------------------------------------------------------
 *
 *  # Date paginator
 *
 *  A jQuery plugin which adds date selection to the Bootstrap pagination component
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.datepaginator {
  height: 72px;
  text-align: center;
}
.datepaginator-sm {
  height: 68px;
}
.datepaginator-lg {
  height: 76px;
}
.datepaginator .pagination {
  white-space: nowrap;
}
.datepaginator .pagination > li {
  display: inline-block;
}
.datepaginator .pagination > li > a {
  min-width: auto;
  text-align: center;
  border-radius: 3px;
  margin-left: 0;
  border: 0;
}
.datepaginator .pagination > li .dp-nav-square-edges {
  border-radius: 0;
}
.datepaginator .pagination > li .dp-off {
  background-color: #fafafa;
}
.datepaginator .pagination > li .dp-no-select {
  color: #ccc;
  background-color: #fafafa;
}
.datepaginator .pagination > li .dp-no-select:hover {
  background-color: #fafafa;
}
.datepaginator .pagination > li #dp-calendar {
  position: absolute;
  right: 6px;
  top: 6px;
}
.datepaginator .pagination > li > .dp-nav {
  height: 72px;
  padding: 29px 0;
  width: 38px;
  line-height: 1;
  border-radius: 3px;
}
.datepaginator .pagination > li > a.dp-nav-sm {
  height: 68px;
  padding: 27px 0;
}
.datepaginator .pagination > li > a.dp-nav-lg {
  height: 76px;
  padding: 31px 0;
}
.datepaginator .pagination > li > a.dp-item {
  height: 72px;
  padding: 15px 0;
  width: 35px;
}
.datepaginator .pagination > li > a.dp-item-sm {
  height: 68px;
  padding: 13px 0;
}
.datepaginator .pagination > li > a.dp-item-lg {
  height: 76px;
  padding: 17px 0;
}
.datepaginator .pagination > li > .dp-today,
.datepaginator .pagination > li > .dp-today:hover,
.datepaginator .pagination > li > .dp-today:focus {
  background-color: #2196F3;
  color: #fff;
}
.datepaginator .pagination > li > .dp-selected,
.datepaginator .pagination > li > .dp-selected:hover,
.datepaginator .pagination > li > .dp-selected:focus {
  background-color: #26A69A;
  color: #fff;
  width: 140px;
}
/* ------------------------------------------------------------------------------
 *
 *  # PNotify notifications
 *
 *  Styles for pnotify.min.js - a flexible JavaScript notification plugin
 *
 *  Version: 1.1
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.ui-pnotify {
  top: 20px;
  right: 20px;
  position: absolute;
  height: auto;
  z-index: 2;
  border-radius: 3px;
}
body > .ui-pnotify {
  position: fixed;
  z-index: 100040;
}
.ui-pnotify.alert-rounded > .ui-pnotify-container {
  border-radius: 100px;
}
.ui-pnotify[class*=bg-] > .ui-pnotify-container {
  background-color: inherit;
  border-color: transparent;
  color: #fff;
}
.ui-pnotify[class*=text-] > .ui-pnotify-container,
.ui-pnotify[class*=alpha-] > .ui-pnotify-container {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.ui-pnotify.stack-top-left,
.ui-pnotify.stack-bottom-left {
  left: 20px;
  right: auto;
}
.ui-pnotify.stack-bottom-right,
.ui-pnotify.stack-bottom-left {
  bottom: 20px;
  top: auto;
}
.ui-pnotify.stack-modal {
  left: 50%;
  right: auto;
  margin-left: -150px;
}
.ui-pnotify.stack-custom-right {
  top: auto;
  left: auto;
  bottom: 200px;
  right: 200px;
}
.ui-pnotify.stack-custom-left {
  top: 200px;
  left: 200px;
  right: auto;
  bottom: auto;
}
.ui-pnotify.stack-custom-top {
  right: 0;
  left: 0;
  top: 0;
}
.ui-pnotify.stack-custom-bottom {
  right: 0;
  left: 0;
  bottom: 0;
  top: auto;
}
.ui-pnotify.ui-pnotify-in {
  display: block!important;
}
.ui-pnotify.ui-pnotify-move {
  -webkit-transition: left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  -o-transition: left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  transition: left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
}
.ui-pnotify.ui-pnotify-fade-slow {
  -webkit-transition: opacity linear 0.6s;
  -o-transition: opacity linear 0.6s;
  transition: opacity linear 0.6s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-pnotify.ui-pnotify-fade-slow.ui-pnotify.ui-pnotify-move {
  -webkit-transition: opacity .6s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  -o-transition: opacity .6s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  transition: opacity .6s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
}
.ui-pnotify.ui-pnotify-fade-normal {
  -webkit-transition: opacity linear 0.4s;
  -o-transition: opacity linear 0.4s;
  transition: opacity linear 0.4s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-pnotify.ui-pnotify-fade-normal.ui-pnotify.ui-pnotify-move {
  -webkit-transition: opacity .4s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  -o-transition: opacity .4s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  transition: opacity .4s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
}
.ui-pnotify.ui-pnotify-fade-fast {
  transition: opacity .2s linear;
  -webkit-transition: opacity linear 0.2s;
  -o-transition: opacity linear 0.2s;
  transition: opacity linear 0.2s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-pnotify.ui-pnotify-fade-fast.ui-pnotify.ui-pnotify-move {
  -webkit-transition: opacity .2s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  -o-transition: opacity .2s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
  transition: opacity .2s linear, left .5s ease, top .5s ease, right .5s ease, bottom .5s ease;
}
.ui-pnotify.ui-pnotify-fade-in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.ui-pnotify-container {
  padding: 15px 20px;
  height: 100%;
  position: relative;
  left: 0;
  margin: 0;
  border-radius: 3px;
}
.ui-pnotify-container:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.ui-pnotify-container.ui-pnotify-sharp {
  border-radius: 0;
}
.ui-pnotify-title {
  display: block;
  margin-top: 0;
  margin-bottom: 7px;
  font-size: 15px;
}
.ui-pnotify-text {
  display: block;
}
.ui-pnotify-icon {
  display: block;
  float: left;
  line-height: 1;
}
.ui-pnotify-icon > [class^=icon-] {
  margin-top: 4px;
  margin-right: 15px;
}
.ui-pnotify-closer,
.ui-pnotify-sticker {
  float: right;
  margin-left: 8px;
  margin-top: 4px;
  line-height: 1;
  outline: 0;
}
.ui-pnotify-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
body > .ui-pnotify-modal-overlay {
  position: fixed;
  z-index: 100039;
}
.brighttheme {
  border: 1px solid;
}
.ui-pnotify[class*=bg-] > .brighttheme {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}
.brighttheme-notice {
  background-color: #FFF3E0;
  border-color: #FF9800;
}
.brighttheme-info {
  background-color: #E3F2FD;
  border-color: #1E88E5;
}
.brighttheme-success {
  background-color: #E8F5E9;
  border-color: #4CAF50;
}
.brighttheme-error {
  background-color: #FBE9E7;
  border-color: #FF5722;
}
.brighttheme-icon-closer,
.brighttheme-icon-sticker {
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;
  width: 10px;
  height: 10px;
  outline: 0;
}
.brighttheme-icon-closer:after,
.brighttheme-icon-sticker:after {
  content: '';
  font-family: 'Icomoon';
  font-size: 10px;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.brighttheme-icon-closer:after {
  content: "\ed6a";
}
.brighttheme-icon-sticker:after {
  content: "\ee70";
}
.brighttheme-icon-sticker.brighttheme-icon-stuck:after {
  content: "\ee75";
}
.ui-pnotify[class*=alert-styled-] {
  border-width: 0;
  padding: 0;
}
.ui-pnotify.alert-styled-left .brighttheme {
  border-left-width: 44px;
}
.ui-pnotify.alert-styled-left:after {
  left: 0;
}
.ui-pnotify.alert-styled-right .brighttheme {
  border-right-width: 44px;
}
.ui-pnotify.alert-styled-right:after {
  right: 0;
}
.brighttheme .ui-pnotify-action-bar {
  padding-top: 15px;
}
.brighttheme .ui-pnotify-action-bar textarea,
.brighttheme .ui-pnotify-action-bar input {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #fff;
  margin-bottom: 15px !important;
  color: #333333;
  padding: 7px 12px;
}
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar textarea,
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar input {
  border-color: #fff;
  color: #fff;
}
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar textarea::-moz-placeholder,
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar textarea:-ms-input-placeholder,
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar input:-ms-input-placeholder {
  color: #fff;
}
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar textarea::-webkit-input-placeholder,
.ui-pnotify[class*=bg-] .brighttheme .ui-pnotify-action-bar input::-webkit-input-placeholder {
  color: #fff;
}
.ui-pnotify[class*=bg-] .form-control {
  border-bottom-color: #fff;
  color: #fff;
}
.ui-pnotify[class*=bg-] .form-control::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.ui-pnotify[class*=bg-] .form-control:-ms-input-placeholder {
  color: #fff;
}
.ui-pnotify[class*=bg-] .form-control::-webkit-input-placeholder {
  color: #fff;
}
.ui-pnotify-history-container {
  position: absolute;
  top: 0;
  right: 20px;
  width: 70px;
  border-top: none;
  padding: 0;
  z-index: 10000;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ui-pnotify-history-container.ui-pnotify-history-fixed {
  position: fixed;
}
.ui-pnotify-history-container .ui-pnotify-history-header {
  padding: 2px;
  text-align: center;
}
.ui-pnotify-history-container button {
  cursor: pointer;
  display: block;
  width: 100%;
}
.ui-pnotify-history-container .ui-pnotify-history-pulldown {
  display: block;
  margin: 0 auto;
}
@media (max-width: 480px) {
  .ui-pnotify-mobile-able.ui-pnotify {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: auto !important;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
  .ui-pnotify-mobile-able.ui-pnotify .ui-pnotify-shadow {
    border-bottom-width: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ui-pnotify-mobile-able.ui-pnotify.stack-top-left,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left {
    left: 0;
    right: 0;
  }
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
  }
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left .ui-pnotify-shadow {
    border-top-width: 5px;
    border-bottom-width: 1px;
  }
  .ui-pnotify-mobile-able.ui-pnotify.ui-pnotify-nonblock-fade {
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
  .ui-pnotify-mobile-able.ui-pnotify.ui-pnotify-nonblock-hide {
    display: none !important;
  }
  .ui-pnotify-mobile-able .ui-pnotify-container {
    border-radius: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # jGrowl notifications
 *
 *  Styles for jgrowl.min.js - an unobtrusive notification system for web applications
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.jGrowl {
  z-index: 2001;
  position: absolute;
}
body > .jGrowl {
  position: fixed;
}
.jGrowl.top-left {
  left: 20px;
  top: 20px;
}
.jGrowl.top-center {
  left: 50%;
  top: 20px;
  margin-left: -150px;
}
.jGrowl.top-right {
  right: 20px;
  top: 20px;
}
.jGrowl.center {
  top: 40%;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  margin-top: -35px;
}
.jGrowl.center .jGrowl-notification,
.jGrowl.center .jGrowl-closer {
  margin-left: auto;
  margin-right: auto;
}
.jGrowl.bottom-left {
  left: 20px;
  bottom: 20px;
}
.jGrowl.bottom-center {
  left: 50%;
  bottom: 20px;
  margin-left: -150px;
}
.jGrowl.bottom-right {
  right: 20px;
  bottom: 20px;
}
@media print {
  .jGrowl {
    display: none;
  }
}
.jGrowl-notification {
  margin-bottom: 10px;
  width: 300px;
  text-align: left;
  display: none;
}
.jGrowl-notification .jGrowl-header {
  font-size: 15px;
  margin-bottom: 5px;
}
.jGrowl-notification .jGrowl-header:empty {
  margin: 0;
}
.jGrowl-notification .jGrowl-close {
  font-weight: 300;
  background: none;
  border: 0;
  font-size: 17px;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  float: right;
  color: inherit;
  outline: 0;
  margin-left: 10px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.jGrowl-notification .jGrowl-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.jGrowl-closer {
  padding: 5px 0;
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
  background-color: #fff;
  width: 300px;
  border-radius: 3px;
  border: 1px solid #ddd;
  color: #999999;
}
.alert-rounded + .jGrowl-closer {
  border-radius: 5px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sweet alerts
 *
 *  Styles for sweet_alert.min.js - A beautiful replacement for JavaScript's "Alert"
 *
 *  Version: 1.1
 *  Latest update: Oct 28, 2015
 *
 * ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
body.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.sweet-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1050;
}
.sweet-alert {
  background-color: #fff;
  width: 470px;
  padding: 20px;
  border-radius: 3px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -235px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 1060;
}
.sweet-alert h2 {
  margin-top: 10px;
  font-size: 19px;
  text-align: center;
  display: block;
  position: relative;
}
.sweet-alert p {
  text-align: center;
  position: relative;
}
.sweet-alert fieldset {
  border: none;
  position: relative;
}
.sweet-alert button {
  background-color: #2196F3;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 7px 15px;
  margin: 10px 5px 0 5px;
  box-shadow: none!important;
}
.sweet-alert button:hover {
  background-color: #1E88E5;
}
.sweet-alert button:focus {
  outline: 0;
}
.sweet-alert button:active {
  background-color: #42A5F5;
}
.sweet-alert button.cancel {
  background-color: transparent;
  color: #333333;
}
.sweet-alert button[disabled] {
  cursor: default;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.sweet-alert button.confirm[disabled] {
  color: transparent;
}
.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 1;
  filter: alpha(opacity=100);
}
.sweet-alert button::-moz-focus-inner {
  border: 0;
}
.sweet-alert[data-has-cancel-button=false] button {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.sweet-alert[data-has-cancel-button=false][data-has-confirm-button=false] {
  padding-bottom: 40px;
}
.sweet-alert .sa-error-container {
  background-color: #f5f5f5;
  overflow: hidden;
  padding: 0 10px;
  max-height: 0;
  border-radius: 3px;
  -webkit-transition: padding 0.15s, max-height 0.15s;
  -o-transition: padding 0.15s, max-height 0.15s;
  transition: padding 0.15s, max-height 0.15s;
}
.sweet-alert .sa-error-container p {
  display: inline-block;
  margin-bottom: 0;
}
.sweet-alert .sa-error-container.show {
  padding: 10px 0;
  max-height: 100px;
  -webkit-transition: padding 0.2s, max-height 0.2s;
  -o-transition: padding 0.2s, max-height 0.2s;
  transition: padding 0.2s, max-height 0.2s;
}
.sweet-alert .sa-error-container .icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  background-color: #FF7043;
  color: white;
  text-align: center;
  margin-right: 7px;
}
.sweet-alert .sa-input-error {
  position: absolute;
  top: 20px;
  right: 12px;
  width: 16px;
  height: 16px;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.sweet-alert .sa-input-error:before,
.sweet-alert .sa-input-error:after {
  content: "";
  width: 16px;
  height: 2px;
  background-color: #EF5350;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -8px;
}
.sweet-alert .sa-input-error:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-input-error:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-input-error.show {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
}
.sweet-alert input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  padding: 7px 12px;
  display: none;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.sweet-alert input::-moz-placeholder {
  color: #bdbdbd;
  opacity: 1;
}
.sweet-alert input:-ms-input-placeholder {
  color: #bdbdbd;
}
.sweet-alert input::-webkit-input-placeholder {
  color: #bdbdbd;
}
.sweet-alert input:focus {
  outline: 0;
}
.sweet-alert input:focus::-moz-placeholder,
.sweet-alert input:focus:-ms-input-placeholder,
.sweet-alert input:focus::-webkit-input-placeholder {
  -webkit-transition: opacity ease 0.3s 0.03s;
  -o-transition: opacity ease 0.3s 0.03s;
  transition: opacity ease 0.3s 0.03s;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.sweet-alert.show-input input {
  display: block;
}
.sweet-alert .sa-confirm-button-container {
  display: inline-block;
  position: relative;
}
.sweet-alert .la-ball-fall {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -27px;
  margin-top: 0;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}
.sweet-alert .sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid #ddd;
  border-radius: 50%;
  margin: 10px auto 20px auto;
  padding: 0;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sweet-alert .sa-icon.sa-error {
  border-color: #F44336;
}
.sweet-alert .sa-icon.sa-error .sa-x-mark {
  position: relative;
  display: block;
}
.sweet-alert .sa-icon.sa-error .sa-line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #F44336;
  display: block;
  top: 37px;
  border-radius: 2px;
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  left: 17px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  right: 16px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-warning {
  border-color: #FF5722;
}
.sweet-alert .sa-icon.sa-warning .sa-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #FF5722;
}
.sweet-alert .sa-icon.sa-warning .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #FF5722;
}
.sweet-alert .sa-icon.sa-info {
  border-color: #2196F3;
}
.sweet-alert .sa-icon.sa-info:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #2196F3;
}
.sweet-alert .sa-icon.sa-info:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #2196F3;
}
.sweet-alert .sa-icon.sa-success {
  border-color: #4CAF50;
}
.sweet-alert .sa-icon.sa-success:before,
.sweet-alert .sa-icon.sa-success:after {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success:before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  -moz-transform-origin: 60px 60px;
  -ms-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.sweet-alert .sa-icon.sa-success:after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  -moz-transform-origin: 0 60px;
  -ms-transform-origin: 0 60px;
  transform-origin: 0 60px;
}
.sweet-alert .sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(76, 175, 80, 0.2);
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sweet-alert .sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-custom {
  background-size: contain;
  border-radius: 0;
  border: 0;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 20px;
    right: 20px;
  }
}
/* # Animations
-------------------------------------------------- */
@-webkit-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.showSweetAlert[data-animation=pop] {
  -webkit-animation: showSweetAlert 0.3s;
  -o-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;
}
.showSweetAlert[data-animation=none] {
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}
@-webkit-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.hideSweetAlert[data-animation=pop] {
  -webkit-animation: hideSweetAlert 0.2s;
  -o-animation: hideSweetAlert 0.2s;
  animation: hideSweetAlert 0.2s;
}
.hideSweetAlert[data-animation=none] {
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}
@-webkit-keyframes slideFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
.showSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideFromTop 0.3s;
  -o-animation: slideFromTop 0.3s;
  animation: slideFromTop 0.3s;
}
@-webkit-keyframes slideToTop {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}
@keyframes slideToTop {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}
.hideSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideToTop 0.4s;
  -o-animation: slideToTop 0.4s;
  animation: slideToTop 0.4s;
}
@-webkit-keyframes slideFromBottom {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromBottom {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}
.showSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideFromBottom 0.3s;
  -o-animation: slideFromBottom 0.3s;
  animation: slideFromBottom 0.3s;
}
@-webkit-keyframes slideToBottom {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}
@keyframes slideToBottom {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}
.hideSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideToBottom 0.3s;
  -o-animation: slideToBottom 0.3s;
  animation: slideToBottom 0.3s;
}
@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  -o-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}
@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  -o-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}
@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  -o-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}
@-webkit-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  -o-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s;
}
@-webkit-keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  -o-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s;
}
@-webkit-keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }
  100% {
    border-color: #F8BB86;
  }
}
@keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }
  100% {
    border-color: #F8BB86;
  }
}
.pulseWarning {
  -webkit-animation: pulseWarning 0.75s infinite alternate;
  -o-animation: pulseWarning 0.75s infinite alternate;
  animation: pulseWarning 0.75s infinite alternate;
}
@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  -o-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate;
}
@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-success {
  border-color: transparent;
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg);
}
.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff;
  width: 54px;
  height: 12px;
}
.la-ball-fall.la-dark {
  color: #333333;
}
.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: #fff;
  border: 0 solid #fff;
  width: 6px;
  height: 6px;
  margin: 2px;
  border-radius: 100%;
  -webkit-animation: ball-fall 1s ease-in-out infinite;
  -o-animation: ball-fall 1s ease-in-out infinite;
  animation: ball-fall 1s ease-in-out infinite;
  opacity: 0;
  filter: alpha(opacity=0);
}
.la-ball-fall > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  animation-delay: -200ms;
}
.la-ball-fall > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  animation-delay: -100ms;
}
.la-ball-fall > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
.la-ball-fall.la-sm {
  width: 26px;
  height: 8px;
}
.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}
.la-ball-fall.la-2x {
  width: 108px;
  height: 36px;
}
.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}
.la-ball-fall.la-3x {
  width: 162px;
  height: 54px;
}
.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}
@-webkit-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@-o-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Bootbox modal extension
 *
 *  Styles for bootbox.min.js - advanced Bootstrap modals
 *
 *  Version: 1.0
 *  Latest update: Oct 9, 2015
 *
 * ---------------------------------------------------------------------------- */
.bootbox .modal-title {
  font-size: 15px;
}
/* ------------------------------------------------------------------------------
*
*  # jQuery UI slider pips
*
*  Styles for jQuery UI slider pips extension
*
*  Version: 1.1
*  Latest update: Nov 15, 2015
*
* ---------------------------------------------------------------------------- */
/* # Pips
-------------------------------------------------- */
.ui-slider-pips.ui-slider-horizontal {
  margin-bottom: 32px;
  margin-left: 10px;
  margin-right: 10px;
}
.ui-slider-pips .ui-slider-label,
.ui-slider-pips .ui-slider-pip-hide {
  display: none;
}
.ui-slider-pips .ui-slider-pip-label .ui-slider-label {
  display: block;
}
.ui-slider-pips .ui-slider-pip {
  height: 10px;
  line-height: 10px;
  font-size: 85%;
  width: 20px;
  margin-left: -11px;
  position: absolute;
  overflow: visible;
  text-align: center;
  top: 20px;
  left: 20px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ui-slider-pips .ui-slider-pip:hover .ui-slider-label {
  font-weight: 500;
}
.ui-slider-pips .ui-slider-line {
  background-color: #999;
  width: 1px;
  height: 3px;
  position: absolute;
  left: 50%;
}
.ui-slider-pips .ui-slider-label {
  position: absolute;
  top: 8px;
  left: 50%;
  width: 20px;
  margin-left: -10px;
}
.ui-slider-pips.ui-slider-vertical {
  margin: 10px 40px 10px 10px;
}
.ui-slider-pips.ui-slider-vertical .ui-slider-pip {
  text-align: left;
  top: auto;
  left: 18px;
  margin-left: 0;
  margin-bottom: -4px;
}
.ui-slider-pips.ui-slider-vertical .ui-slider-line {
  width: 3px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
}
.ui-slider-pips.ui-slider-vertical .ui-slider-label {
  top: 50%;
  margin-left: 0;
  margin-top: -5px;
  width: 20px;
  left: 5px;
}
/* # Tooltips
-------------------------------------------------- */
.ui-slider-float .ui-slider-handle:focus,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip-label {
  outline: 0;
}
.ui-slider-float .ui-slider-tip,
.ui-slider-float .ui-slider-tip-label {
  position: absolute;
  visibility: hidden;
  top: -45px;
  display: block;
  width: 34px;
  margin-left: -16px;
  left: 50%;
  height: 30px;
  line-height: 32px;
  background: #333333;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all ease-in-out 0.2s 0.2s;
  -o-transition: all ease-in-out 0.2s 0.2s;
  transition: all ease-in-out 0.2s 0.2s;
}
.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip {
  top: -40px;
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.ui-slider-float .ui-slider-pip .ui-slider-tip-label {
  top: 42px;
}
.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
  top: 32px;
  font-weight: normal;
}
.ui-slider-float .ui-slider-tip:after,
.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #333333;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
}
.ui-slider-float.ui-slider-vertical .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-tip-label {
  top: 50%;
  margin-top: -16px;
  width: 34px;
  margin-left: 0px;
  left: -50px;
  -webkit-transition: all ease-in-out 0.2s 0.2s;
  -o-transition: all ease-in-out 0.2s 0.2s;
  transition: all ease-in-out 0.2s 0.2s;
}
.ui-slider-float.ui-slider-vertical .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-pip:hover .ui-slider-tip-label,
.ui-slider-float.ui-slider-vertical .ui-slider-pip:focus .ui-slider-tip-label {
  top: 50%;
  margin-top: -16px;
  left: -45px;
}
.ui-slider-float.ui-slider-vertical .ui-slider-pip .ui-slider-tip-label {
  left: 47px;
}
.ui-slider-float.ui-slider-vertical .ui-slider-pip:hover .ui-slider-tip-label {
  left: 37px;
}
.ui-slider-float.ui-slider-vertical .ui-slider-tip:after,
.ui-slider-float.ui-slider-vertical .ui-slider-pip .ui-slider-tip-label:after {
  border-left-color: #333333;
  bottom: 9px;
  left: auto;
  margin-right: -9px;
  border-top-color: transparent;
  right: 0;
  margin-left: 0;
}
/* ------------------------------------------------------------------------------
*
*  # NoUI slider
*
*  Styles for NoUI range slider plugin
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.noUi-target {
  border-radius: 100px;
  position: relative;
  direction: ltr;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.noUi-target,
.noUi-target * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 100px;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-background {
  background-color: #eeeeee;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.noUi-connect {
  background-color: #607D8B;
}
[disabled] .noUI-background {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.noUi-horizontal {
  height: 6px;
}
.noUi-horizontal .noUi-handle {
  top: -6px;
  left: -9px;
}
.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}
.noUi-horizontal.has-pips {
  margin-bottom: 35px;
}
.noUi-vertical {
  display: inline-block;
  width: 6px;
  height: 150px;
}
.noUi-vertical + .noUi-vertical {
  margin-left: 20px;
}
.noUi-vertical .noUi-handle {
  top: -9px;
  left: -6px;
}
.noUi-vertical.noUi-extended {
  padding: 15px 0;
}
.noUi-vertical.noUi-extended .noUi-origin {
  bottom: -15px;
}
.noUi-vertical.has-pips {
  margin-right: 35px;
}
.noUi-dragable {
  cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}
.noUi-handle {
  width: 18px;
  height: 18px;
  background-color: #fcfcfc;
  cursor: pointer;
  top: -6px;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  border: 1px solid #bbb;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-handle:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #546E7A;
  border-radius: 50%;
}
.noUi-handle:hover,
.noUi-handle:focus {
  background-color: #f8f8f8;
}
.noUi-handle:hover:after,
.noUi-handle:focus:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.noUi-handle:active {
  background-color: #fefefe;
}
.noUi-handle:active:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.noui-slider-white .noUi-handle:after {
  content: none;
}
.noui-slider-lg .noUi-handle {
  width: 24px;
  height: 24px;
  top: -8px;
}
.noui-slider-lg .noUi-handle:after {
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
}
.noui-slider-lg.noUi-horizontal {
  height: 8px;
}
.noui-slider-lg.noUi-horizontal .noUi-handle {
  left: -12px;
}
.noui-slider-lg.noUi-vertical {
  width: 8px;
}
.noui-slider-lg.noUi-vertical .noUi-handle {
  top: -12px;
  left: -8px;
}
.noui-slider-sm .noUi-handle,
.noui-slider-xs .noUi-handle {
  width: 12px;
  height: 12px;
  top: -4px;
}
.noui-slider-sm .noUi-handle:after,
.noui-slider-xs .noUi-handle:after {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}
.noui-slider-sm.noUi-horizontal {
  height: 4px;
}
.noui-slider-sm.noUi-horizontal .noUi-handle {
  top: -4px;
  left: -6px;
}
.noui-slider-sm.noUi-vertical {
  width: 4px;
}
.noui-slider-sm.noUi-vertical .noUi-handle {
  top: -6px;
  left: -4px;
}
.noui-slider-xs.noUi-horizontal {
  height: 2px;
}
.noui-slider-xs.noUi-horizontal .noUi-handle {
  top: -5px;
  left: -6px;
}
.noui-slider-xs.noUi-vertical {
  width: 2px;
}
.noui-slider-xs.noUi-vertical .noUi-handle {
  top: -6px;
  left: -5px;
}
.noui-slider-solid .noUi-handle {
  background-color: #999999;
  border-color: #999999;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.noui-slider-solid .noUi-handle:after {
  background-color: #fff;
  -webkit-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s;
}
.noui-slider-solid .noUi-handle:hover:after,
.noui-slider-solid .noUi-handle:focus:after {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.noui-slider-solid.noui-slider-primary .noUi-handle {
  background-color: #2196F3;
  border-color: #2196F3;
}
.noui-slider-solid.noui-slider-danger .noUi-handle {
  background-color: #F44336;
  border-color: #F44336;
}
.noui-slider-solid.noui-slider-success .noUi-handle {
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.noui-slider-solid.noui-slider-warning .noUi-handle {
  background-color: #FF5722;
  border-color: #FF5722;
}
.noui-slider-solid.noui-slider-info .noUi-handle {
  background-color: #00BCD4;
  border-color: #00BCD4;
}
.noui-slider-primary .noUi-connect,
.noui-slider-primary.noUi-connect {
  background-color: #2196F3;
}
.noui-slider-danger .noUi-connect,
.noui-slider-danger.noUi-connect {
  background-color: #F44336;
}
.noui-slider-success .noUi-connect,
.noui-slider-success.noUi-connect {
  background-color: #4CAF50;
}
.noui-slider-warning .noUi-connect,
.noui-slider-warning.noUi-connect {
  background-color: #FF5722;
}
.noui-slider-info .noUi-connect,
.noui-slider-info.noUi-connect {
  background-color: #00BCD4;
}
.noUi-tooltip {
  display: none;
  position: absolute;
  border-radius: 3px;
  background: #333333;
  color: #fff;
  top: -43px;
  padding: 7px 12px;
  left: 50%;
  margin-left: -27px;
  text-align: center;
  font-size: 12px;
  width: 54px;
}
.noUi-tooltip:after {
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #333333;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
}
.noUi-handle:hover .noUi-tooltip {
  display: block;
}
.noUi-pips {
  position: absolute;
  color: #999999;
}
.noUi-value {
  width: 40px;
  position: absolute;
  text-align: center;
  font-size: 11px;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 11px;
}
.noUi-marker {
  position: absolute;
  background-color: #ccc;
}
.noUi-pips-horizontal {
  padding-top: 10px;
  height: 35px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  margin-left: -20px;
  padding-top: 15px;
}
.noUi-value-horizontal.noUi-value-sub {
  padding-top: 10px;
}
.noUi-marker-horizontal.noUi-marker {
  width: 1px;
  height: 3px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 6px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 10px;
}
.noUi-pips-vertical {
  padding-left: 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  width: 15px;
  margin-left: 15px;
  margin-top: -6px;
}
.noUi-marker-vertical.noUi-marker {
  width: 3px;
  height: 1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 6px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 10px;
}
/* ------------------------------------------------------------------------------
*
*  # ION Range Slider
*
*  Styles for ION range slider plugin
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.irs {
  position: relative;
  display: block;
  height: 49px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}
.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none;
  height: 6px;
  top: 36px;
  background-color: #eeeeee;
  border-radius: 100px;
}
.irs-line-left,
.irs-line-mid,
.irs-line-right {
  position: absolute;
  display: block;
  top: 0;
}
.irs-line-left {
  left: 0;
  width: 11%;
}
.irs-line-mid {
  left: 9%;
  width: 82%;
}
.irs-line-right {
  right: 0;
  width: 11%;
}
.irs-bar {
  position: absolute;
  display: block;
  top: 36px;
  left: 0;
  width: 0;
  height: 6px;
  background-color: #03A9F4;
  border-radius: 100px;
}
.irs-bar-edge {
  position: absolute;
  display: block;
  top: 25px;
  left: 0;
  height: 12px;
  width: 9px;
}
.irs-shadow {
  position: absolute;
  display: none;
  top: 36px;
  left: 0;
  width: 0;
  height: 6px;
  background-color: #333333;
  border-radius: 100px;
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.irs-slider {
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  background-color: #fcfcfc;
  border: 1px solid #bbb;
  top: 30px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 1;
}
.irs-slider:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #666;
  border-radius: 100px;
}
.irs-slider:hover,
.irs-slider:focus {
  background-color: #f7f7f7;
}
.irs-slider:active {
  background-color: #fafafa;
}
.irs-slider.type_last {
  z-index: 2;
}
.irs-min,
.irs-max,
.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  cursor: default;
}
.irs-min,
.irs-max {
  color: #999999;
  font-size: 11px;
  line-height: 1;
  top: 0;
  padding: 5px;
  background-color: #eeeeee;
  border-radius: 2px;
}
.irs-min {
  left: 0;
}
.irs-max {
  right: 0;
}
.irs-from,
.irs-to,
.irs-single {
  left: 0;
  white-space: nowrap;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  padding: 5px;
  top: 0;
  background-color: #333;
  border-radius: 2px;
}
.irs-from:after,
.irs-to:after,
.irs-single:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -8px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -4px;
  overflow: hidden;
  border: 4px solid transparent;
  border-top-color: #333;
}
.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 24px;
}
.irs-with-grid {
  height: 75px;
}
.irs-with-grid .irs-grid {
  display: block;
}
.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background-color: #999999;
}
.irs-grid-pol.small {
  height: 4px;
}
.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 11px;
  color: #999999;
  line-height: 1;
  padding: 0 3px;
}
.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: transparent;
  z-index: 2;
}
.irs-disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Interactions
 *
 *  Separate styles for jQuery UI library. Component's interactions
 *
 *  Version: 1.1
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.ui-draggable-handle,
.ui-sortable-handle {
  -ms-touch-action: none;
  touch-action: none;
}
.ui-sortable .ui-state-disabled {
  color: #999999;
  cursor: not-allowed;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.sortable-placeholder {
  position: relative;
}
.sortable-placeholder:before {
  content: '';
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.sidebar .sortable-placeholder:before {
  border-left: 0;
  border-right: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.sidebar-category.ui-sortable-helper {
  background-color: rgba(0, 0, 0, 0.4);
}
.sidebar-default .sidebar-category.ui-sortable-helper {
  background-color: #fcfcfc;
}
.panel + .sortable-placeholder {
  margin-bottom: 20px;
}
.sortable-placeholder + .panel {
  margin-top: 20px;
}
.panel-group > .sortable-placeholder:before {
  border-radius: 3px;
}
.panel-group .panel + .sortable-placeholder {
  margin-top: 5px;
  margin-bottom: 5px;
}
.panel-group .sortable-placeholder + .panel {
  margin-top: 5px;
}
.table .ui-sortable-helper {
  width: 100%;
  background-color: #fff;
  display: table;
}
.table .sortable-placeholder {
  margin: 0;
}
.table .sortable-placeholder:before {
  content: none;
}
.table.ui-sortable {
  position: relative;
}
.ui-resizable {
  position: relative;
}
.ui-resizable,
.ui-resizable .ui-dialog-content {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}
.ui-resizable-handle.ui-icon {
  display: inline-block;
  border-style: solid;
  border-width: 0 0 6px 6px;
  border-color: transparent transparent #333333 transparent;
}
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}
.ui-resizable-helper {
  border: 1px dashed #ccc;
}
.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}
.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-se {
  cursor: se-resize;
  right: 1px;
  bottom: 1px;
}
.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}
.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}
.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}
.ui-selectable {
  -ms-touch-action: none;
  touch-action: none;
}
.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dashed #333333;
}
/* ------------------------------------------------------------------------------
*
*  # jQuery UI Widgets
*
*  Styles for jQuery UI widgets
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.ui-accordion .ui-accordion-header {
  display: block;
  cursor: pointer;
  margin: 0;
  outline: 0;
  position: relative;
  background-color: #fff;
  padding: 15px 20px;
  border: 1px solid #ddd;
  margin-top: 5px;
  border-radius: 3px;
}
.ui-accordion .ui-accordion-header:first-child {
  margin-top: 0;
}
.ui-accordion .ui-accordion-header.ui-accordion-header-active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon:before {
  content: '\e9b8';
  font-family: 'Icomoon';
  display: block;
  width: 16px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-accordion .ui-accordion-header-active .ui-accordion-header-icon:before {
  content: '\e9b7';
}
.ui-accordion .ui-accordion-icons {
  padding-right: 46px;
}
.ui-accordion .ui-accordion-content {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  overflow: auto;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.accordion-sortable-group + .accordion-sortable-group {
  margin-top: 5px;
}
.ui-autocomplete {
  position: absolute;
  display: none;
  padding: 7px 0;
  z-index: 1000;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ui-autocomplete .ui-autocomplete-category {
  padding: 8px 12px;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 2px;
}
.ui-autocomplete .ui-autocomplete-category ~ .ui-menu-item {
  padding-left: 24px;
}
.ui-autocomplete-processing:after {
  content: '\eb51';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  width: 16px;
  text-align: right;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.input-group .ui-autocomplete-input + .input-group-btn > .btn,
.input-group .ui-autocomplete-input + .input-addon > .btn {
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ui-button {
  display: inline-block;
  position: relative;
  touch-action: manipulation;
  margin-bottom: 0;
  padding: 7px 12px;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  color: #333;
}
.ui-button.ui-state-hover,
.ui-button.ui-state-focus {
  color: #333;
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.ui-button.ui-state-active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.ui-button.ui-state-disabled {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-button[class*=bg-].ui-state-hover,
.ui-button[class*=bg-].ui-state-focus {
  color: #fff;
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.ui-button[class*=bg-].ui-state-active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.ui-button.ui-button-link {
  background-color: transparent;
  border-color: transparent;
}
.ui-button.ui-button-link.ui-state-hover,
.ui-button.ui-button-link.ui-state-focus {
  color: #166dba;
}
.ui-button.ui-button-link.ui-state-hover,
.ui-button.ui-button-link.ui-state-focus,
.ui-button.ui-button-link.ui-state-active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.ui-button-text-icon-primary .ui-button-icon-primary,
.ui-button-text-icons .ui-button-icon-primary,
.ui-button-icon-primary .ui-button-icons-only {
  margin-right: 7px;
}
.ui-button-text-icon-secondary .ui-button-icon-secondary,
.ui-button-text-icons .ui-button-icon-secondary,
.ui-button-icons-only .ui-button-icon-secondary {
  margin-left: 7px;
}
.ui-button-icon-only {
  padding-left: 9px;
  padding-right: 9px;
}
.ui-button-icon-only .ui-button-text,
.ui-button-icons-only .ui-button-text {
  display: none;
}
.ui-buttonset {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.ui-buttonset .ui-button {
  border-radius: 0;
  margin-left: -1px;
  float: left;
}
.ui-buttonset .ui-button:first-child,
.ui-buttonset .ui-helper-hidden-accessible:first-child + .ui-button {
  margin-left: 0;
}
.ui-buttonset .ui-button:first-child,
.ui-buttonset .ui-helper-hidden-accessible:first-child + .ui-button:not(:last-child) {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.ui-buttonset .ui-button:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.ui-buttonset-split {
  position: relative;
  display: inline-block;
}
.ui-buttonset-split > ul {
  position: absolute;
  text-align: left;
  min-width: 180px;
}
.ui-datepicker {
  min-width: 270px;
  background-color: #fff;
  padding: 14px;
  display: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  z-index: 190;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.sidebar .ui-datepicker {
  min-width: 0;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  text-align: center;
  line-height: 1;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
  font-weight: 500;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
  font-size: 12px;
  color: #999999;
  margin-left: 5px;
}
.ui-datepicker .ui-datepicker-title select {
  outline: 0;
  height: 30px;
  border-color: #ddd;
}
.ui-datepicker .ui-datepicker-title select.ui-datepicker-month,
.ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
  width: 48%;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  line-height: 1;
  color: #333333;
  padding: 7px;
  border-radius: 2px;
}
.ui-datepicker .ui-datepicker-prev:after,
.ui-datepicker .ui-datepicker-next:after {
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  width: 16px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: none;
}
.ui-datepicker .ui-datepicker-prev {
  left: 0;
}
.ui-datepicker .ui-datepicker-prev:after {
  content: '\e9c8';
}
.ui-datepicker .ui-datepicker-next {
  right: 0;
}
.ui-datepicker .ui-datepicker-next:after {
  content: '\e9cb';
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  background-color: #f5f5f5;
}
.ui-datepicker table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}
.ui-datepicker table th {
  text-align: center;
  font-weight: 400;
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  color: #999999;
}
.ui-datepicker table td {
  border: 0;
  padding: 1px;
  position: relative;
}
.ui-datepicker table td span,
.ui-datepicker table td a {
  display: block;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  color: #333333;
  min-width: 34px;
}
.ui-datepicker table td .ui-state-hover {
  background-color: #f5f5f5;
}
.ui-datepicker table td.ui-state-disabled span {
  color: #ccc;
}
.ui-datepicker table td.ui-datepicker-week-col {
  padding: 6px;
  color: #999999;
}
.ui-datepicker table td.ui-datepicker-current-day .ui-state-active {
  background-color: #26A69A;
  color: #fff;
}
.ui-datepicker table td.ui-datepicker-today .ui-state-highlight {
  background-color: #2196F3;
  color: #fff;
}
.ui-datepicker table td.ui-datepicker-today .ui-state-highlight:after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  width: 0;
  height: 0;
  border-top: 6px solid #fff;
  border-left: 6px solid transparent;
}
.ui-datepicker table td a.ui-priority-secondary {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  border: 1px solid #ddd;
  background-color: #fcfcfc;
  font-weight: 400;
  margin-top: 14px;
  cursor: pointer;
  padding: 7px 12px;
  width: auto;
  overflow: visible;
  outline: 0;
  border-radius: 3px;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-state-hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}
.ui-datepicker .ui-datepicker-buttonpane button:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.03) inset;
}
.ui-datepicker.ui-datepicker-multi {
  width: auto!important;
}
.datepicker-inline {
  width: 264px;
  max-width: 100%;
  overflow-x: auto;
}
.full-width.datepicker-inline,
.sidebar .datepicker-inline,
.popover .datepicker-inline {
  width: 100%;
}
.ui-datepicker-trigger {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
  padding: 6px;
  cursor: pointer;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group + .ui-datepicker-group {
  padding-left: 15px;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 46%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-dialog {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid transparent;
  outline: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .ui-dialog {
    width: 90%!important;
  }
}
.ui-dialog-titlebar {
  position: relative;
  padding: 20px;
  padding-bottom: 0;
}
.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}
.ui-dialog-title {
  float: left;
  font-size: 15px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-dialog-titlebar-close {
  position: absolute;
  background-color: transparent;
  border: 0;
  right: 20px;
  top: 50%;
  padding: 0;
  margin-top: 2px;
}
.ui-dialog-titlebar-close:after {
  content: '\ed6b';
  font-family: 'Icomoon';
  display: block;
  width: 16px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-dialog-titlebar-close.ui-state-hover,
.ui-dialog-titlebar-close.ui-state-focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-dialog-content {
  position: relative;
  border: 0;
  padding: 20px;
  background: none;
  overflow: auto;
}
.ui-dialog-content .form-group:last-child,
.ui-dialog-content p:last-child {
  margin-bottom: 0;
}
.ui-dialog-buttonpane {
  padding: 20px;
  padding-top: 0;
}
.ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right;
}
.ui-dialog-buttonpane button + button {
  margin-left: 5px;
  cursor: pointer;
}
.ui-menu {
  list-style: none;
  padding: 7px 0;
  margin: 0;
  display: block;
  outline: none;
  min-width: 180px;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  z-index: 1000;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.ui-menu .ui-menu {
  position: absolute;
  top: -8px !important;
}
.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  cursor: pointer;
  padding: 8px 15px;
  min-height: 0;
  outline: 0;
}
.ui-menu .ui-menu-item > a {
  display: block;
  color: #333333;
}
.ui-menu .ui-menu-item > i {
  margin-right: 7px;
}
.ui-menu .ui-menu-item > .ui-menu-icon {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 15px;
}
.ui-menu .ui-menu-item > .ui-menu-icon:after {
  content: '\e9c7';
  font-family: 'icomoon';
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.ui-menu .ui-menu-item.ui-state-disabled,
.ui-menu .ui-menu-item.ui-state-disabled a {
  color: #999999;
  cursor: not-allowed;
}
.ui-menu .ui-menu-header {
  padding: 8px 15px;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  color: #999999;
  margin-top: 7px;
}
.ui-menu .ui-menu-header.highlight {
  margin-top: 0;
  background-color: #f8f8f8;
  color: #999999;
}
.ui-menu .ui-menu-header.highlight:first-child {
  margin-top: 0;
}
.ui-menu .ui-menu-item + .highlight,
.ui-menu .highlight + .ui-menu-item {
  margin-top: 7px;
}
.ui-menu .ui-menu-item > a > i[class*=icon-],
.ui-menu .ui-menu-header > i[class*=icon-] {
  margin-top: 2px;
  float: left;
  margin-right: 10px;
  top: 0;
}
.ui-menu .ui-menu-item > a > i[class*=icon-].pull-right,
.ui-menu .ui-menu-header > i[class*=icon-].pull-right {
  margin-right: 0;
  margin-left: 10px;
}
.ui-menu .ui-menu-divider {
  margin: 7px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 1px solid #e5e5e5;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  color: #333333;
  background-color: #f5f5f5;
}
.ui-menu.ui-state-disabled,
.ui-menu.ui-state-disabled .ui-menu-item,
.ui-menu.ui-state-disabled a {
  color: #999999;
  cursor: not-allowed;
}
.ui-progressbar {
  height: 18px;
  overflow: hidden;
  position: relative;
  background-color: #eeeeee;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ui-progressbar-value {
  float: left;
  width: 0%;
  height: 100%;
  background-color: #2196F3;
  color: #fff;
  overflow: hidden;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.ui-progressbar-value,
.ui-progressbar-overlay {
  height: 100%;
  background-size: 40px 40px;
}
.ui-progressbar-striped .ui-progressbar-value,
.ui-progressbar-striped .ui-progressbar-overlay {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.ui-progressbar-overlay {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.ui-progressbar-active .ui-progressbar-value,
.ui-progressbar-overlay {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none;
}
.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.ui-selectmenu-menu .ui-menu {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.82;
  padding: 7px 12px;
  margin: 2px 0;
  text-transform: uppercase;
  height: auto;
  border: 0;
}
.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup ~ .ui-menu-item {
  padding-left: 24px;
}
.ui-selectmenu-open {
  display: block;
}
.ui-selectmenu-button {
  display: inline-block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  height: 36px;
  padding: 7px 12px;
  padding-right: 34px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: 0;
}
.ui-selectmenu-button.ui-state-hover,
.ui-selectmenu-button.ui-state-focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.01) inset;
}
.ui-selectmenu-button:after {
  content: '\e9c5';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  text-align: right;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-selectmenu-button .ui-selectmenu-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-selectmenu-disabled {
  cursor: not-allowed;
  background-color: #fafafa;
  color: #999999;
}
.ui-selectmenu-disabled.ui-state-hover,
.ui-selectmenu-disabled.ui-state-focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-selectmenu-lg .ui-selectmenu-button {
  height: 40px;
}
.ui-selectmenu-lg .ui-selectmenu-button .ui-selectmenu-text {
  padding: 9px 15px;
  padding-right: 40px;
}
.ui-selectmenu-sm .ui-selectmenu-button {
  height: 34px;
}
.ui-selectmenu-sm .ui-selectmenu-button .ui-selectmenu-text {
  padding: 6px 12px;
  padding-right: 32px;
  font-size: 12px;
  line-height: 1.6666667;
}
.ui-selectmenu-xs .ui-selectmenu-button {
  height: 32px;
}
.ui-selectmenu-xs .ui-selectmenu-button .ui-selectmenu-text {
  padding: 5px 10px;
  padding-right: 30px;
  font-size: 12px;
  line-height: 1.6666667;
}
.ui-selectmenu-images .ui-menu-item > span {
  display: inline-block;
  margin-right: 10px;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  float: left;
}
.ui-selectmenu-images .demo-img-amazon {
  background: url('../images/brands/amazon.png') no-repeat;
}
.ui-selectmenu-images .demo-img-youtube {
  background: url('../images/brands/youtube.png') no-repeat;
}
.ui-selectmenu-images .demo-img-twitter {
  background: url('../images/brands/twitter.png') no-repeat;
}
.ui-selectmenu-images .demo-img-bing {
  background: url('../images/brands/bing.png') no-repeat;
}
.ui-selectmenu-images .demo-img-spotify {
  background: url('../images/brands/spotify.png') no-repeat;
}
.ui-slider {
  position: relative;
  text-align: left;
  background-color: #eeeeee;
  border-radius: 100px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fcfcfc;
  border: 1px solid #bbb;
  outline: 0;
}
.ui-slider .ui-slider-handle.ui-state-hover,
.ui-slider .ui-slider-handle.ui-state-focus {
  background-color: #f8f8f8;
}
.ui-slider .ui-slider-handle.ui-state-hover:after,
.ui-slider .ui-slider-handle.ui-state-focus:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.ui-slider .ui-slider-handle.ui-state-active {
  background-color: #fefefe;
}
.ui-slider .ui-slider-handle.ui-state-active:after {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.ui-slider .ui-slider-handle:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #546E7A;
  border-radius: 50%;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  background-color: #607D8B;
  border-radius: 100px;
}
.ui-slider.ui-slider-disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.ui-slider-horizontal {
  height: 6px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -6px;
  margin-left: -9px;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}
.ui-slider-vertical {
  width: 6px;
  height: 150px;
  display: inline-block;
  margin: 0 10px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -6px;
  margin-bottom: -9px;
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}
.ui-slider-solid .ui-slider-handle {
  background-color: #999999;
  border-color: #999999;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-slider-solid .ui-slider-handle:after {
  background-color: #fff;
  -webkit-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s;
}
.ui-slider-solid .ui-slider-handle.ui-state-hover:after,
.ui-slider-solid .ui-slider-handle.ui-state-active:after {
  background-color: #fff;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.ui-handle-white .ui-slider-handle:after {
  content: none;
}
.ui-slider-lg .ui-slider-handle {
  width: 24px;
  height: 24px;
}
.ui-slider-lg .ui-slider-handle:after {
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
}
.ui-slider-lg.ui-slider-horizontal {
  height: 8px;
}
.ui-slider-lg.ui-slider-horizontal .ui-slider-handle {
  top: -8px;
  margin-left: -12px;
}
.ui-slider-lg.ui-slider-vertical {
  width: 8px;
}
.ui-slider-lg.ui-slider-vertical .ui-slider-handle {
  left: -8px;
  margin-bottom: -12px;
}
.ui-slider-sm .ui-slider-handle,
.ui-slider-xs .ui-slider-handle {
  width: 12px;
  height: 12px;
}
.ui-slider-sm .ui-slider-handle:after,
.ui-slider-xs .ui-slider-handle:after {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}
.ui-slider-sm.ui-slider-horizontal {
  height: 4px;
}
.ui-slider-sm.ui-slider-horizontal .ui-slider-handle {
  top: -4px;
  margin-left: -6px;
}
.ui-slider-sm.ui-slider-vertical {
  width: 4px;
}
.ui-slider-sm.ui-slider-vertical .ui-slider-handle {
  left: -4px;
  margin-bottom: -6px;
}
.ui-slider-xs.ui-slider-horizontal {
  height: 2px;
}
.ui-slider-xs.ui-slider-horizontal .ui-slider-handle {
  top: -5px;
  margin-left: -6px;
}
.ui-slider-xs.ui-slider-vertical {
  width: 2px;
}
.ui-slider-xs.ui-slider-vertical .ui-slider-handle {
  left: -5px;
  margin-bottom: -6px;
}
.ui-slider-primary .ui-slider-range,
.ui-slider-solid.ui-slider-primary .ui-slider-handle {
  background-color: #2196F3;
  border-color: #2196F3;
}
.ui-slider-danger .ui-slider-range,
.ui-slider-solid.ui-slider-danger .ui-slider-handle {
  background-color: #F44336;
  border-color: #F44336;
}
.ui-slider-success .ui-slider-range,
.ui-slider-solid.ui-slider-success .ui-slider-handle {
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.ui-slider-warning .ui-slider-range,
.ui-slider-solid.ui-slider-warning .ui-slider-handle {
  background-color: #FF5722;
  border-color: #FF5722;
}
.ui-slider-info .ui-slider-range,
.ui-slider-solid.ui-slider-info .ui-slider-handle {
  background-color: #00BCD4;
  border-color: #00BCD4;
}
.ui-spinner {
  position: relative;
  display: table;
}
.ui-spinner-input {
  padding-right: 30px;
  display: table-cell;
  width: 100%;
  border-radius: 3px 0 0 3px;
  border-right: 0;
}
.ui-spinner-button {
  font-size: 0;
  color: #333333;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  display: table-cell;
  width: 1%;
  padding: 0 7px;
}
.ui-spinner-button:after {
  font-family: 'Icomoon';
  display: inline-block;
  width: 16px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-spinner-button + .ui-spinner-button {
  border-left: 0;
}
.ui-spinner-button .ui-button-text {
  display: none;
}
.ui-spinner-button.ui-state-hover,
.ui-spinner-button.ui-state-focus {
  color: #333333;
}
.ui-spinner-button.ui-state-disabled {
  background-color: #fafafa;
  color: #999999;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui-spinner-up:after {
  content: '\e9f7';
}
.ui-spinner-down {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.ui-spinner-down:after {
  content: '\e9e2';
}
.ui-tabs {
  position: relative;
}
.ui-tabs .ui-tabs-nav {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.ui-tabs .ui-tabs-nav li {
  list-style: none;
  position: relative;
  padding: 0;
  white-space: nowrap;
  margin-bottom: -1px;
}
@media (min-width: 769px) {
  .ui-tabs .ui-tabs-nav li {
    float: left;
  }
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  display: block;
  color: #888;
  padding: 9px 15px;
  border: 1px solid transparent;
  border-top-width: 2px;
}
.ui-tabs .ui-tabs-nav .ui-state-hover .ui-tabs-anchor:not(.ui-state-disabled),
.ui-tabs .ui-tabs-nav .ui-state-focus .ui-tabs-anchor:not(.ui-state-disabled) {
  color: #333333;
}
.ui-tabs .ui-tabs-nav .ui-tabs-active .ui-tabs-anchor {
  color: #333333;
  background-color: #fff;
  cursor: default;
  border-color: #2196F3 #ddd transparent;
}
.ui-tabs .ui-tabs-nav .ui-state-disabled {
  color: #999999;
  cursor: not-allowed;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.ui-tabs .ui-tabs-nav .ui-sortable-helper:not(.ui-tabs-active) .ui-tabs-anchor {
  background-color: #fff;
  border-bottom-color: #ddd;
}
@media (max-width: 768px) {
  .ui-tabs .ui-tabs-nav {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 7px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .ui-tabs .ui-tabs-nav li {
    margin-bottom: 0;
  }
  .ui-tabs .ui-tabs-nav li + li {
    margin-top: 1px;
  }
  .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    border-width: 0 0 0 2px;
    border-left-color: transparent;
  }
  .ui-tabs .ui-tabs-nav .ui-state-hover .ui-tabs-anchor,
  .ui-tabs .ui-tabs-nav .ui-state-focus .ui-tabs-anchor {
    background-color: #fafafa;
  }
  .ui-tabs .ui-tabs-nav .ui-state-active .ui-tabs-anchor {
    border-left-color: #2196F3;
    background-color: #f5f5f5;
  }
  .ui-tabs .ui-tabs-nav:before {
    content: 'Contents';
    color: inherit;
    font-size: 12px;
    line-height: 1.6666667;
    font-weight: 500;
    margin-top: 8px;
    margin-left: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
}
.ui-tabs .ui-tabs-panel {
  display: block;
}
.ui-tooltip {
  position: absolute;
  z-index: 1070;
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #333;
  border-radius: 3px;
}
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}
.ui-helper-clearfix:after {
  clear: both;
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-front {
  z-index: 1040;
}
/* ------------------------------------------------------------------------------
*
*  # Floating action buttons
*
*  Material design floating action button with menu
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.fab-menu {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 999;
}
.fab-menu-absolute {
  position: absolute;
}
.fab-menu-absolute.affix {
  position: fixed;
}
.fab-menu-fixed {
  position: fixed;
  z-index: 1030;
}
.fab-menu-top-left,
.fab-menu-top-right {
  margin-top: -24px;
}
.fab-menu-top-left.affix,
.fab-menu-top-right.affix {
  top: 20px;
  margin-top: 0;
}
.content-wrapper > .fab-menu-top-left,
.content-wrapper > .fab-menu-top-right {
  top: -24px;
}
.content-wrapper > .fab-menu-top-left.affix,
.content-wrapper > .fab-menu-top-right.affix {
  top: 20px;
}
.navbar-top .fab-menu-top-left.affix,
.navbar-top .fab-menu-top-right.affix,
.navbar-affixed-top .fab-menu-top-left.affix,
.navbar-affixed-top .fab-menu-top-right.affix {
  top: 66px;
}
.navbar-top-lg .fab-menu-top-left.affix,
.navbar-top-lg .fab-menu-top-right.affix,
.navbar-affixed-top-lg .fab-menu-top-left.affix,
.navbar-affixed-top-lg .fab-menu-top-right.affix {
  top: 70px;
}
.navbar-top-sm .fab-menu-top-left.affix,
.navbar-top-sm .fab-menu-top-right.affix,
.navbar-affixed-top-sm .fab-menu-top-left.affix,
.navbar-affixed-top-sm .fab-menu-top-right.affix {
  top: 64px;
}
.navbar-top-xs .fab-menu-top-left.affix,
.navbar-top-xs .fab-menu-top-right.affix,
.navbar-affixed-top-xs .fab-menu-top-left.affix,
.navbar-affixed-top-xs .fab-menu-top-right.affix {
  top: 62px;
}
.fab-menu-bottom-left,
.fab-menu-bottom-right {
  bottom: 20px;
  -webkit-transition: bottom ease-in-out 0.15s;
  -o-transition: bottom ease-in-out 0.15s;
  transition: bottom ease-in-out 0.15s;
}
.fab-menu-bottom-left.reached-bottom,
.fab-menu-bottom-right.reached-bottom {
  bottom: 80px;
}
.navbar-bottom .fab-menu-bottom-left.reached-bottom,
.navbar-bottom .fab-menu-bottom-right.reached-bottom {
  bottom: 86px;
}
.navbar-bottom-lg .fab-menu-bottom-left.reached-bottom,
.navbar-bottom-lg .fab-menu-bottom-right.reached-bottom {
  bottom: 90px;
}
.navbar-bottom-sm .fab-menu-bottom-left.reached-bottom,
.navbar-bottom-sm .fab-menu-bottom-right.reached-bottom {
  bottom: 84px;
}
.navbar-bottom-xs .fab-menu-bottom-left.reached-bottom,
.navbar-bottom-xs .fab-menu-bottom-right.reached-bottom {
  bottom: 82px;
}
.fab-menu-top-right,
.fab-menu-bottom-right {
  right: 20px;
}
.fab-menu-inner {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fab-menu-inner > li {
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  text-align: center;
  padding-top: 20px;
  margin-top: -20px;
}
.fab-menu-inner > li .btn {
  border-width: 0;
}
.fab-menu-inner > li .btn-float {
  padding: 0;
  width: 44px;
  height: 44px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.fab-menu-inner > li .btn-float:hover,
.fab-menu-inner > li .btn-float:focus {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.fab-menu-inner > li .btn-float > i {
  margin: 14px;
}
.fab-menu-inner > li .btn-float.btn-flat > i {
  margin: 12px;
}
.fab-menu-inner > li .badge,
.fab-menu-inner > li .label,
.fab-menu-inner > li .status-mark {
  position: absolute;
}
@media (min-width: 769px) {
  .fab-menu-inner > li .badge,
  .fab-menu-inner > li .label,
  .fab-menu-inner > li .status-mark {
    -webkit-box-shadow: 0 0 0 2px #f5f5f5;
    box-shadow: 0 0 0 2px #f5f5f5;
  }
}
.fab-menu-inner > li .badge,
.fab-menu-inner > li .label {
  top: 16px;
  right: -4px;
}
.fab-menu-inner > li .status-mark {
  top: 22px;
  right: 2px;
}
.fab-menu-bottom-left .fab-menu-inner > li,
.fab-menu-bottom-right .fab-menu-inner > li {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 20px;
  margin-bottom: -20px;
}
.fab-menu-bottom-left .fab-menu-inner > li .badge,
.fab-menu-bottom-right .fab-menu-inner > li .badge,
.fab-menu-bottom-left .fab-menu-inner > li .label,
.fab-menu-bottom-right .fab-menu-inner > li .label {
  top: -4px;
}
.fab-menu-bottom-left .fab-menu-inner > li .status-mark,
.fab-menu-bottom-right .fab-menu-inner > li .status-mark {
  top: 2px;
}
.fab-menu-inner .dropdown-menu {
  margin-top: 0;
  margin-right: 10px;
  top: 19px;
  left: auto;
  right: 100%;
}
.fab-menu-inner .dropdown-menu-right {
  margin-right: 0;
  margin-left: 10px;
  right: auto;
  left: 100%;
}
.fab-menu-btn {
  z-index: 1001;
  border-width: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.fab-menu-btn.btn-float {
  padding: 24px;
}
.fab-menu-btn.btn-float > i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
}
.fab-menu-btn:hover,
.fab-menu-btn:focus,
.fab-menu[data-fab-toggle="hover"]:hover .fab-menu-btn,
.fab-menu[data-fab-state="open"] .fab-menu-btn {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.fab-icon-close,
.fab-icon-open {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-close,
.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-open,
.fab-menu[data-fab-state="open"] .fab-icon-close,
.fab-menu[data-fab-state="open"] .fab-icon-open {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-open,
.fab-menu[data-fab-state="open"] .fab-icon-open {
  opacity: 0;
  filter: alpha(opacity=0);
}
.fab-icon-close {
  opacity: 0;
  filter: alpha(opacity=0);
}
.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-close,
.fab-menu[data-fab-state="open"] .fab-icon-close {
  opacity: 1;
  filter: alpha(opacity=100);
}
.fab-menu .fab-menu-inner > li {
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.fab-menu .fab-menu-inner > li:nth-child(1) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.fab-menu .fab-menu-inner > li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.fab-menu .fab-menu-inner > li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.fab-menu .fab-menu-inner > li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.fab-menu .fab-menu-inner > li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.fab-menu[data-fab-toggle="hover"]:hover .fab-menu-inner > li,
.fab-menu[data-fab-state="open"] .fab-menu-inner > li {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(1) {
  top: 68px;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(2) {
  top: 132px;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(3) {
  top: 196px;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(4) {
  top: 260px;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(5) {
  top: 324px;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(1) {
  top: -60px;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(2) {
  top: -124px;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(3) {
  top: -188px;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(4) {
  top: -252px;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(5) {
  top: -316px;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.fab-menu-inner div[data-fab-label]:after {
  content: attr(data-fab-label);
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 66px;
  color: #fff;
  background-color: #333;
  padding: 7px 12px;
  visibility: hidden;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.fab-menu-bottom-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-bottom-right .fab-menu-inner div[data-fab-label]:after {
  margin-top: -27px;
}
.fab-menu-top-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-bottom-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-inner div[data-fab-label].fab-label-right:after {
  right: auto;
  left: 66px;
}
.fab-menu[data-fab-toggle="hover"] .fab-menu-inner div[data-fab-label]:hover:after,
.fab-menu[data-fab-state="open"] .fab-menu-inner div[data-fab-label]:hover:after {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fab-menu-inner div[data-fab-label].fab-label-light:after {
  background-color: #fff;
  color: #333333;
}
.fab-menu-inner div[data-fab-label].fab-label-visible:after {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
/* ------------------------------------------------------------------------------
 *
 *  # Prism
 *
 *  Lightweight, extensible syntax highlighter
 *
 *  Version: 1.0
 *  Latest update: May 10, 2015
 *
 * ---------------------------------------------------------------------------- */
code[class*="language-"],
pre[class*="language-"] {
  text-shadow: 0 1px #fff;
  word-break: normal;
  line-height: 1.5;
  direction: ltr;
}
@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata,
.token.punctuation {
  color: #999;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
  color: #905;
}
.token.selector,
.token.attr-name,
.token.string,
.token.builtin {
  color: #690;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5);
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}
.token.function {
  color: #DD4A68;
}
.token.regex,
.token.important {
  color: #e90;
}
.token.important {
  font-weight: bold;
}
.token.entity {
  cursor: help;
}
.namespace {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.line-highlight {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 20px;
  background: rgba(133, 238, 149, 0.12);
  pointer-events: none;
  white-space: pre;
  line-height: inherit;
}
.line-highlight:before,
.line-highlight:after {
  content: attr(data-start);
  position: absolute;
  top: 0px;
  left: 10px;
  padding: 2px;
  text-align: center;
  font-size: 10px;
  color: #999999;
}
.line-highlight[data-end]:after {
  content: attr(data-end);
  top: auto;
  bottom: 0;
}
pre.line-numbers {
  position: relative;
  padding-left: 50px;
  counter-reset: linenumber;
}
pre.line-numbers > code {
  position: relative;
}
.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -50px;
  width: 36px;
  letter-spacing: -1px;
  border-right: 1px solid #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.line-numbers .line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}
.line-numbers .line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999999;
  display: block;
  padding-right: 10px;
  text-align: right;
}
pre[class*="language-"] {
  overflow: auto;
  position: relative;
  border-left-width: 3px;
  border-left-color: #2196F3;
  background-color: #fdfdfd;
  background-image: -webkit-linear-gradient(transparent 50%, rgba(25, 118, 210, 0.025) 50%);
  background-image: -moz-linear-gradient(transparent 50%, rgba(25, 118, 210, 0.025) 50%);
  background-image: -ms-linear-gradient(transparent 50%, rgba(25, 118, 210, 0.025) 50%);
  background-image: -o-linear-gradient(transparent 50%, rgba(25, 118, 210, 0.025) 50%);
  background-image: linear-gradient(transparent 50%, rgba(25, 118, 210, 0.025) 50%);
  background-size: 3em 3em;
  background-origin: content-box;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
pre[class*="language-"] > code[data-language] {
  display: block;
}
pre[class*="language-"] > code[data-language]:before {
  content: attr(data-language);
  background-color: #f8f8f8;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 11px;
  border-radius: 0 0 0 2px;
  padding: 4px 8px;
  text-shadow: none;
  color: #333333;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
pre[class*="language-"].line-numbers > code[data-language]:before {
  top: -15px;
  right: -15px;
}
pre[data-line] {
  position: relative;
  padding-left: 36px;
}
/* ------------------------------------------------------------------------------
*
*  # Fancytree
*
*  Tree plugin for jQuery with support for persistence, keyboard, checkboxes,
*  tables (grid), drag'n'drop, and lazy loading
*
*  Version: 1.1
*  Latest update: Jul 5, 2016
*
* ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.fancytree-container {
  list-style: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  overflow: auto;
  position: relative;
}
.fancytree-container:focus {
  outline: 0;
}
.fancytree-container ul {
  list-style: none;
  padding: 0 0 0 20px;
  margin: 0;
}
.fancytree-container ul li {
  margin-top: 1px;
}
.ui-fancytree-disabled .fancytree-container {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.ui-fancytree-disabled .fancytree-container,
.ui-fancytree-disabled .fancytree-title,
.ui-fancytree-disabled .fancytree-expander {
  cursor: not-allowed;
}
.ui-fancytree-disabled .fancytree-treefocus .fancytree-selected .fancytree-title {
  background-color: #f5f5f5;
  color: #333333;
}
.ui-helper-hidden {
  display: none;
}
.fancytree-expander:after,
.fancytree-icon:after,
.fancytree-checkbox:after,
.fancytree-drag-helper-img:after,
.fancytree-drop-before:after,
.fancytree-drop-after:after,
.fancytree-loading .fancytree-expander:after,
.fancytree-statusnode-wait .fancytree-icon:after {
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fancytree-icon,
.fancytree-checkbox,
.fancytree-custom-icon {
  margin-top: 5px;
  float: left;
}
.fancytree-checkbox {
  margin: 4px 10px 0 2px;
}
.fancytree-custom-icon {
  display: inline-block;
}
img.fancytree-icon {
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-top: 3px;
  vertical-align: top;
  border-style: none;
}
.fancytree-expander {
  cursor: pointer;
  float: left;
  margin-top: 5px;
  width: 16px;
  line-height: 1;
}
.fancytree-exp-c .fancytree-expander:after {
  content: '\e9c7';
}
.fancytree-exp-cl .fancytree-expander:after {
  content: '\e9c7';
}
.fancytree-exp-cd .fancytree-expander:after,
.fancytree-exp-cdl .fancytree-expander:after {
  content: '\e9c7';
}
.fancytree-exp-e .fancytree-expander:after,
.fancytree-exp-ed .fancytree-expander:after {
  content: '\e9c5';
}
.fancytree-exp-el .fancytree-expander:after,
.fancytree-exp-edl .fancytree-expander:after {
  content: '\e9c5';
}
.fancytree-statusnode-error .fancytree-icon:after {
  content: '\ed63';
}
.fancytree-loading .fancytree-expander,
.fancytree-statusnode-wait .fancytree-icon {
  margin-left: auto;
  margin-right: auto;
  width: 16px;
  height: 16px;
  display: inline-block;
  text-align: center;
}
.fancytree-loading .fancytree-expander:after,
.fancytree-statusnode-wait .fancytree-icon:after {
  content: '\ed6c';
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.fancytree-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.fancytree-ico-c .fancytree-icon:after,
.fancytree-ico-e .fancytree-icon:after {
  content: '\ea1a';
}
.fancytree-has-children.fancytree-ico-c .fancytree-icon:after {
  content: '\ea0f';
}
.fancytree-has-children.fancytree-ico-e .fancytree-icon:after {
  content: '\ea11';
}
.fancytree-ico-cf .fancytree-icon:after,
.fancytree-ico-ef .fancytree-icon:after {
  content: '\ea3d';
}
.fancytree-has-children.fancytree-ico-cf .fancytree-icon:after {
  content: '\ea41';
}
.fancytree-has-children.fancytree-ico-ef .fancytree-icon:after {
  content: '\ea43';
}
.fancytree-checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid #777;
  display: block;
  text-align: center;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
}
.fancytree-checkbox:after,
.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
  content: "\e600";
  margin-top: -1px;
  margin-left: -1px;
  display: none;
}
.fancytree-partsel > .fancytree-checkbox:after {
  content: "";
  width: 8px;
  height: 8px;
  display: none;
  background-color: #888;
  margin: 3px;
}
.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
  background-color: transparent;
  width: auto;
  height: auto;
}
.fancytree-selected .fancytree-checkbox:after,
.fancytree-partsel .fancytree-checkbox:after,
.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
  display: block;
}
.fancytree-radio .fancytree-checkbox {
  border-radius: 100%;
}
.fancytree-radio .fancytree-checkbox:after {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #888;
  margin: 3px;
  border-radius: 100px;
}
/* # Other styles
-------------------------------------------------- */
.fancytree-drag-helper {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 4px 5px;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.fancytree-drag-helper-img:after {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: -26px;
}
.fancytree-drop-accept .fancytree-drag-helper-img:after {
  content: '\ee73';
  color: #4CAF50;
}
.fancytree-drop-reject .fancytree-drag-helper-img:after {
  content: '\ed63';
  color: #F44336;
}
#fancytree-drop-marker.fancytree-drop-before,
#fancytree-drop-marker.fancytree-drop-after {
  width: 200px;
  border-top: 1px solid #333333;
  position: absolute!important;
}
.fancytree-drag-source {
  background-color: #ddd;
}
.fancytree-drag-source .fancytree.title {
  color: #333333;
}
.fancytree-drop-target.fancytree-drop-accept a {
  background-color: #2196F3 !important;
  color: #fff !important;
  text-decoration: none;
}
.fancytree-node {
  display: inherit;
  width: 100%;
  position: relative;
}
.fancytree-title {
  border: 0;
  padding: 4px 6px;
  border-radius: 2px;
  margin-left: 4px;
  display: inline-block;
  cursor: pointer;
}
.fancytree-title > input {
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
}
.fancytree-selected .fancytree-title {
  background-color: #ddd;
  color: #333333;
}
.fancytree-treefocus .fancytree-selected .fancytree-title {
  background-color: #2196F3;
  color: #fff;
}
.fancytree-active .fancytree-title {
  background-color: #ddd;
}
.sidebar:not(.sidebar-default) .fancytree-selected .fancytree-title,
.sidebar:not(.sidebar-default) .fancytree-active .fancytree-title {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.sidebar:not(.sidebar-default) .fancytree-treefocus .fancytree-selected .fancytree-title {
  background-color: #2196F3;
  color: #fff;
}
.fancytree-ext-table .fancytree-node {
  display: inline-block;
  width: auto;
}
.fancytree-ext-table .fancytree-title {
  display: inline;
}
.fancytree-ext-table.fancytree-container {
  white-space: normal;
}
.fancytree-ext-table .fancytree-expander,
.fancytree-ext-table .fancytree-icon,
.fancytree-ext-table .fancytree-custom-icon {
  margin-top: 1px;
}
.fancytree-ext-table .fancytree-checkbox {
  margin: 0 auto;
  float: none;
}
.fancytree-ext-table .checker {
  display: block;
  margin: 0 auto;
}
.fancytree-ext-table tbody tr td:first-child {
  text-align: center;
}
.fancytree-ext-table tbody tr.fancytree-focused {
  background-color: #f5f5f5;
}
.fancytree-ext-table tbody tr.fancytree-active {
  background-color: #f5f5f5;
}
.fancytree-ext-table tbody tr.fancytree-selected {
  background-color: #2196F3;
  color: #fff;
}
.fancytree-ext-table tbody tr.fancytree-selected .fancytree-checkbox {
  color: #fff;
  border-color: #fff;
}
.fancytree-ext-table tbody tr.fancytree-selected .checker span {
  border-color: #fff;
  color: #fff;
}
.fancytree-ext-childcounter .fancytree-childcounter {
  position: absolute;
  right: 0;
  top: 4px;
  color: #999999;
}
/* ------------------------------------------------------------------------------
*
*  # Ladda progress buttons
*
*  Buttons with built-in loading indicators
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.btn-ladda,
.btn-ladda .ladda-spinner,
.btn-ladda .ladda-label {
  -webkit-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
  -o-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
}
.btn-ladda .ladda-spinner {
  position: absolute;
  z-index: 2;
  display: inline-block;
  top: 50%;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda .ladda-label {
  z-index: 3;
}
.btn-ladda .ladda-progress {
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  -webkit-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-loading] .ladda-progress {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=zoom-in],
.btn-ladda[data-style=zoom-out] {
  overflow: hidden;
}
.btn-ladda[data-style=zoom-in],
.btn-ladda[data-style=zoom-out],
.btn-ladda[data-style=zoom-in] .ladda-spinner,
.btn-ladda[data-style=zoom-out] .ladda-spinner,
.btn-ladda[data-style=zoom-in] .ladda-label,
.btn-ladda[data-style=zoom-out] .ladda-label {
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.btn-ladda[data-style=zoom-in] .ladda-label,
.btn-ladda[data-style=zoom-out] .ladda-label {
  position: relative;
  display: inline-block;
}
.btn-ladda[data-style=zoom-in] .ladda-spinner,
.btn-ladda[data-style=zoom-out] .ladda-spinner {
  left: 50%;
}
.btn-ladda[data-style=zoom-in][data-loading] .ladda-label,
.btn-ladda[data-style=zoom-out][data-loading] .ladda-label {
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=zoom-in][data-loading] .ladda-spinner,
.btn-ladda[data-style=zoom-out][data-loading] .ladda-spinner {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=zoom-in] .ladda-spinner {
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
}
.btn-ladda[data-style=zoom-in][data-loading] .ladda-label {
  -webkit-transform: scale(2.2);
  -ms-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
}
.btn-ladda[data-style=zoom-out] .ladda-spinner {
  -webkit-transform: scale(2.5);
  -ms-transform: scale(2.5);
  -o-transform: scale(2.5);
  transform: scale(2.5);
}
.btn-ladda[data-style=zoom-out][data-loading] .ladda-label {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}
.btn-ladda[data-style=expand-left] {
  overflow: hidden;
}
.btn-ladda[data-style=expand-left] .ladda-spinner {
  left: 20px;
}
.btn-ladda[data-style=expand-left][data-loading] {
  padding-left: 35px;
}
.btn-ladda[data-style=expand-left][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=expand-right] {
  overflow: hidden;
}
.btn-ladda[data-style=expand-right] .ladda-spinner {
  right: 20px;
}
.btn-ladda[data-style=expand-right][data-loading] {
  padding-right: 35px;
}
.btn-ladda[data-style=expand-right][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=expand-up] {
  overflow: hidden;
}
.btn-ladda[data-style=expand-up] .ladda-spinner {
  top: -16px;
  left: 50%;
}
.btn-ladda[data-style=expand-up][data-loading] {
  padding-top: 40px;
}
.btn-ladda[data-style=expand-up][data-loading] .ladda-spinner {
  top: 12px;
  margin-top: 8px;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=expand-down] {
  overflow: hidden;
}
.btn-ladda[data-style=expand-down] .ladda-spinner {
  top: 44px;
  left: 50%;
}
.btn-ladda[data-style=expand-down][data-loading] {
  padding-bottom: 40px;
}
.btn-ladda[data-style=expand-down][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=slide-left] {
  overflow: hidden;
}
.btn-ladda[data-style=slide-left] .ladda-label {
  left: 0;
  position: relative;
}
.btn-ladda[data-style=slide-left] .ladda-spinner {
  right: 0;
}
.btn-ladda[data-style=slide-left][data-loading] .ladda-label {
  left: -100%;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=slide-left][data-loading] .ladda-spinner {
  right: 50%;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=slide-right] {
  overflow: hidden;
}
.btn-ladda[data-style=slide-right] .ladda-label {
  right: 0;
  position: relative;
}
.btn-ladda[data-style=slide-right] .ladda-spinner {
  left: 0;
}
.btn-ladda[data-style=slide-right][data-loading] .ladda-label {
  right: -100%;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=slide-right][data-loading] .ladda-spinner {
  left: 50%;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=slide-up] {
  overflow: hidden;
}
.btn-ladda[data-style=slide-up] .ladda-label {
  top: 0;
  position: relative;
}
.btn-ladda[data-style=slide-up] .ladda-spinner {
  top: 100%;
  left: 50%;
}
.btn-ladda[data-style=slide-up][data-loading] .ladda-label {
  top: -16px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=slide-up][data-loading] .ladda-spinner {
  top: 16px;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=slide-down] {
  overflow: hidden;
}
.btn-ladda[data-style=slide-down] .ladda-label {
  top: 0;
  position: relative;
}
.btn-ladda[data-style=slide-down] .ladda-spinner {
  top: -100%;
  left: 50%;
}
.btn-ladda[data-style=slide-down][data-loading] .ladda-label {
  top: 16px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=slide-down][data-loading] .ladda-spinner {
  top: 16px;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=fade] {
  overflow: hidden;
}
.btn-ladda[data-style=fade] .ladda-spinner {
  left: 50%;
}
.btn-ladda[data-style=fade][data-loading] .ladda-label {
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=fade][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-ladda[data-style=radius] {
  overflow: hidden;
  border-radius: 2px;
  -webkit-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}
.btn-ladda[data-style=radius] .ladda-spinner {
  left: 50%;
}
.btn-ladda[data-style=radius][data-loading] {
  border-radius: 5px;
}
.btn-ladda[data-style=radius][data-loading] .ladda-label {
  opacity: 0;
  filter: alpha(opacity=0);
}
.btn-ladda[data-style=radius][data-loading] .ladda-spinner {
  opacity: 1;
  filter: alpha(opacity=100);
}
/* ------------------------------------------------------------------------------
 *
 *  # Headroom
 *
 *  Hide BS navbar component on page scroll
 *
 *  Version: 1.1
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.headroom {
  -webkit-transition: top 0.2s ease-in-out, bottom 0.2s ease-in-out;
  -o-transition: top 0.2s ease-in-out, bottom 0.2s ease-in-out;
  transition: top 0.2s ease-in-out, bottom 0.2s ease-in-out;
}
.navbar.headroom-top-pinned {
  top: 0;
}
.navbar.headroom-top-unpinned {
  top: -48px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar-lg.navbar.headroom-top-unpinned {
  top: -52px;
}
.navbar-sm.navbar.headroom-top-unpinned {
  top: -46px;
}
.navbar-xs.navbar.headroom-top-unpinned {
  top: -44px;
}
.navbar.headroom-bottom-pinned {
  bottom: -48px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar-lg.navbar.headroom-bottom-pinned {
  bottom: -52px;
}
.navbar-sm.navbar.headroom-bottom-pinned {
  bottom: -46px;
}
.navbar-xs.navbar.headroom-bottom-pinned {
  bottom: -44px;
}
.navbar.headroom-bottom-unpinned {
  bottom: 0;
}
/* ------------------------------------------------------------------------------
 *
 *  # FullCalendar
 *
 *  A JavaScript event calendar. Customizable and open source
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.fc {
  direction: ltr;
  text-align: left;
}
.fc-view > table {
  min-width: 769px;
}
.fc-events-container {
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 3px;
}
.fc-events-container .fc-event {
  margin: 0;
  padding: 4px 11px;
}
.fc-events-container .fc-event + .fc-event {
  margin-top: 10px;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed hr,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #ddd;
}
.fc-unthemed thead,
.fc-unthemed thead td,
.fc-unthemed thead th {
  border-bottom-color: #bbb;
}
.fc-unthemed hr {
  background-color: #f5f5f5;
}
.fc-unthemed .fc-popover {
  background-color: #fff;
  border-radius: 3px;
}
.fc-unthemed .fc-popover .fc-header {
  background-color: #f5f5f5;
}
.fc-unthemed .fc-today {
  background-color: #E8F5E9;
}
.fc-highlight {
  background-color: #e5e5e5;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-bgevent {
  background-color: #81C784;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-nonbusiness {
  background-color: #ccc;
}
.fc-icon {
  display: block;
}
.fc-icon:after {
  display: inline-block;
  font-family: "icomoon";
  line-height: 1;
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fc-icon-left-single-arrow:after {
  content: "\e9c0";
}
.fc-icon-right-single-arrow:after {
  content: "\e9c3";
}
.fc-icon-left-double-arrow:after {
  content: "\000AB";
}
.fc-icon-right-double-arrow:after {
  content: "\000BB";
}
.fc-icon-x:after {
  content: "\ed6b";
  font-size: 12px;
  display: block;
}
.fc-button {
  margin: 0;
  border: 1px solid #ddd;
  padding: 7px 12px;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
}
.fc-button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}
.fc-state-default {
  border: 1px solid #ddd;
  background-color: #fcfcfc;
}
.fc-state-default.fc-corner-left {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.fc-state-default.fc-corner-right {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.fc-state-hover {
  background-color: #f8f8f8;
}
.fc-state-down,
.fc-state-active {
  background-color: #f5f5f5;
}
.fc-button.fc-state-down,
.fc-button.fc-state-active {
  border-color: #ddd;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
}
.fc-state-disabled {
  color: #999999;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.fc-button-group {
  display: inline-block;
}
.fc .fc-button-group > * {
  float: left;
  margin: 0 0 0 -1px;
}
.fc .fc-button-group > :first-child {
  margin-left: 0;
}
.fc-popover {
  position: absolute;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}
.fc-popover .fc-header {
  padding: 10px;
}
.fc-popover .fc-header .fc-close {
  cursor: pointer;
}
.fc-ltr .fc-popover .fc-header .fc-title {
  float: left;
}
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}
.fc-unthemed .fc-popover .fc-header .fc-close {
  margin-top: 2px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.fc-unthemed .fc-popover .fc-header .fc-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.fc hr {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  border-style: solid;
  border-width: 1px 0;
}
.fc-clear {
  clear: both;
}
.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-bg {
  bottom: 0;
}
.fc-bg table {
  height: 100%;
}
.fc table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}
.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}
.fc th {
  padding: 12px 20px;
  font-weight: 500;
  text-align: center;
}
.fc td.fc-today {
  border-style: double;
}
.fc-row {
  border-style: solid;
  border-width: 0;
  position: relative;
}
.fc-row table {
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  border-bottom: 0 hidden transparent;
}
.fc-row:first-child table {
  border-top: 0 hidden transparent;
}
.fc-row .fc-bg {
  z-index: 1;
}
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
}
.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
}
.fc-row .fc-bgevent-skeleton td,
.fc-row .fc-highlight-skeleton td {
  border-color: transparent;
  border: 0;
}
.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}
.fc-row .fc-highlight-skeleton {
  z-index: 3;
}
.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  background: none;
  border-color: transparent;
  border-bottom: 0;
}
.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  border-top: 0;
}
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
}
.fc-row .fc-helper-skeleton {
  z-index: 5;
}
.fc-scroller {
  overflow-y: scroll;
  overflow-x: hidden;
}
.fc-scroller > * {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.fc-event {
  position: relative;
  display: block;
  border: 1px solid #039BE5;
  background-color: #039BE5;
  font-weight: normal;
  border-radius: 2px;
}
.fc-event,
.fc-event:hover {
  color: #fff;
  text-decoration: none;
}
.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc-day-grid-event {
  margin: 0 5px 5px 5px;
  padding: 4px 8px;
}
.fc-day-grid-event > .fc-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fc-day-grid-event .fc-time {
  font-weight: 500;
}
.fc-day-grid-event .fc-resizer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 7px;
}
.fc-ltr .fc-day-grid-event .fc-resizer {
  right: -3px;
  cursor: e-resize;
}
@media (min-width: 1200px) {
  .fc-day-grid-event > .fc-content {
    background-color: inherit;
  }
  .fc-day-grid-event .fc-time {
    float: right;
    padding-left: 5px;
    display: inline-block;
    z-index: 2;
    position: relative;
    background-color: inherit;
  }
  .fc-day-grid-event .fc-title {
    z-index: 1;
    position: relative;
  }
}
a.fc-more {
  margin: 1px 5px;
  background-color: #fafafa;
  display: block;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  color: #999999;
  font-size: 12px;
}
a.fc-more:hover {
  background-color: #f5f5f5;
  color: #333333;
}
.fc-limited {
  display: none;
}
.fc-day-grid .fc-row {
  z-index: 1;
}
.fc-more-popover {
  z-index: 2;
  width: 220px;
}
.fc-more-popover .fc-event-container {
  padding: 10px 5px;
}
.fc-toolbar {
  text-align: center;
  margin-bottom: 20px;
}
.fc-toolbar h2 {
  margin: 5px 0;
  font-size: 17px;
}
.fc-toolbar .fc-left {
  float: left;
  width: 33%;
}
.fc-toolbar .fc-right {
  float: right;
  width: 33%;
}
.fc-toolbar .fc-right .fc-button-group {
  float: right;
}
.fc-toolbar .fc-left > .fc-button,
.fc-toolbar .fc-right > .fc-button {
  margin-left: 10px;
}
.fc-toolbar .fc-center {
  display: inline-block;
  vertical-align: middle;
}
.fc-toolbar > * > * {
  float: left;
}
.fc-toolbar > * > :first-child {
  margin-left: 0;
}
.fc-toolbar button {
  position: relative;
}
.fc-toolbar button:focus {
  z-index: 5;
}
.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}
.fc-toolbar .fc-state-down {
  z-index: 3;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}
.fc-view-container {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  overflow: auto;
  max-width: 100%;
}
.fc-view-container .fc-view > table th,
.fc-view-container .fc-view > table td {
  border-right-width: 0;
}
.fc-view-container .fc-view > table tr:first-child th:first-child,
.fc-view-container .fc-view > table tr:first-child td:first-child {
  border-left-width: 0;
}
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.fc-view,
.fc-view > table {
  position: relative;
  z-index: 1;
}
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  padding-top: 5px;
  padding-bottom: 10px;
}
.fc-basic-view tbody .fc-row {
  min-height: 100px;
  min-width: 100px;
}
.fc-row.fc-rigid {
  overflow: hidden;
}
.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 10px;
}
.fc-basic-view .fc-week-number {
  text-align: center;
}
.fc-basic-view .fc-week-number span {
  display: inline-block;
  min-width: 1.25em;
}
.fc-basic-view .fc-day-number {
  text-align: right;
}
.fc-basic-view .fc-day-number.fc-other-month {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
}
.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
}
.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-top: 5px;
  padding-bottom: 0;
}
.fc-axis {
  text-align: right;
}
.fc .fc-axis {
  vertical-align: middle;
  padding: 10px;
  white-space: nowrap;
}
.fc-time-grid-container,
.fc-time-grid {
  position: relative;
  z-index: 1;
}
.fc-time-grid {
  min-height: 100%;
}
.fc-time-grid table {
  border: 0 hidden transparent;
}
.fc-time-grid > .fc-bg {
  z-index: 1;
}
.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  position: relative;
  z-index: 2;
}
.fc-time-grid .fc-bgevent-skeleton,
.fc-time-grid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-bgevent-skeleton {
  z-index: 3;
}
.fc-time-grid .fc-highlight-skeleton {
  z-index: 4;
}
.fc-time-grid .fc-content-skeleton {
  z-index: 5;
}
.fc-time-grid .fc-helper-skeleton {
  z-index: 6;
}
.fc-slats td {
  border-bottom: 0;
}
.fc-slats .fc-minor td {
  border-top-style: dotted;
}
.fc-time-grid .fc-highlight-container {
  position: relative;
}
.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-event-container,
.fc-time-grid .fc-bgevent-container {
  position: relative;
}
.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  margin: 5px;
  padding: 10px;
  min-height: 45px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
}
.fc-time-grid .fc-bgevent {
  left: 0;
  right: 0;
}
.fc-time-grid-event {
  overflow: hidden;
}
.fc-time-grid-event.fc-not-start {
  border-top-width: 0;
  padding-top: 1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.fc-time-grid-event.fc-not-end {
  border-bottom-width: 0;
  padding-bottom: 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-time-grid-event > .fc-content {
  position: relative;
  z-index: 2;
}
.fc-time-grid-event .fc-time {
  font-weight: 500;
}
.fc-time-grid-event.fc-short .fc-content {
  white-space: nowrap;
}
.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: inline-block;
  vertical-align: top;
}
.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
}
.fc-time-grid-event.fc-short .fc-time:after {
  content: "\000A0-\000A0";
}
.fc-time-grid-event.fc-short .fc-time span {
  display: none;
}
.fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  padding: 0;
}
.fc-time-grid-event .fc-resizer {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 10px;
  font-size: 12px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1px;
}
.fc-time-grid-event .fc-resizer:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.fc-time-grid-event .fc-resizer:after {
  content: "=";
}
@media (max-width: 768px) {
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-left > .fc-button,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-right > .fc-button,
  .fc-toolbar .fc-left .fc-button-group,
  .fc-toolbar .fc-right .fc-button-group {
    float: none;
    width: auto;
    vertical-align: top;
  }
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right {
    display: block;
    margin-bottom: 10px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Dragula - drag and drop library
*
*  Styles for Dragula Drag and drop plugin
*
*  Version: 1.0
*  Latest update: Nov 25, 2015
*
* ---------------------------------------------------------------------------- */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.gu-unselectable.dropdown-menu li:nth-last-child(2) {
  margin-bottom: 0;
}
.nav-pills-toolbar > li.gu-mirror:not(.active) > a {
  border: 0;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.gu-transit {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dragula-handle {
  color: #999999;
  cursor: pointer;
}
.dragula-handle:hover,
.dragula-handle:focus {
  color: #333333;
}
/* ------------------------------------------------------------------------------
*
*  # Plupload multiple file uploader
*
*  Multi runtime single and multiple file uploader
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.plupload_wrapper {
  width: 100%;
  border: 2px dashed #ddd;
  border-radius: 3px;
}
.panel > div > .plupload_wrapper {
  border: 0;
}
.panel.panel-flat > div > .plupload_wrapper {
  border-top: 1px solid #ddd;
}
.plupload_header:after,
.plupload_filelist:empty:before,
.plupload_filelist li.plupload_droptext:before,
.plupload_file_action:after,
.plupload_delete a:after,
.plupload_failed a:after,
.plupload_done a:after {
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.plupload_header {
  display: none;
  position: relative;
  background-color: #0097A7;
  margin: -1px -1px 0 -1px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.plupload_header:after {
  content: '\ec78';
  color: #fff;
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -16px;
}
.plupload_header_content {
  padding: 15px 20px;
  padding-left: 72px;
  color: #fff;
}
.plupload_header_title {
  font-size: 15px;
  font-weight: 500;
}
.plupload_header_text {
  font-size: 11px;
  line-height: 1.82;
}
.plupload_clear,
.plupload_clearer {
  clear: both;
}
.plupload_clearer,
.plupload_progress_bar {
  display: block;
  font-size: 0;
  line-height: 0;
}
.plupload_button {
  display: inline-block;
  color: #fff;
  background-color: #2196F3;
  border: 1px solid transparent;
  padding: 6px 11px;
  border-radius: 2px;
  margin-right: 5px;
}
.plupload_button:hover,
.plupload_button:focus {
  color: #fff;
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.plupload_button:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
a.plupload_disabled,
a.plupload_disabled:hover,
a.plupload_disabled:focus {
  color: #999999;
  border-color: #ddd;
  background: #fcfcfc;
  cursor: default;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.plupload_start {
  background-color: #26A69A;
}
.plupload_filelist {
  margin: 0;
  padding: 0;
  list-style: none;
}
.plupload_scroll .plupload_filelist {
  height: 250px;
  background: #fff;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .plupload_scroll .plupload_filelist {
    height: 200px;
  }
}
.plupload_filelist li {
  padding: 12px 20px;
  background-color: #fff;
}
.plupload_filelist li:hover {
  background-color: #f5f5f5;
}
.plupload_filelist li.plupload_droptext:hover {
  background-color: transparent;
}
.plupload_filelist:empty,
.plupload_filelist li.plupload_droptext {
  position: relative;
  height: 250px;
  text-align: center;
  background-color: transparent;
  text-indent: -99999px;
}
.plupload_filelist:empty:before,
.plupload_filelist li.plupload_droptext:before {
  content: '\ea0e';
  font-size: 80px;
  padding-top: 65px;
  position: absolute;
  top: 0;
  width: 80px;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  z-index: 2;
  color: #ddd;
  text-indent: 0;
  font-weight: normal;
}
.plupload_filelist:empty:after,
.plupload_filelist li.plupload_droptext:after {
  content: 'Drag files to upload';
  font-size: 19px;
  position: absolute;
  top: 50%;
  padding-top: 145px;
  left: 50%;
  margin: -100px 0 0 -150px;
  width: 300px;
  height: 200px;
  color: #bbb;
  text-indent: 0;
}
@media (max-width: 768px) {
  .plupload_filelist:empty,
  .plupload_filelist li.plupload_droptext {
    height: 200px;
  }
  .plupload_filelist:empty:before,
  .plupload_filelist li.plupload_droptext:before,
  .plupload_filelist:empty:after,
  .plupload_filelist li.plupload_droptext:after {
    width: 100%;
    height: 200px;
    left: 0;
    margin: 0;
    top: 0;
  }
  .plupload_filelist:empty:before,
  .plupload_filelist li.plupload_droptext:before {
    font-size: 64px;
    padding-top: 40px;
  }
  .plupload_filelist:empty:after,
  .plupload_filelist li.plupload_droptext:after {
    font-size: 17px;
    border-width: 3px 0;
    padding-top: 120px;
  }
}
.plupload_filelist:empty:after {
  content: 'Add files to upload';
}
.plupload_filelist_header {
  padding: 15px 20px;
}
.plupload_filelist_header .plupload_file_name,
.plupload_filelist_header .plupload_file_size,
.plupload_filelist_header .plupload_file_status,
.plupload_filelist_header .plupload_file_action {
  font-weight: 500;
}
.plupload_filelist_footer {
  padding: 15px 20px;
}
.plupload_filelist_footer .plupload_file_size,
.plupload_filelist_footer .plupload_file_status,
.plupload_filelist_footer .plupload_upload_status {
  padding: 7px 0;
  font-weight: 500;
}
@media (max-width: 768px) {
  .plupload_filelist_footer .plupload_file_action,
  .plupload_filelist_footer .plupload_file_status,
  .plupload_filelist_footer .plupload_file_size,
  .plupload_filelist_footer .plupload_progress {
    display: none;
  }
}
.plupload_file_action {
  position: relative;
  float: right;
  margin-left: 30px;
  margin-top: 2px;
  color: #999999;
  width: 16px;
  height: 16px;
}
.plupload_file_action * {
  display: none;
}
.plupload_file_action:after {
  content: '\ec67';
  position: absolute;
  left: 0;
  top: 0;
}
.plupload_filelist_footer .plupload_file_action {
  margin-top: 9px;
}
.plupload_filelist .plupload_file_action:after {
  content: none;
}
.plupload_delete .plupload_file_action > a {
  color: #333333;
  line-height: 1;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.plupload_delete .plupload_file_action > a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.plupload_uploading {
  background-color: #E8F5E9;
}
.plupload_delete a:after {
  content: '\ed6b';
}
.plupload_failed a {
  color: #F44336;
  cursor: default;
}
.plupload_failed a:after {
  content: '\e9cf';
}
.plupload_done {
  color: #999999;
}
.plupload_done a {
  color: #4DB6AC;
  cursor: default;
}
.plupload_done a:after {
  content: '\e9db';
}
.plupload_progress,
.plupload_upload_status {
  display: none;
}
.plupload_progress_container {
  margin-top: 14px;
  background-color: #eeeeee;
}
.plupload_progress_bar {
  width: 0px;
  height: 4px;
  background: #4DB6AC;
  border-radius: 5px;
}
@media (min-width: 769px) {
  .plupload_file_name {
    float: left;
    overflow: hidden;
  }
  .plupload_filelist .plupload_file_name {
    width: 205px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .plupload_file_size,
  .plupload_file_status,
  .plupload_progress {
    float: right;
    width: 80px;
  }
  .plupload_file_size,
  .plupload_file_status,
  .plupload_file_action {
    text-align: right;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap file input
 *
 *  File input styling for Bootstrap 3.0
 *
 *  Version: 1.3
 *  Latest update: Aug 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.file-input.has-error .form-control {
  border-color: #ddd;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 769px) {
  .file-input .btn > [class*=icon-],
  .file-input .btn > .glyphicon {
    margin-right: 5px;
  }
}
.file-object {
  margin: 0 0 -7px 0;
  padding: 0;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  background: none repeat scroll 0 0 transparent;
  cursor: inherit;
  display: block;
}
.file-caption-name {
  display: inline-block;
  overflow: hidden;
  word-break: break-all;
}
.file-caption-name [class*=icon-],
.file-caption-name .glyphicon {
  margin-right: 7px;
}
.file-input-new .file-caption-name {
  color: #999999;
}
.file-input-new .file-caption-name > [class*=icon-],
.file-input-new .file-caption-name > .glyphicon {
  display: none;
}
.file-error-message {
  position: relative;
  background-color: #f2dede;
  color: #a94442;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  margin: 0 5px 5px 5px;
}
.file-drop-zone .file-error-message {
  margin-top: 5px;
}
.file-error-message .close {
  margin-top: 1px;
  color: #a94442;
}
.file-error-message pre,
.file-error-message ul {
  margin: 7px 0;
  text-align: left;
}
.file-caption-disabled {
  background-color: #fafafa;
  cursor: not-allowed;
}
.file-caption-disabled .file-caption-name {
  color: #ccc;
}
.file-preview-detail-modal {
  text-align: left;
}
.file-preview {
  border-radius: 2px;
  border: 1px solid #ddd;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}
.file-preview > .close {
  font-weight: 400;
  font-size: 17px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  padding: 8px;
  line-height: 0.6;
  border-radius: 3px;
  color: #555;
  opacity: 1;
  filter: alpha(opacity=100);
}
.file-preview-frame {
  margin: 7px;
  margin-right: 0;
  height: 160px;
  display: table;
  float: left;
  vertical-align: middle;
}
.file-drop-zone .file-preview-frame {
  border: 1px solid #ddd;
  border-radius: 3px;
}
.file-preview-image {
  height: 160px;
  vertical-align: middle;
  border-radius: 3px;
}
@media (max-width: 768px) {
  .file-preview-image {
    max-width: 100%;
  }
}
.file-preview-text {
  text-align: left;
  width: 160px;
  margin-bottom: 2px;
  color: #428bca;
  background-color: #fff;
  overflow-x: hidden;
}
.file-preview-other {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 160px;
  height: 160px;
}
.file-preview-other:after {
  content: "\ea0c";
  font-family: "icomoon";
  display: block;
  font-size: 64px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.file-preview-other .glyphicon {
  display: none;
}
.file-preview-status {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #333333;
  background-color: rgba(250, 250, 250, 0.8);
  border-top: 1px solid #ddd;
  padding: 7px 12px;
  font-size: 12px;
  line-height: 1.6666667;
  color: #333333 !important;
}
.file-preview-status:empty {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.file-thumb-loading {
  min-height: 107px;
}
.file-thumb-loading:before,
.file-uploading:before {
  content: "";
  display: inline-block;
  background-color: #263238;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -33.5px 0 0 -16px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
}
.file-thumb-loading:after,
.file-uploading:after {
  content: "\eb55";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  margin: -25.5px 0 0 -8px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.file-upload-indicator {
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: default;
  text-align: left;
  padding-left: 8px;
}
.file-input-new .file-preview,
.file-input-new .close,
.file-input-new .glyphicon-file,
.file-input-new .fileinput-remove-button,
.file-input-new .fileinput-upload-button {
  display: none;
}
.file-input-ajax-new .fileinput-remove-button,
.file-input-ajax-new .fileinput-upload-button,
.file-input-ajax-new .fileinput-remove,
.file-input-ajax-new .file-caption-name > [class*=icon-],
.file-input-ajax-new .file-caption-name > .glyphicon {
  display: none;
}
.file-actions {
  margin-top: 5px;
  border-top: 1px solid #eeeeee;
}
.file-actions .btn > [class*=icon-],
.file-actions .btn > .glyphicon {
  margin-right: 0;
}
.file-footer-buttons {
  float: right;
}
.file-footer-caption {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  text-align: center;
  font-size: 11px;
  padding: 12px 7px 7px 7px;
  margin-left: auto;
  margin-right: auto;
}
.file-preview-error {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.file-drop-zone {
  border: 1px dashed #ddd;
  border-radius: 3px;
  height: 100%;
  vertical-align: middle;
  margin: 5px;
  padding: 5px;
}
.file-drop-zone-title {
  color: #999999;
  font-size: 21px;
  font-weight: 300;
  padding: 85px 10px;
}
.file-highlighted {
  border-color: #ccc;
  background-color: #fafafa;
}
.file-thumb-progress {
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
}
.file-thumb-progress .progress,
.file-thumb-progress .progress-bar {
  height: 5px;
  border-radius: 0;
  font-size: 0;
}
.file-thumbnail-footer {
  position: relative;
}
.btn-file ::-ms-browse {
  width: 100%;
  height: 100%;
}
.file-zoom-fullscreen.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.file-zoom-fullscreen .modal-dialog {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
.file-zoom-fullscreen .modal-content {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.file-zoom-fullscreen .modal-body {
  overflow-y: auto;
}
.file-zoom-dialog .btn-navigate {
  position: absolute;
  top: 50%;
  margin-top: -27px;
  padding: 0;
  border: 0;
  background: transparent;
  outline: none;
  color: #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.file-zoom-dialog .btn-navigate > i {
  display: block;
  font-size: 32px;
}
.file-zoom-dialog .btn-navigate:not([disabled]):hover,
.file-zoom-dialog .btn-navigate:not([disabled]):focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.file-zoom-dialog .btn-navigate[disabled] {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.file-zoom-dialog .btn-prev {
  left: -42px;
}
.file-zoom-dialog .btn-next {
  right: -42px;
}
.file-zoom-dialog .floating-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  border-radius: 0 0 0 3px;
}
.file-zoom-dialog .floating-buttons:not(:empty) {
  padding: 0 0 20px 20px;
}
.file-zoom-dialog .floating-buttons,
.file-zoom-dialog .floating-buttons .btn {
  z-index: 3000;
}
.kv-zoom-actions {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -6px;
}
.file-zoom-content {
  height: 480px;
  text-align: center;
}
.file-drag-handle {
  margin-right: 2px;
  float: left;
  cursor: move;
  cursor: -webkit-grabbing;
}
.file-drag-handle:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
/* ------------------------------------------------------------------------------
 *
 *  # Dropzone file uploader
 *
 *  Open source library that provides drag’n’drop file uploads with image previews
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.dropzone {
  position: relative;
  border: 3px dashed #eeeeee;
  min-height: 250px;
  border-radius: 2px;
  background-color: #fcfcfc;
  padding: 5px;
}
.dropzone.dz-clickable,
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message span {
  cursor: pointer;
}
.dropzone.dz-clickable * {
  cursor: default;
}
.dropzone .dz-message {
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-color: rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.04);
}
.sidebar .dropzone {
  min-height: 190px;
  text-align: center;
  border-width: 2px;
}
.sidebar .dropzone .dz-default.dz-message:before {
  font-size: 64px;
  width: 64px;
  height: 64px;
  margin-left: -32px;
  top: 50px;
}
.sidebar .dropzone .dz-default.dz-message span {
  font-size: 15px;
}
.sidebar .dropzone .dz-default.dz-message > span {
  margin-top: 130px;
}
@media (min-width: 769px) {
  .dropzone {
    min-height: 320px;
  }
}
.dropzone .dz-default.dz-message {
  position: absolute;
  height: 230px;
  width: 100%;
  margin-top: -115px;
  top: 50%;
  left: 0;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.dropzone .dz-default.dz-message:before {
  content: '\ea0e';
  font-family: 'icomoon';
  font-size: 64px;
  position: absolute;
  top: 48px;
  width: 64px;
  height: 64px;
  display: inline-block;
  left: 50%;
  margin-left: -32px;
  line-height: 1;
  z-index: 2;
  color: #ddd;
  text-indent: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropzone .dz-default.dz-message span {
  font-size: 15px;
  color: #bbb;
  text-align: center;
  margin-top: 140px;
  display: block;
  font-weight: 500;
  text-shadow: 0 1px 1px #fff;
}
.dropzone .dz-default.dz-message span > span {
  margin-top: 2px;
  display: block;
  color: #ccc;
  font-size: 15px;
}
@media (min-width: 769px) {
  .dropzone .dz-default.dz-message:before {
    font-size: 80px;
    top: 48px;
    width: 80px;
    height: 80px;
    margin-left: -32px;
  }
  .dropzone .dz-default.dz-message span {
    font-size: 19px;
    margin-top: 130px;
  }
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.15;
  filter: alpha(opacity=15);
}
.dropzone.dz-started .dz-message {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
}
.dropzone .dz-preview,
.dropzone-previews .dz-preview {
  background-color: #fff;
  position: relative;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 2px;
}
.dropzone .dz-preview.dz-file-preview [data-dz-thumbnail],
.dropzone-previews .dz-preview.dz-file-preview [data-dz-thumbnail] {
  display: none;
}
.dropzone .dz-preview .dz-details,
.dropzone-previews .dz-preview .dz-details {
  width: 140px;
  position: relative;
  background-color: #fafafa;
  padding: 8px;
  margin-bottom: 25px;
}
.dropzone .dz-preview .dz-details .dz-filename,
.dropzone-previews .dz-preview .dz-details .dz-filename {
  overflow: hidden;
  height: 100%;
}
.dropzone .dz-preview .dz-details img,
.dropzone-previews .dz-preview .dz-details img {
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 140px;
}
.dropzone .dz-preview .dz-details .dz-size,
.dropzone-previews .dz-preview .dz-details .dz-size {
  position: absolute;
  bottom: -26px;
  left: 0;
}
.dropzone .dz-preview:hover .dz-details img,
.dropzone-previews .dz-preview:hover .dz-details img {
  display: none;
}
.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  display: none;
  position: absolute;
  z-index: 9;
  right: 8px;
}
.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after,
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  font-family: 'icomoon';
  display: block;
  position: absolute;
  right: 0;
  bottom: 3px;
  font-size: 12px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropzone .dz-preview .dz-error-mark span,
.dropzone-previews .dz-preview .dz-error-mark span,
.dropzone .dz-preview .dz-success-mark span,
.dropzone-previews .dz-preview .dz-success-mark span,
.dropzone .dz-preview .dz-error-mark svg,
.dropzone-previews .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg,
.dropzone-previews .dz-preview .dz-success-mark svg {
  display: none;
}
.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark {
  color: #F44336;
}
.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after {
  content: '\ed6a';
}
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  color: #4CAF50;
}
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  content: '\ed6e';
}
.dropzone .dz-preview.dz-error .dz-error-mark,
.dropzone-previews .dz-preview.dz-error .dz-error-mark,
.dropzone .dz-preview.dz-success .dz-success-mark,
.dropzone-previews .dz-preview.dz-success .dz-success-mark {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone .dz-preview .dz-progress,
.dropzone-previews .dz-preview .dz-progress {
  position: absolute;
  top: 118px;
  left: 8px;
  right: 8px;
  height: 4px;
  background-color: #eeeeee;
  display: none;
  border-radius: 2px;
}
.dropzone .dz-preview .dz-progress .dz-upload,
.dropzone-previews .dz-preview .dz-progress .dz-upload {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #00BCD4;
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.dropzone .dz-preview.dz-processing .dz-progress,
.dropzone-previews .dz-preview.dz-processing .dz-progress {
  display: block;
}
.dropzone .dz-preview.dz-success .dz-progress,
.dropzone-previews .dz-preview.dz-success .dz-progress {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.dropzone .dz-preview .dz-error-message,
.dropzone-previews .dz-preview .dz-error-message {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(245, 245, 245, 0.8);
  margin: 8px;
  padding: 8px;
  color: #F44336;
  max-width: 100%;
  z-index: 5;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message,
.dropzone-previews .dz-preview.dz-error:hover .dz-error-message {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dropzone .dz-preview.dz-error .dz-progress .dz-upload,
.dropzone-previews .dz-preview.dz-error .dz-progress .dz-upload {
  background: #F44336;
}
.dropzone .dz-preview.dz-image-preview:hover .dz-details img,
.dropzone-previews .dz-preview.dz-image-preview:hover .dz-details img {
  display: block;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dropzone .dz-preview a.dz-remove,
.dropzone-previews .dz-preview a.dz-remove {
  background-color: #fafafa;
  border-radius: 2px;
  border: 1px solid #eeeeee;
  display: block;
  padding: 5px;
  text-align: center;
  color: #999999;
  margin-top: 35px;
  cursor: pointer;
}
.dropzone .dz-preview a.dz-remove:hover,
.dropzone-previews .dz-preview a.dz-remove:hover {
  color: #333333;
  background-color: #f5f5f5;
}
/* ------------------------------------------------------------------------------
 *
 *  # Image cropper
 *
 *  Styles for cropper.min.js - a simple jQuery image cropping plugin
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.image-cropper-container {
  height: 350px;
  width: 100%;
  overflow: hidden;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}
.eg-preview {
  text-align: center;
  font-size: 0;
}
.eg-preview .preview {
  margin: 10px auto 0 auto;
  display: block;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px;
}
@media (min-width: 769px) {
  .eg-preview .preview {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
  }
  .eg-preview .preview + .preview {
    margin-left: 15px;
  }
}
.eg-preview .preview-lg {
  width: 150px;
  height: 150px;
}
.eg-preview .preview-md {
  width: 120px;
  height: 120px;
}
.eg-preview .preview-sm {
  width: 90px;
  height: 90px;
}
.eg-preview .preview-xs {
  width: 60px;
  height: 60px;
}
.eg-preview .preview-xxs {
  width: 40px;
  height: 40px;
}
@media (min-width: 769px) {
  .eg-preview {
    white-space: nowrap;
    max-width: 100%;
    overflow: auto;
  }
}
.cropper-container {
  font-size: 0;
  line-height: 0;
  position: relative;
  direction: ltr !important;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cropper-container img {
  display: block;
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  height: 100%;
  min-height: 0 !important;
  max-height: none !important;
  image-orientation: 0deg !important;
}
.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cropper-wrap-box {
  overflow: hidden;
}
.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.cropper-modal {
  background-color: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.cropper-view-box {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.cropper-dashed {
  position: absolute;
  display: block;
  border: 0 dashed #eee;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.cropper-dashed.dashed-h {
  top: 33.33333%;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}
.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.cropper-center:before,
.cropper-center:after {
  content: ' ';
  position: absolute;
  display: block;
  background-color: #eee;
}
.cropper-center:before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}
.cropper-center:after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}
.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff;
}
.cropper-line {
  background-color: #2196F3;
}
.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}
.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}
.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}
.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}
.cropper-point {
  width: 5px;
  height: 5px;
  background-color: #2196F3;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}
.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}
.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}
.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}
.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}
.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}
.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}
.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
  filter: alpha(opacity=100);
}
.cropper-point.point-se:before {
  content: ' ';
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  background-color: #2196F3;
  opacity: 0;
  filter: alpha(opacity=0);
}
@media (min-width: 769px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}
@media (min-width: 1025px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    opacity: 0.75;
    filter: alpha(opacity=75);
  }
}
.cropper-bg {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}
.cropper-invisible {
  opacity: 0;
  filter: alpha(opacity=0);
}
.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}
.cropper-hidden {
  display: none !important;
}
.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
.cropper-move {
  cursor: move;
}
.cropper-crop {
  cursor: crosshair;
}
/* ------------------------------------------------------------------------------
*
*  # Fancybox lightbox
*
*  Mac-style "lightbox" plugin that floats overtop of web page.
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}
.fancybox-skin {
  position: relative;
  background-color: #fff;
  color: #333333;
  border-radius: 3px;
}
.fancybox-opened {
  z-index: 8030;
}
.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  cursor: pointer;
  z-index: 8060;
  color: #fff;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
#fancybox-loading div:after {
  content: '\eb55';
  font-family: 'icomoon';
  font-size: 16px;
  margin-top: -8px;
  margin-left: -8px;
  display: block;
  line-height: 1;
  width: 16px;
  height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s ease infinite;
  -o-animation: rotation 1s ease infinite;
  animation: rotation 1s ease infinite;
}
.fancybox-close {
  position: fixed;
  right: 0;
  top: 0;
  padding: 15px;
  z-index: 8061;
  cursor: pointer;
  line-height: 1;
  color: #fff;
  border-radius: 3px;
}
.fancybox-close:hover,
.fancybox-close:focus {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
}
.fancybox-close:after {
  content: '\ed6a';
  font-family: 'icomoon';
  font-size: 16px;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fancybox-error {
  color: #F44336;
  font-weight: 500;
  margin: 0;
  padding: 20px;
  white-space: nowrap;
}
.fancybox-title {
  visibility: hidden;
  font-weight: 500;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -45px;
  z-index: 8050;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 7px 12px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  white-space: nowrap;
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background-color: transparent;
}
.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  z-index: 8040;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  cursor: pointer;
  padding: 8px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  text-align: center;
  z-index: 8040;
  visibility: hidden;
  border-radius: 3px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.fancybox-nav span:after {
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fancybox-nav:hover span {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}
.fancybox-prev {
  left: 0;
}
.fancybox-prev span {
  left: 10px;
}
.fancybox-prev span:after {
  content: '\e9c8';
}
.fancybox-next {
  right: 0;
}
.fancybox-next span {
  right: 10px;
}
.fancybox-next span:after {
  content: '\e9cb';
}
.fancybox-lock {
  overflow: visible !important;
  width: auto;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
.fancybox-lock body {
  overflow: hidden !important;
}
.fancybox-lock-test {
  overflow-y: hidden !important;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background-color: rgba(0, 0, 0, 0.8);
}
.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}
/* ------------------------------------------------------------------------------
*
*  # Footable
*
*  jQuery plugin that aims to make HTML tables on smaller devices look awesome.
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.footable {
  width: 100%;
}
.footable > thead > tr > th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.footable.breakpoint > tbody > tr.footable-detail-show > td {
  border-bottom: 0;
}
.footable.breakpoint > tbody > tr.footable-row-detail:hover {
  background-color: transparent;
}
.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}
.footable.breakpoint > tbody > tr > .footable-cell-detail {
  background-color: #eeeeee;
  border-top: 0;
}
.footable.breakpoint > tbody > tr .footable-toggle:before {
  content: "\e9e4";
  display: inline-block;
  font-family: 'icomoon';
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 10px;
}
.footable.breakpoint > tbody > tr.footable-detail-show .footable-toggle:before {
  content: "\e9e7";
}
.footable-row-detail-inner {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.footable-row-detail-row {
  display: table-row;
}
.footable-row-detail-group {
  display: block;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 7px;
}
.footable-row-detail-group:first-child {
  margin-top: 7px;
}
.footable-row-detail-name {
  display: table-cell;
  padding-right: 40px;
  font-weight: 500;
}
.footable-row-detail-value {
  display: table-cell;
  padding: 7px 0;
}
@media (max-width: 480px) {
  .footable-row-detail-inner {
    position: relative;
  }
  .footable-row-detail .dropdown,
  .footable-row-detail .dropup,
  .footable-row-detail .btn-group {
    position: static;
  }
  .footable-row-detail .dropdown-menu {
    left: 0;
    right: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Handsontable library
 *
 *  EXCEL-LIKE SPREADSHEET FOR APPS
 *
 *  Version: 1.0
 *  Latest update: Nov 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.hot-container {
  position: relative;
  -webkit-box-shadow: 0 0 0 1px #ddd;
  box-shadow: 0 0 0 1px #ddd;
}
.hot-container .ht_master .wtHolder {
  min-width: 100%;
}
.hot-container.has-scroll {
  height: 300px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .hot-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .hot-container .ht_master .wtHolder {
    max-width: 100%;
    min-width: auto;
  }
  .hot-container:not(.has-scroll) .wtHolder {
    height: 100%!important;
  }
}
.handsontable .table-striped > tbody > tr:nth-of-type(odd) > td:not([class*=bg-]) {
  background-color: inherit;
}
.handsontable .table-hover > tbody > tr:hover > th {
  background-color: #f5f5f5;
}
.changeType {
  border: 0;
  background-color: transparent;
  border-radius: 2px;
  padding: 0;
  float: right;
  margin-right: 2px;
  line-height: 16px;
  margin-top: 2px;
}
.changeType:hover {
  color: #777;
}
.changeTypeMenu {
  position: absolute;
  border: 1px solid #ccc;
  margin-top: 20px;
  background-color: #fff;
  padding: 7px 0;
  display: none;
  width: 160px;
  z-index: 1000;
}
.changeTypeMenu li {
  list-style: none;
  padding: 7px 12px;
  padding-right: 34px;
  cursor: pointer;
  position: relative;
}
.changeTypeMenu li:hover {
  background-color: #f5f5f5;
}
.changeTypeMenu li.active:after {
  content: '\e600';
  font-family: 'Icomoon';
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -8px;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.handsontable {
  position: relative;
  direction: ltr;
}
.handsontable .hide {
  display: none;
}
.handsontable .relative {
  position: relative;
}
.handsontable.htAutoSize {
  visibility: hidden;
  left: -99000px;
  position: absolute;
  top: -99000px;
}
.handsontable .htDimmed {
  color: #999999;
}
.handsontable .wtHider {
  width: 0;
}
.handsontable .wtSpreader {
  position: relative;
  width: 0;
  /*must be 0, otherwise blank space appears in scroll demo after scrolling max to the right */
  height: auto;
}
.handsontable table,
.handsontable tbody,
.handsontable thead,
.handsontable td,
.handsontable th,
.handsontable input,
.handsontable textarea,
.handsontable div {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.handsontable input,
.handsontable textarea {
  min-height: initial;
}
.handsontable .htCore {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  border-width: 0;
  table-layout: fixed;
  width: 0;
  outline-width: 0;
  max-width: none;
  max-height: none;
}
.handsontable col,
.handsontable col.rowHeader {
  width: 50px;
}
.handsontable th,
.handsontable td {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 30px;
  empty-cells: show;
  position: relative;
  padding: 0 4px;
  overflow: hidden;
  outline-width: 0;
  white-space: pre-line;
}
.handsontable th.disabled,
.handsontable td.disabled {
  background-color: #fafafa;
  color: #999999;
}
.handsontable th {
  text-align: center;
  font-weight: normal;
  white-space: nowrap;
}
.handsontable th:not([class*=bg-]) {
  background-color: #f5f5f5;
}
.handsontable th.active {
  background-color: #eeeeee;
}
.handsontable thead th {
  padding: 0;
}
.handsontable thead th .relative {
  padding: 2px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.handsontable td:not([class*=bg-]) {
  background-color: #fff;
}
.handsontable td.htInvalid {
  background-color: #FFEBEE !important;
}
.handsontable td.htNoWrap {
  white-space: nowrap;
}
.handsontable td.active {
  background-color: #f8f8f8;
}
.handsontable td.area {
  background-color: #fff;
  background: -moz-linear-gradient(top, rgba(181, 209, 255, 0.34) 0%, rgba(181, 209, 255, 0.34) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(181, 209, 255, 0.34)), color-stop(100%, rgba(181, 209, 255, 0.34)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(181, 209, 255, 0.34) 0%, rgba(181, 209, 255, 0.34) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(181, 209, 255, 0.34) 0%, rgba(181, 209, 255, 0.34) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(181, 209, 255, 0.34) 0%, rgba(181, 209, 255, 0.34) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(181, 209, 255, 0.34) 0%, rgba(181, 209, 255, 0.34) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#57b5d1ff', endColorstr='#57b5d1ff', GradientType=0);
  /* IE6-9 */
}
.handsontable tr:first-child th.htNoFrame,
.handsontable th:first-child.htNoFrame,
.handsontable th.htNoFrame {
  border-left-width: 0;
  background-color: #fff;
  border-color: #fff;
}
.handsontable th:first-child,
.handsontable td:first-of-type,
.handsontable .htNoFrame + th,
.handsontable .htNoFrame + td {
  border-left: 1px solid #ddd;
}
.handsontable.htRowHeaders thead tr th:nth-child(2) {
  border-left: 1px solid #ddd;
}
.handsontable tr:first-child th,
.handsontable tr:first-child td {
  border-top: 1px solid #ddd;
}
.ht_master:not(.innerBorderLeft) ~ .handsontable tbody tr th,
.ht_master:not(.innerBorderLeft) ~ .handsontable:not(.ht_clone_top) thead tr th:first-child {
  border-right-width: 0;
}
.ht_master:not(.innerBorderTop) thead tr:last-child th,
.ht_master:not(.innerBorderTop) thead tr.lastChild th,
.ht_master:not(.innerBorderTop) ~ .handsontable thead tr:last-child th,
.ht_master:not(.innerBorderTop) ~ .handsontable thead tr.lastChild th {
  border-bottom-width: 0;
}
.ht_master tbody tr:first-child td {
  border-top-color: transparent;
}
.ht_clone_top tr:first-child th,
.ht_clone_top tr:first-child td {
  border-top-color: transparent;
}
.ht_clone_left thead tr:first-child th,
.ht_clone_top_left_corner thead tr:first-child th,
.ht_clone_left tbody tr:first-child td,
.ht_clone_top_left_corner tbody tr:first-child td,
.ht_clone_left tbody tr:first-child th,
.ht_clone_top_left_corner tbody tr:first-child th {
  border-top-color: transparent;
}
.handsontable th:first-child,
.handsontable td:first-child {
  border-left-color: transparent;
}
.ht_master td:last-child,
.ht_clone_top th:last-child,
.ht_clone_top td:last-child {
  border-right-color: transparent;
}
.ht_master tbody tr:last-child th,
.ht_clone_left tbody tr:last-child th,
.ht_master tbody tr:last-child td,
.ht_clone_left tbody tr:last-child td {
  border-bottom-color: transparent;
}
.htRowHeaders.htColumnHeaders .ht_clone_top_left_corner tbody tr:first-child th,
.htRowHeaders.htColumnHeaders .ht_clone_left tbody tr:first-child th {
  border-top-color: #ddd;
}
.htColumnHeaders .handsontable tr:first-child td {
  border-top-color: #ddd;
}
.manualColumnMover,
.manualRowMover {
  position: fixed;
  top: 0;
  background-color: transparent;
  z-index: 999;
  cursor: move;
}
.manualColumnMover {
  left: 0;
  width: 5px;
  height: 32px;
}
.manualRowMover {
  left: -4px;
  height: 5px;
  width: 50px;
}
.manualColumnMoverGuide,
.manualRowMoverGuide {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ddd;
  display: none;
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.manualColumnMoverGuide.active,
.manualRowMoverGuide.active {
  display: block;
}
.manualColumnMoverGuide:hover,
.manualRowMoverGuide:hover,
.manualColumnMoverGuide.active,
.manualRowMoverGuide.active {
  background-color: #2196F3;
}
.manualColumnMoverGuide {
  max-height: 404px;
}
.manualColumnResizer.active,
.manualRowResizer.active {
  display: block;
}
.manualColumnResizer:hover,
.manualRowResizer:hover,
.manualColumnResizer.active,
.manualRowResizer.active {
  background-color: #999999;
}
.manualColumnResizer {
  position: fixed;
  top: 0;
  cursor: col-resize;
  z-index: 110;
  width: 5px;
  height: 31px;
}
.manualRowResizer {
  position: fixed;
  left: 0;
  cursor: row-resize;
  z-index: 110;
  height: 5px;
  width: 50px;
}
.manualColumnResizerGuide {
  position: fixed;
  right: 0;
  top: 0;
  background-color: #999999;
  display: none;
  width: 0;
  border-right: 1px dashed #777;
  margin-left: 5px;
}
.manualRowResizerGuide {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #999999;
  display: none;
  height: 0;
  border-bottom: 1px dashed #777;
  margin-top: 5px;
}
.handsontable .columnSorting {
  position: relative;
}
.handsontable .columnSorting:hover {
  cursor: pointer;
}
.handsontable .columnSorting:before,
.handsontable .columnSorting:after,
.handsontable .columnSorting.ascending:after,
.handsontable .columnSorting.descending:after {
  font-family: 'Icomoon';
  font-size: 12px;
  color: #999999;
  position: absolute;
  display: inline-block;
  top: 50%;
  margin-top: -6px;
  right: -20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.handsontable .columnSorting:before {
  content: '\e9c1';
  margin-top: -2px;
}
.handsontable .columnSorting:after {
  content: '\e9c2';
  margin-top: -10px;
}
.handsontable .columnSorting.ascending:before {
  content: none;
}
.handsontable .columnSorting.ascending:after {
  content: '\e9c2';
}
.handsontable .columnSorting.descending:before {
  content: none;
}
.handsontable .columnSorting.descending:after {
  content: '\e9c1';
}
.handsontable th.beforeHiddenColumn,
.handsontable th.afterHiddenColumn {
  position: relative;
}
.handsontable th.beforeHiddenColumn:after,
.handsontable th.afterHiddenColumn:after,
.handsontable th.beforeHiddenColumn:before,
.handsontable th.afterHiddenColumn:before {
  content: '\25C0';
  color: #bbb;
  position: absolute;
  right: 1px;
  top: 2px;
  font-size: 5pt;
}
.handsontable th.afterHiddenColumn:before {
  left: 1px;
  top: 2px;
  right: auto;
  content: '\25B6';
}
.handsontable thead th.hiddenHeader {
  display: none;
}
.handsontable td.afterHiddenColumn.firstVisible {
  border-left: 1px solid #ddd;
}
.wtBorder {
  position: absolute;
  font-size: 0;
  z-index: 10;
}
.wtBorder.hidden {
  display: none!important;
}
.wtBorder.corner {
  font-size: 0;
  cursor: crosshair;
}
.htBorder.htFillBorder {
  background-color: #F44336;
  width: 1px;
  height: 1px;
}
.handsontableInput {
  border: none;
  outline-width: 0;
  margin: 0 ;
  padding: 1px 5px 0 5px;
  font-family: inherit;
  line-height: 30px;
  font-size: inherit;
  resize: none;
  display: inline-block;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 2px #2196F3 inset;
  box-shadow: 0 0 0 2px #2196F3 inset;
}
.handsontableInputHolder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
.htSelectEditor {
  -webkit-appearance: menulist-button !important;
  position: absolute;
  width: auto;
  outline: 0;
  border-color: #ddd;
  background-color: #fff;
}
.handsontable .htSubmenu {
  position: relative;
}
.handsontable .htSubmenu > div:after {
  content: '\e9c7';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 12px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.handsontable .htLeft {
  text-align: left;
}
.handsontable .htCenter {
  text-align: center;
}
.handsontable .htRight {
  text-align: right;
}
.handsontable .htJustify {
  text-align: justify;
}
.handsontable .htTop {
  vertical-align: top;
}
.handsontable .htMiddle {
  vertical-align: middle;
}
.handsontable .htBottom {
  vertical-align: bottom;
}
.htAutocomplete {
  padding-right: 20px;
}
.htPlaceholder {
  color: #999999;
}
.htAutocompleteArrow {
  color: #999999;
  cursor: default;
  font-size: 0;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -2px;
  display: inline-block;
  margin-right: 4px;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.handsontable td:hover .htAutocompleteArrow,
.handsontable th:hover .htAutocompleteArrow {
  color: #333333;
}
.autocompleteEditor,
.autocompleteEditor .wtHolder,
.autocompleteEditor .htCore {
  min-width: 180px;
}
.htCheckboxRendererInput.noValue {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.htCheckboxRendererLabel {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}
.htCheckboxRendererLabel > .htCheckboxRendererInput {
  margin-right: 10px;
}
.htCommentCell {
  position: relative;
}
.htCommentCell:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-left: 6px solid transparent;
  border-top: 6px solid red;
}
.htCommentTextArea {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.handsontable .handsontable.ht_clone_top .wtHider {
  padding: 0 0 5px 0;
}
.handsontable.listbox {
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
}
.handsontable.listbox .ht_master table {
  border-collapse: separate;
  border: 0;
}
.handsontable.listbox th,
.handsontable.listbox tr:first-child th,
.handsontable.listbox tr:last-child th,
.handsontable.listbox tr:first-child td,
.handsontable.listbox td {
  border-color: transparent;
}
.handsontable.listbox th,
.handsontable.listbox td {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.handsontable.listbox td.htDimmed {
  cursor: pointer;
  color: inherit;
  font-style: inherit;
}
.handsontable.listbox .wtBorder {
  visibility: hidden;
}
.handsontable.listbox tr td.current,
.handsontable.listbox tr:hover td {
  background-color: #f5f5f5;
}
.ht_clone_top {
  z-index: 11;
}
.ht_clone_left {
  z-index: 12;
}
.ht_clone_top_left_corner,
.ht_clone_bottom_left_corner {
  z-index: 13;
}
.ht_clone_debug {
  z-index: 13;
}
.ht_master,
.ht_clone_left,
.ht_clone_top,
.ht_clone_bottom {
  overflow: hidden;
}
.ht_master .wtHolder {
  overflow: auto;
}
.ht_clone_left .wtHolder {
  overflow-x: hidden;
  overflow-y: auto;
}
.ht_clone_top .wtHolder,
.ht_clone_bottom .wtHolder {
  overflow-x: hidden;
  overflow-y: hidden;
}
.handsontable td.htSearchResult {
  background-color: #FFF3E0;
}
.htBordered {
  border-width: 1px;
}
.htBordered.htTopBorderSolid {
  border-top-style: solid;
  border-top-color: #000;
}
.htBordered.htRightBorderSolid {
  border-right-style: solid;
  border-right-color: #000;
}
.htBordered.htBottomBorderSolid {
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.htBordered.htLeftBorderSolid {
  border-left-style: solid;
  border-left-color: #000;
}
.handsontable thead tr:nth-last-child(2) th.htGroupIndicatorContainer {
  padding-bottom: 5px;
}
.htCollapseButton {
  width: 10px;
  height: 10px;
  line-height: 10px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #f5f5f5;
  cursor: pointer;
  margin-bottom: 3px;
  position: relative;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}
.htCollapseButton:after {
  content: "";
  height: 300%;
  width: 1px;
  display: block;
  background-color: #ccc;
  margin-left: 4px;
  position: absolute;
  bottom: 10px;
}
thead .htCollapseButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
}
thead .htCollapseButton:after {
  height: 1px;
  width: 700%;
  right: 10px;
  top: 4px;
}
.handsontable th .htExpandButton {
  position: absolute;
  top: 0;
  display: none;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}
.handsontable th .htExpandButton.clickable {
  display: block;
}
.handsontable thead th .htExpandButton {
  top: 5px;
}
.collapsibleIndicator {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 5px;
  border: 1px solid #999999;
  line-height: 10px;
  color: #333333;
  border-radius: 100px;
  font-size: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: #eee;
  -webkit-box-shadow: 0 0 0 6px #eeeeee;
  box-shadow: 0 0 0 6px #eeeeee;
}
.handsontable col.hidden {
  width: 0!important;
}
.handsontable table tr th.lightRightBorder {
  border-right: 1px solid #E6E6E6;
}
.handsontable tr.hidden,
.handsontable tr.hidden td,
.handsontable tr.hidden th {
  display: none;
}
.wtDebugHidden {
  display: none;
}
.wtDebugVisible {
  display: block;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: wtFadeInFromNone;
  animation-name: wtFadeInFromNone;
}
@keyframes wtFadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@-webkit-keyframes wtFadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
.handsontable.mobile,
.handsontable.mobile .wtHolder {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.htMobileEditorContainer {
  display: none;
  position: absolute;
  top: 0;
  width: 70%;
  height: 54pt;
  background: #f5f5f5;
  border-radius: 20px;
  border: 1px solid #eee;
  z-index: 999;
  -webkit-text-size-adjust: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.htMobileEditorContainer.active {
  display: block;
}
.htMobileEditorContainer .inputs {
  position: absolute;
  right: 210px;
  bottom: 10px;
  top: 10px;
  left: 14px;
  height: 36px;
}
.htMobileEditorContainer .inputs textarea {
  font-size: 13px;
  border: 1px solid #a1a1a1;
  -webkit-appearance: none;
  position: absolute;
  left: 14px;
  right: 14px;
  top: 0;
  bottom: 0;
  padding: 7px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.htMobileEditorContainer .cellPointer {
  position: absolute;
  top: -13px;
  height: 0;
  width: 0;
  left: 30px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #ebebeb;
}
.htMobileEditorContainer .cellPointer.hidden {
  display: none;
}
.htMobileEditorContainer .cellPointer:before {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  height: 0;
  width: 0;
  left: -13px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #f5f5f5;
}
.htMobileEditorContainer .moveHandle {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 30px;
  bottom: 0px;
  cursor: move;
  z-index: 9999;
}
.htMobileEditorContainer .moveHandle:after {
  content: "..\a..\a..\a..";
  white-space: pre;
  line-height: 10px;
  font-size: 20px;
  display: inline-block;
  margin-top: -8px;
  color: #ebebeb;
}
.htMobileEditorContainer .positionControls {
  width: 205px;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
}
.htMobileEditorContainer .positionControls > div {
  width: 50px;
  height: 100%;
  float: left;
}
.htMobileEditorContainer .positionControls > div:after {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 50px;
}
.htMobileEditorContainer .leftButton:after,
.htMobileEditorContainer .rightButton:after,
.htMobileEditorContainer .upButton:after,
.htMobileEditorContainer .downButton:after {
  margin: 21px 0 0 21px;
  -webkit-transform-origin: 5px;
  -moz-transform-origin: 5px;
  -ms-transform-origin: 5px;
  transform-origin: 5px;
}
.htMobileEditorContainer .leftButton:after {
  border-top: 2px solid #288ffe;
  border-left: 2px solid #288ffe;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.htMobileEditorContainer .leftButton:active:after {
  border-color: #cfcfcf;
}
.htMobileEditorContainer .rightButton:after {
  border-top: 2px solid #288ffe;
  border-left: 2px solid #288ffe;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.htMobileEditorContainer .rightButton:active:after {
  border-color: #cfcfcf;
}
.htMobileEditorContainer .upButton:after {
  border-top: 2px solid #288ffe;
  border-left: 2px solid #288ffe;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.htMobileEditorContainer .upButton:active:after {
  border-color: #cfcfcf;
}
.htMobileEditorContainer .downButton:after {
  border-top: 2px solid #288ffe;
  border-left: 2px solid #288ffe;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}
.htMobileEditorContainer .downButton:active:after {
  border-color: #cfcfcf;
}
.topLeftSelectionHandle:not(.ht_master .topLeftSelectionHandle),
.topLeftSelectionHandle-HitArea:not(.ht_master .topLeftSelectionHandle-HitArea) {
  z-index: 9999;
}
.topLeftSelectionHandle,
.topLeftSelectionHandle-HitArea,
.bottomRightSelectionHandle,
.bottomRightSelectionHandle-HitArea {
  left: -10000px;
  top: -10000px;
}
.handsontable.hide-tween {
  -webkit-animation: opacity-hide 0.3s;
  -o-animation: opacity-hide 0.3s;
  animation: opacity-hide 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.handsontable.show-tween {
  -webkit-animation: opacity-show 0.3s;
  -o-animation: opacity-show 0.3s;
  animation: opacity-show 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes opacity-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes opacity-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes opacity-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opacity-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.htContextMenu {
  display: none;
  position: absolute;
  z-index: 1051;
}
.htContextMenu .ht_clone_top,
.htContextMenu .ht_clone_left,
.htContextMenu .ht_clone_corner,
.htContextMenu .ht_clone_debug {
  display: none;
}
.htContextMenu .htCore {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 7px 0;
}
.htContextMenu .wtBorder {
  visibility: hidden;
}
.htContextMenu table tbody tr td {
  position: relative;
  border-width: 0;
  padding: 0 12px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.htContextMenu table tbody tr td:first-child {
  border: 0;
}
.htContextMenu table tbody tr td.htDimmed {
  color: #333333;
}
.htContextMenu table tbody tr td.current,
.htContextMenu table tbody tr td.zeroclipboard-is-hover {
  background: #f5f5f5;
}
.htContextMenu table tbody tr td.htSeparator {
  height: 0;
  padding: 0;
  background-color: transparent;
  cursor: default;
}
.htContextMenu table tbody tr td.htSeparator > div {
  height: 1px;
  background-color: #ddd;
  margin: 7px 0;
}
.htContextMenu table tbody tr td.htDisabled {
  color: #999999;
}
.htContextMenu table tbody tr td.htDisabled,
.htContextMenu table tbody tr td.htDisabled:hover {
  cursor: default;
}
.htContextMenu table tbody tr td div span.selected {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -8px;
  font-size: 0;
  color: #777;
}
.htContextMenu table tbody tr td div span.selected:after {
  content: '\e600';
  font-family: 'Icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.htContextMenu table tbody tr.htHidden {
  display: none;
}
.htContextMenu .ht_master .wtHolder {
  overflow: hidden;
}
.htContextMenuSub_Alignment {
  margin-top: -7px;
  margin-left: -1px;
}
.global-zeroclipboard-container,
.global-zeroclipboard-flash-bridge {
  cursor: pointer;
}
.pika-single {
  z-index: 1000;
  display: block;
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.pika-single:before,
.pika-single:after {
  content: " ";
  display: table;
}
.pika-single:after {
  clear: both;
}
.pika-single.is-hidden {
  display: none;
}
.pika-single.is-bound {
  position: absolute;
}
.pika-lendar {
  float: left;
  max-width: 250px;
  margin: 5px;
}
.pika-title {
  position: relative;
  text-align: center;
}
.pika-title select {
  cursor: pointer;
  position: absolute;
  z-index: 999;
  margin: 0;
  left: 0;
  height: 36px;
  padding: 7px 12px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.pika-label {
  display: inline-block;
  position: relative;
  z-index: 1000;
  overflow: hidden;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  line-height: 1;
}
.pika-label + .pika-label {
  margin-left: 10px;
  top: -1px;
  font-size: 13px;
  color: #999999;
}
.pika-prev,
.pika-next {
  display: block;
  position: relative;
  border: 0;
  padding: 5px;
  margin: 10px 0;
  font-size: 0;
  background-color: transparent;
}
.pika-prev:after,
.pika-next:after {
  font-family: 'Icomoon';
  display: block;
  font-size: 16px;
  width: 16px;
  text-align: center;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pika-prev:hover,
.pika-next:hover {
  background-color: #f5f5f5;
}
.pika-prev.is-disabled,
.pika-next.is-disabled {
  cursor: default;
  opacity: .2;
}
.pika-prev {
  float: left;
}
.pika-prev:after {
  content: '\e9c8';
}
.pika-next {
  float: right;
}
.pika-next:after {
  content: '\e9cb';
}
.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  table-layout: fixed;
}
.pika-table th,
.pika-table td {
  position: relative;
}
.pika-table th {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
}
.pika-table abbr {
  border-bottom: 0;
  cursor: default;
}
.pika-button {
  display: block;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 7px;
  text-align: center;
  border-radius: 3px;
  background-color: transparent;
}
.pika-button:hover {
  background-color: #f5f5f5;
}
.is-today .pika-button {
  background-color: #f5f5f5;
}
.is-selected .pika-button {
  color: #fff;
  background-color: #26A69A;
}
.is-today .pika-button:before,
.is-selected .pika-button:before,
.is-today.is-selected .pika-button:before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 6px solid #fff;
  border-left: 6px solid transparent;
}
.is-today .pika-button:before {
  border-top-color: #EF5350;
}
.is-startrange .pika-button,
.is-endrange .pika-button {
  color: #fff;
  background: #26A69A;
}
.is-inrange .pika-button {
  background: #f5f5f5;
}
.is-disabled .pika-button {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.pika-select {
  display: inline-block;
}
.pika-week {
  font-size: 11px;
  color: #999999;
}
/* ------------------------------------------------------------------------------
 *
 *  # Datatables library
 *
 *  Add advanced interaction controls to any HTML table
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.dataTable {
  margin: 0;
  max-width: none;
  border-collapse: separate;
}
.dataTable thead th,
.dataTable thead td {
  outline: 0;
  position: relative;
}
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting {
  cursor: pointer;
}
.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  padding-right: 40px;
}
.dataTable thead .sorting:before,
.dataTable thead .sorting:after,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_desc_disabled:after {
  content: '';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 12px;
  margin-top: -6px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable thead .sorting:before {
  content: '\e9c1';
  margin-top: -2px;
  color: #999999;
}
.dataTable thead .sorting:after {
  content: '\e9c2';
  margin-top: -10px;
  color: #999999;
}
.dataTable thead .sorting_asc:after {
  content: '\e9c2';
}
.dataTable thead .sorting_desc:after {
  content: '\e9c1';
}
.dataTable thead .sorting_asc_disabled:after {
  content: '\e9c2';
  color: #ccc;
}
.dataTable thead .sorting_desc_disabled:after {
  content: '\e9c1';
  color: #ccc;
}
.dataTable tbody > tr:first-child > th,
.dataTable tbody > tr:first-child > td {
  border-top: 0;
}
.dataTable tbody + thead > tr:first-child > th,
.dataTable tbody + tfoot + thead > tr:first-child > th,
.dataTable tbody + thead > tr:first-child > td,
.dataTable tbody + tfoot + thead > tr:first-child > td {
  border-top: 0;
}
.dataTable .dataTables_empty {
  text-align: center;
}
.dataTable.table-bordered {
  border-collapse: collapse;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
.dataTables_wrapper .table-bordered {
  border-top: 0;
}
.dataTables_wrapper + .panel-body {
  border-top: 1px solid #ddd;
}
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}
.datatable-header,
.datatable-footer {
  padding: 20px 20px 0 20px;
}
.datatable-header:after,
.datatable-footer:after {
  content: "";
  display: table;
  clear: both;
}
.datatable-header > div:first-child,
.datatable-footer > div:first-child {
  margin-left: 0;
}
.panel-body .datatable-header,
.panel-body .datatable-footer {
  padding-left: 0;
  padding-right: 0;
}
.datatable-header {
  border-bottom: 1px solid #ddd;
}
.datatable-footer {
  border-top: 1px solid #bbb;
}
.dataTables_length {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px;
}
.dataTables_length > label {
  margin-bottom: 0;
}
.dataTables_length > label > span:first-child {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.length-left .dataTables_length {
  float: left;
}
.dataTables_length .select2-container {
  width: auto;
}
.dataTables_length .select2-choice {
  min-width: 60px;
}
.dataTables_filter {
  position: relative;
  display: block;
  float: left;
  margin: 0 0 20px 20px;
}
.dataTables_filter > label {
  margin-bottom: 0;
  position: relative;
}
.dataTables_filter > label:after {
  content: "\e98e";
  font-family: 'icomoon';
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -6px;
  color: #999999;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTables_filter > label > span {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.dataTables_filter input {
  outline: 0;
  width: 200px;
  height: 36px;
  padding: 7px 12px;
  padding-right: 36px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.filter-right .dataTables_filter {
  float: right;
}
.dataTables_info {
  float: left;
  padding: 8px 0;
  margin-bottom: 20px;
}
.info-right .dataTables_info {
  float: right;
}
.dataTables_paginate {
  float: right;
  text-align: right;
  margin: 0 0 20px 20px;
}
.dataTables_paginate .paginate_button {
  display: inline-block;
  padding: 7px;
  min-width: 36px;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
  border: 1px solid transparent;
  border-radius: 3px;
}
.dataTables_paginate .paginate_button:first-child {
  margin-left: 0;
}
.dataTables_paginate .paginate_button:hover,
.dataTables_paginate .paginate_button:focus {
  background-color: #f5f5f5;
}
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #bbb;
}
.dataTables_paginate.paging_simple .paginate_button {
  padding-left: 12px;
  padding-right: 12px;
}
.paginate-left .dataTables_paginate {
  float: left;
}
.paging_simple .paginate_button:hover,
.paging_simple .paginate_button:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_scroll {
  clear: both;
}
.dataTables_scroll .dataTables_scrollHead table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollHead th,
.dataTables_scroll .dataTables_scrollHead td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}
.dataTables_scroll .dataTables_scrollBody table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:before,
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:after {
  content: none;
}
.dataTables_scroll .dataTables_scrollBody table tbody tr:first-child > td {
  border-top: 0;
}
.dataTables_scroll .dataTables_scrollBody th,
.dataTables_scroll .dataTables_scrollBody td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing,
.dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.panel-body + .dataTables_wrapper,
.panel-body + * > .dataTables_wrapper {
  border-top: 1px solid #ddd;
}
.panel-body > .dataTables_wrapper .datatable-footer {
  border-top: 0;
}
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_length,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_filter,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_info,
.panel-body > .dataTables_wrapper .datatable-footer .dataTables_paginate {
  margin-bottom: 0;
}
.panel-flat > .panel-heading + .dataTables_wrapper > .datatable-header {
  padding-top: 0;
}
.panel > .dataTables_wrapper .table-bordered {
  border: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:first-child {
  border-left: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:last-child {
  border-right: 0;
}
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > th,
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > td {
  border-bottom: 0;
}
.datatable-scroll-lg,
.datatable-scroll,
.datatable-scroll-sm {
  min-height: .01%;
}
.datatable-scroll-wrap {
  width: 100%;
  min-height: .01%;
  overflow-x: auto;
}
@media (max-width: 768px) {
  .datatable-scroll-sm {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-sm th,
  .datatable-scroll-sm td {
    white-space: nowrap;
  }
}
@media (max-width: 1024px) {
  .datatable-scroll {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll th,
  .datatable-scroll td {
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .datatable-scroll-lg {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-lg th,
  .datatable-scroll-lg td {
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .dataTables_info,
  .dataTables_paginate,
  .dataTables_length,
  .dataTables_filter,
  .DTTT_container,
  .ColVis {
    float: none!important;
    text-align: center;
    margin-left: 0;
  }
  .dataTables_info,
  .dataTables_paginate {
    margin-top: 0;
  }
  .datatable-header {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Columns reorder
*
*  Easily modify the column order of a table through drop-and-drag of column headers
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTCR_clonedTable {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 202;
  cursor: move;
}
.DTCR_clonedTable th,
.DTCR_clonedTable td {
  border: 1px solid #ddd !important;
}
.DTCR_pointer {
  width: 1px;
  background-color: #2196F3;
  z-index: 201;
}
/* ------------------------------------------------------------------------------
*
*  # Row Reorder extension
*
*  RowReorder adds the ability for rows in a DataTable to be reordered through
*  user interaction with the table.
*
*  Version: 1.0
*  Latest update: Nov 10, 2015
*
* ---------------------------------------------------------------------------- */
.dt-rowReorder-float {
  position: absolute!important;
  table-layout: static;
  outline: 1px dashed #777;
  outline-offset: -1px;
  background-color: #fff;
  z-index: 1030;
  cursor: move;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.dt-rowReorder-moving {
  outline: 1px solid #777;
  outline-offset: -1px;
}
.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}
.dataTable td.reorder {
  text-align: center;
  cursor: move;
}
/* ------------------------------------------------------------------------------
*
*  # Fixed columns
*
*  Extension that "freezes" in place the left most columns in a scrolling DataTable
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTFC_Cloned {
  background-color: #fff;
  border-bottom: 0;
}
.DTFC_LeftWrapper .DTFC_Cloned.table {
  border-right: 1px solid #ddd;
}
.DTFC_RightWrapper .DTFC_Cloned.table {
  border-left: 1px solid #ddd;
}
.DTFC_LeftBodyWrapper .DTFC_Cloned thead th:before,
.DTFC_RightBodyWrapper .DTFC_Cloned thead th:before,
.DTFC_LeftBodyWrapper .DTFC_Cloned thead th:after,
.DTFC_RightBodyWrapper .DTFC_Cloned thead th:after {
  content: none;
}
.DTFC_LeftBodyWrapper .DTFC_Cloned tbody > tr:first-child > td,
.DTFC_RightBodyWrapper .DTFC_Cloned tbody > tr:first-child > td,
.DTFC_LeftBodyWrapper .DTFC_Cloned tbody > tr:first-child > th,
.DTFC_RightBodyWrapper .DTFC_Cloned tbody > tr:first-child > th {
  border-top: 0;
}
.DTFC_Blocker {
  background-color: white;
}
@media (max-width: 768px) {
  .DTFC_LeftWrapper,
  .DTFC_RightWrapper {
    display: none;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Fixed Header extension
*
*  This extension lets your users quickly determine what each column refers to
*  rather than needing to scroll back to the top of the table.
*
*  Version: 1.0
*  Latest update: Nov 9, 2015
*
* ---------------------------------------------------------------------------- */
.fixedHeader-floating,
.fixedHeader-locked {
  background-color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}
.fixedHeader-floating {
  position: fixed;
}
.fixedHeader-floating + .fixedHeader-floating,
.fixedHeader-locked + .fixedHeader-floating {
  border-top: 0!important;
}
@media (max-width: 768px) {
  .fixedHeader-floating {
    display: none;
  }
}
.fixedHeader-locked {
  position: absolute;
}
@media (max-width: 768px) {
  .fixedHeader-locked {
    display: none;
  }
}
@media print {
  table.fixedHeader-floating {
    display: none;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Autofill extension
*
*  Spreadsheets such as Excel and Google Docs have a very handy data duplication
*  option of an auto fill tool
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.dt-autofill-handle {
  position: absolute;
  height: 8px;
  width: 8px;
  z-index: 102;
  border: 1px solid #2196F3;
  background: #2196F3;
}
.dt-autofill-select {
  position: absolute;
  z-index: 1001;
  background-color: #2196F3;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.75) 5px, rgba(255, 255, 255, 0.75) 10px);
}
.dt-autofill-select.top,
.dt-autofill-select.bottom {
  height: 1px;
}
.dt-autofill-select.left,
.dt-autofill-select.right {
  width: 1px;
}
.dt-autofill-list {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid transparent;
  z-index: 1050;
  padding: 7px 0;
}
.dt-autofill-list ul {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
.dt-autofill-list ul li {
  display: table-row;
}
.dt-autofill-list ul li:hover {
  background-color: #f5f5f5;
}
.dt-autofill-list .dt-autofill-question {
  display: table-cell;
  padding: 7px 15px;
}
.dt-autofill-list .dt-autofill-question input[type=number] {
  padding: 6px;
  width: 30px;
  margin: -2px 0;
}
.dt-autofill-list .dt-autofill-button {
  display: table-cell;
  padding: 7px 15px;
  text-align: right;
}
.dt-autofill-list .dt-autofill-button .btn {
  padding: 3px;
  background-color: #2196F3;
  color: #fff;
  font-size: 0;
}
.dt-autofill-list .dt-autofill-button .btn:after {
  content: '\e9c3';
  font-family: 'Icomoon';
  display: block;
  font-size: 16px;
  width: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dt-autofill-list .dt-autofill-button .btn:hover {
  opacity: 0.85;
  filter: alpha(opacity=85);
}
.dt-autofill-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1040;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
/* ------------------------------------------------------------------------------
*
*  # Select extension
*
*  Spreadsheets such as Excel and Google Docs have a very handy data duplication
*  option of an auto fill tool
*
*  Version: 1.1
*  Latest update: Dec 9, 2015
*
* ---------------------------------------------------------------------------- */
.dataTable tbody > tr.selected,
.dataTable tbody > tr > .selected {
  background-color: #E8F5E9;
}
.dataTables_wrapper .select-info,
.dataTables_wrapper .select-item {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .dataTables_wrapper .select-info,
  .dataTables_wrapper .select-item {
    margin-left: 0;
    display: block;
  }
}
.dataTable tbody .select-checkbox {
  position: relative;
}
.dataTable tbody .select-checkbox:before,
.dataTable tbody .select-checkbox:after {
  display: inline-block;
  color: #455A64;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
}
.dataTable tbody .select-checkbox:before {
  content: '';
  background-color: #fff;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  margin-left: -9px;
  border: 2px solid #607D8B;
  border-radius: 2px;
}
.dataTable tbody .selected .select-checkbox:after {
  content: "\e600";
  font-family: 'icomoon';
  font-size: 16px;
  line-height: 1;
  margin-top: -8px;
  margin-left: -8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ------------------------------------------------------------------------------
*
*  # Buttons extension
*
*  The Buttons extension for DataTables provides a common set of options, API
*  methods and styling to display buttons that will interact with a DataTable
*
*  Version: 1.2
*  Latest update: Jul 5, 2016
*
* ---------------------------------------------------------------------------- */
.dt-buttons-full .dt-buttons {
  text-align: center;
  float: none;
  display: block;
  margin: 0;
  border-bottom: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #fcfcfc;
}
.dt-buttons-full .dt-buttons > .btn {
  margin-bottom: 10px;
  float: none;
}
.dt-buttons {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px;
}
.dt-buttons-left .dt-buttons {
  float: left;
}
.dt-buttons > .dt-button {
  display: inline-block;
}
.dt-buttons > .btn {
  border-radius: 0;
}
.dt-buttons > .btn:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.dt-buttons > .btn:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.dt-buttons > .btn + .btn {
  margin-left: -1px;
}
@media screen and (max-width: 767px) {
  .dt-buttons {
    float: none;
    text-align: center;
    display: block;
  }
  .dt-buttons .btn {
    float: none;
  }
}
.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  text-align: center;
  z-index: 1050;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.dt-button-info h2 {
  margin-top: 0;
  line-height: 1.5384616;
  font-size: 17px;
}
.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #333;
  z-index: 999;
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  display: block;
  z-index: 1000;
  padding: 7px 0;
  overflow: hidden;
  min-width: 180px;
  border-radius: 3px;
  -webkit-column-gap: 2px;
  -moz-column-gap: 2px;
  -ms-column-gap: 2px;
  -o-column-gap: 2px;
  column-gap: 2px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.dt-button-collection > .dt-button {
  padding: 8px 15px;
  color: #333333;
  display: block;
  outline: 0;
}
.dt-button-collection > .dt-button + .dt-button {
  margin-top: 1px;
}
.dt-button-collection > .dt-button:hover,
.dt-button-collection > .dt-button:focus {
  color: #333333;
  background-color: #f5f5f5;
}
.dt-button-collection > .dt-button.active {
  color: #fff;
  background-color: #2196F3;
}
.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  padding-left: 5px;
  padding-right: 5px;
}
.dt-button-collection.fixed.two-column {
  margin-left: -150px;
}
.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}
.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}
.dt-button-collection > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
.dt-button-collection.two-column {
  width: 300px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}
.dt-button-collection.three-column {
  width: 450px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}
.dt-button-collection.four-column {
  width: 600px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}
/* ------------------------------------------------------------------------------
*
*  # Key Table extension
*
*  KeyTable provides Excel like cell navigation on any table. Events (focus, blur,
*  action etc) can be assigned to individual cells, columns, rows or all cells.
*
*  Version: 1.0
*  Latest update: Nov 10, 2015
*
* ---------------------------------------------------------------------------- */
.dataTable th.focus,
.dataTable td.focus {
  outline: 2px solid #2196F3;
  outline-offset: -1px;
}
.dataTable th.focus-success,
.dataTable td.focus-success {
  background-color: #E8F5E9;
  outline-color: #4CAF50;
}
.dataTable th.focus-info,
.dataTable td.focus-info {
  background-color: #E3F2FD;
  outline-color: #2196F3;
}
.dataTable th.focus-warning,
.dataTable td.focus-warning {
  background-color: #FBE9E7;
  outline-color: #FF5722;
}
.dataTable th.focus-danger,
.dataTable td.focus-danger {
  background-color: #FFEBEE;
  outline-color: #F44336;
}
/* ------------------------------------------------------------------------------
*
*  # Datatables Scroller
*
*  Drawing the rows required for the current display only, for fast operation
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTS tbody th,
.DTS tbody td {
  white-space: nowrap;
}
.DTS .DTS_Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 20px;
  margin-top: -20px;
  margin-left: -100px;
  z-index: 1;
  border: 1px solid #999;
  padding: 20px 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
}
.DTS .dataTables_scrollHead,
.DTS .dataTables_scrollFoot {
  background-color: #fff;
}
.DTS .dataTables_scrollBody {
  z-index: 2;
}
/* ------------------------------------------------------------------------------
*
*  # Responsive extension
*
*  Optimise the table's layout for different screen sizes through the dynamic 
*  insertion and removal of columns from the table
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.dtr-inline.collapsed tbody tr td:before,
.dtr-inline.collapsed tbody tr th:before {
  margin-right: 10px;
}
.dtr-inline.collapsed tbody tr td:first-child,
.dtr-inline.collapsed tbody tr th:first-child {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.dtr-inline.collapsed tbody tr td:first-child.dataTables_empty:before,
.dtr-inline.collapsed tbody tr th:first-child.dataTables_empty:before {
  display: none;
}
.dtr-column tbody td.control,
.dtr-column tbody th.control {
  position: relative;
  cursor: pointer;
}
.dtr-inline.collapsed tbody tr td:first-child:before,
.dtr-inline.collapsed tbody tr th:first-child:before,
.dtr-column tbody tr td.control:before,
.dtr-column tbody tr th.control:before {
  content: '\e9e4';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  width: 16px;
  line-height: 1;
  position: relative;
  top: -1px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before,
.dataTable.dtr-column tbody tr.parent td.control:before,
.dataTable.dtr-column tbody tr.parent th.control:before {
  content: '\e9e7';
}
.dtr-inline.collapsed tbody tr.child td:before {
  display: none;
}
.dataTable tr.child:hover {
  background-color: transparent;
}
.dataTable tr.child .dtr-title {
  display: table-cell;
  font-weight: 500;
  padding-right: 40px;
}
.dataTable tr.child .dtr-data {
  display: table-cell;
  padding: 8px 0;
}
.dataTable tr td.child {
  white-space: normal;
  position: relative;
}
.dataTable tr td.child > ul {
  display: table;
  table-layout: fixed;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.dataTable tr td.child > ul > li {
  display: table-row;
}
@media (max-width: 480px) {
  .dataTable tr td.child > ul > li .dropdown,
  .dataTable tr td.child > ul > li .dropup,
  .dataTable tr td.child > ul > li .btn-group {
    position: static;
  }
  .dataTable tr td.child > ul > li .dropdown-menu {
    left: 0;
    right: 0;
    border-radius: 0;
    border-width: 1px 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Google maps base
 *
 *  Styles related to Google Maps: containers, sizing etc.
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.map-wrapper {
  width: 100%;
  height: 400px;
}
.sidebar .map-wrapper {
  height: 200px;
  border: 1px solid #ddd;
}
/* ------------------------------------------------------------------------------
 *
 *  # Google Maps overrides
 *
 *  Override Google Maps default styles
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.map-container {
  height: 500px;
}
.gm-style {
  font-size: 13px;
}
.gm-style .gm-style-iw {
  font-size: 13px;
  font-weight: 400;
}
/* ------------------------------------------------------------------------------
 *
 *  # Vector maps
 *
 *  Vector map container, tooltip and zooming
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.vector-map-container {
  height: 500px;
}
.jvectormap-label {
  position: absolute;
  display: none;
  border-radius: 2px;
  background-color: #333;
  color: #fff;
  padding: 7px 12px;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 2px;
  padding: 8px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  line-height: 10px;
  background-color: #37474F;
  text-align: center;
}
.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
  background-color: #455A64;
}
.jvectormap-zoomin {
  top: 10px;
}
.jvectormap-zoomout {
  top: 40px;
}
/* ------------------------------------------------------------------------------
*
*  # Chart styling
*
*  Charts base - container and sizing setup
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.chart-container {
  position: relative;
  width: 100%;
}
.chart-container.has-scroll {
  overflow-x: scroll;
  overflow-y: visible;
  max-width: 100%;
}
@media (max-width: 768px) {
  .chart-container {
    overflow-x: scroll;
    overflow-y: visible;
    max-width: 100%;
  }
}
.chart {
  position: relative;
  display: block;
  width: 100%;
  direction: ltr;
}
.chart.has-minimum-width {
  min-width: 600px;
}
.has-fixed-height {
  height: 400px;
}
.chart-pie {
  width: 100%;
  height: 400px;
  min-width: 500px;
}
/* ------------------------------------------------------------------------------
*
*  # C3 charts
*
*  Styles for C3.js visualization library
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.c3 svg {
  font-size: 12px;
}
.c3 path,
.c3 line {
  fill: none;
  stroke: #999999;
}
.c3 path.domain {
  shape-rendering: crispEdges;
}
.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}
.c3-chart-arc path {
  stroke: #fff;
}
.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}
.c3-grid line {
  stroke: #aaa;
}
.c3-grid text {
  fill: #aaa;
}
.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 3 3;
}
.c3-text {
  font-weight: 500;
}
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}
.c3-line {
  stroke-width: 2px;
}
.c3-area {
  stroke-width: 0;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.c3-circle._expanded_ {
  stroke-width: 1.5px;
  stroke: #fff;
}
.c3-selected-circle {
  fill: #fff;
  stroke-width: 2px;
}
.c3-bar {
  stroke-width: 0;
}
.c3-bar._expanded_ {
  fill-opacity: 0.75;
}
.c3-chart-arcs-title {
  font-size: 1.3em;
}
.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: none;
}
.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #333333;
  font-size: 15px;
}
.c3-chart-arcs .c3-chart-arcs-gauge-max,
.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #999999;
}
.c3-chart-arc .c3-gauge-value {
  fill: #333333;
  font-size: 28px;
}
.c3-target.c3-focused {
  opacity: 1;
  filter: alpha(opacity=100);
}
.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}
.c3-target.c3-defocused {
  opacity: 0.3 !important;
  filter: alpha(opacity=30) !important;
}
.c3-region {
  fill: #546E7A;
  fill-opacity: .1;
}
.c3-brush .extent {
  fill-opacity: .1;
}
.c3-legend-item {
  font-size: 12px;
}
.c3-legend-background {
  fill: #fff;
  stroke: lightgray;
  stroke-width: 1;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.c3-tooltip th {
  background-color: #333;
  padding: 4px 10px;
  text-align: center;
  color: #fff;
  border: 1px solid #333;
  font-weight: 500;
}
.c3-tooltip td {
  font-size: 12px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.c3-tooltip td.value {
  text-align: right;
}
/* ------------------------------------------------------------------------------
*
*  # D3.js library
*
*  Basic styles for D3.js visualization library
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.d3-axis path {
  fill: none;
  stroke: #999999;
  shape-rendering: crispEdges;
}
.d3-axis line {
  stroke: #eeeeee;
  shape-rendering: crispEdges;
}
.d3-axis .tick,
.d3-axis .tick text {
  font-size: 12px;
  fill: #333333;
}
.d3-axis-solid path,
.d3-axis-solid line {
  stroke: #ccc;
}
.d3-axis-solid .tick {
  fill: #999999;
}
.d3-axis-solid .tick text {
  fill: #999999;
}
.d3-axis-strong path,
.d3-axis-strong line {
  stroke: #ccc;
}
.d3-axis-strong .tick {
  fill: #333333;
}
.d3-axis-strong .tick text {
  fill: #333333;
}
.d3-axis-transparent path {
  stroke: none;
}
.d3-axis-transparent line {
  stroke: #e5e5e5;
}
.d3-axis-transparent .tick {
  fill: #999999;
}
.d3-axis-transparent .tick text {
  fill: #999999;
}
.d3-tip {
  position: absolute;
  padding: 8px 15px;
  border-radius: 3px;
  background-color: #333;
  color: #fff;
  margin-bottom: -3px;
  font-size: 12px;
  z-index: 1070;
}
.d3-tip .d3-tip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.d3-tip.n .d3-tip-arrow {
  bottom: -4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #333;
}
.d3-tip.e .d3-tip-arrow {
  top: 50%;
  left: -4px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #333;
}
.d3-tip.w .d3-tip-arrow {
  top: 50%;
  right: -4px;
  margin-top: -4px;
  border-width: 4px 0 4px 4px;
  border-left-color: #333;
}
.d3-tip.s .d3-tip-arrow {
  top: 0;
  left: 50%;
  margin-left: -4px;
  border-width: 0 4px 4px;
  border-bottom-color: #333;
}
.venntooltip {
  position: absolute;
  text-align: center;
  min-width: 60px;
  white-space: nowrap;
  background-color: #333;
  color: #fff;
  padding: 7px 12px;
  border-radius: 3px;
  display: none;
}
.d3-line {
  fill: none;
}
.d3-line-thin {
  stroke-width: 1px;
}
.d3-line-medium {
  stroke-width: 1.5px;
}
.d3-line-strong {
  stroke-width: 2px;
}
.d3-line-circle {
  fill: none;
  cursor: pointer;
}
.d3-line-circle-thin {
  stroke-width: 1px;
}
.d3-line-circle-medium {
  stroke-width: 1.5px;
}
.d3-line-circle-strong {
  stroke-width: 2px;
}
.d3-grid .tick line {
  stroke-width: 1px;
  stroke: #e5e5e5;
  shape-rendering: crispEdges;
}
.d3-grid .tick text {
  fill: #333333;
  stroke: none;
}
.d3-grid-dashed .tick {
  stroke-dasharray: 4,2;
  stroke-width: 1px;
  stroke: #e5e5e5;
  shape-rendering: crispEdges;
}
.d3-grid-dashed path {
  stroke-width: 0;
}
.d3-crosshair-overlay {
  fill: none;
  pointer-events: all;
}
.bullet-tick line {
  stroke: #999999;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}
.bullet-tick text {
  fill: #999999;
  font-size: 12px;
}
.bullet-marker {
  stroke-width: 2px;
  shape-rendering: crispEdges;
}
.bullet-title {
  font-size: 13px;
  font-weight: 500;
}
.bullet-subtitle {
  fill: #999999;
}
.bullet-1 .bullet-range-1 {
  fill: rgba(96, 125, 139, 0.8);
}
.bullet-1 .bullet-range-2 {
  fill: rgba(96, 125, 139, 0.4);
}
.bullet-1 .bullet-range-3 {
  fill: #607D8B;
}
.bullet-1 .bullet-measure-1 {
  fill: rgba(84, 110, 122, 0.9);
}
.bullet-1 .bullet-measure-2 {
  fill: #fff;
}
.bullet-1 .bullet-marker {
  stroke: #37474F;
}
.bullet-2 .bullet-range-1 {
  fill: rgba(255, 112, 67, 0.6);
}
.bullet-2 .bullet-range-2 {
  fill: rgba(255, 112, 67, 0.3);
}
.bullet-2 .bullet-range-3 {
  fill: #FF7043;
}
.bullet-2 .bullet-measure-1 {
  fill: rgba(244, 81, 30, 0.9);
}
.bullet-2 .bullet-measure-2 {
  fill: #fff;
}
.bullet-2 .bullet-marker {
  stroke: #D84315;
}
.bullet-3 .bullet-range-1 {
  fill: rgba(102, 187, 106, 0.7);
}
.bullet-3 .bullet-range-2 {
  fill: rgba(102, 187, 106, 0.35);
}
.bullet-3 .bullet-range-3 {
  fill: #66BB6A;
}
.bullet-3 .bullet-measure-1 {
  fill: rgba(67, 160, 71, 0.9);
}
.bullet-3 .bullet-measure-2 {
  fill: #fff;
}
.bullet-3 .bullet-marker {
  stroke: #2E7D32;
}
.counter-icon {
  font-size: 32px;
  position: absolute;
  left: 50%;
  margin-left: -16px;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sparklines library
 *
 *  Generate sparklines (small inline charts) directly in the browser
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.jqsfield {
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif !important;
}
.jqstooltip {
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif !important;
  padding: 7px !important;
  line-height: 1.6666667 !important;
  border: 0 !important;
  border-radius: 3px !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
/* ------------------------------------------------------------------------------
 *
 *  # Task manager
 *
 *  Styles related to task manager - detailed task, list and grid
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.timer-weekdays {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.timer-weekdays > li {
  display: inline-block;
  margin: 2px 0;
}
.timer-weekdays > li > a {
  display: block;
  margin: 0 1px;
}
.timer-weekdays .label-default {
  background-color: #ccc;
  border-color: #ccc;
}
.timer-weekdays .label-default:hover,
.timer-weekdays .label-default:focus {
  border-color: #999999;
}
.timer {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.timer > li {
  font-size: 46px;
  text-align: center;
  display: inline-block;
  color: #555;
  font-weight: 300;
}
.timer > li.dots {
  font-size: 25px;
  vertical-align: top;
  padding: 16.66666667px 0;
}
.timer > li > span {
  display: block;
  font-size: 13px;
}
.task-details {
  float: right;
  margin: 0;
}
@media (max-width: 1024px) {
  .task-details {
    float: none;
    margin-top: 15px;
    list-style: none;
    padding: 0;
  }
  .task-details > li {
    float: left;
    position: relative;
    padding-right: 20px;
    margin-top: 0;
  }
  .task-details > li:before {
    content: '•';
    position: absolute;
    right: 8px;
    color: #999;
  }
  .task-details > li:last-child:before {
    content: none;
  }
}
@media (max-width: 768px) {
  .task-details > li {
    float: none;
  }
  .task-details > li:before {
    content: none;
  }
  .task-details > li + li {
    margin-top: 10px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Invoice template
 *
 *  General styles for invoice, mainly responsive setup
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
@media (min-width: 769px) {
  .invoice-details,
  .invoice-payment-details > li span {
    float: right;
    text-align: right;
  }
}
.invoice-grid ul,
.invoice-grid ol {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .invoice-grid .text-right {
    text-align: inherit;
  }
  .invoice-grid [class*=col-] + [class*=col-] {
    margin-top: 20px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # User list
 *
 *  Styles for user lists - layouts, contact details etc.
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.contact-details {
  padding: 15px;
  padding-left: 30px;
  background-color: #fcfcfc;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.media-list-bordered .contact-details {
  border-bottom: 0;
}
.panel-body > .media-list .contact-details {
  padding-left: 15px;
  background-color: transparent;
  margin-top: 15px;
}
.panel-body > .media-list-bordered .contact-details {
  padding-bottom: 0;
}
.contact-details > ul,
.contact-details > ul > li:first-child {
  margin: 0;
}
/* ------------------------------------------------------------------------------
 *
 *  # User profile
 *
 *  Styles for all user profile layouts
 *
 *  Version: 1.1
 *  Latest update: Mar 6, 2016
 *
 * ---------------------------------------------------------------------------- */
.profile-cover {
  position: relative;
}
.profile-cover + .navbar {
  margin-bottom: 40px;
}
.profile-cover .media {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.profile-cover .media .btn-default {
  border-color: #fcfcfc;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.profile-cover .media-body,
.profile-cover small {
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}
@media (max-width: 768px) {
  .profile-cover .media {
    text-align: center;
    margin-top: 0;
    bottom: auto;
    top: 40px;
  }
  .profile-cover .media-left,
  .profile-cover .media-right {
    padding: 0;
    display: block;
    overflow-x: auto;
  }
  .profile-cover .media-body {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
  .profile-cover .media-body > h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
.profile-cover-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
@media (max-width: 768px) {
  .profile-cover-img {
    height: 320px;
  }
}
.media .profile-thumb img {
  width: 100px;
  height: 100px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
/* ------------------------------------------------------------------------------
 *
 *  # Login and related forms
 *
 *  Styles related to user login - logins, registration, password revovery, unlock etc.
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.login-cover {
  background: url('../images/login_cover.jpg') no-repeat;
  background-size: cover;
}
.login-container .page-container {
  padding-top: 40px;
  position: static;
}
.login-container .page-container .login-form {
  width: 320px;
}
.login-container .page-container .login-form .thumb {
  margin: 0 auto 20px auto;
}
.login-container .page-container .login-form,
.login-container .page-container .registration-form {
  margin: 0 auto 20px auto;
}
@media (max-width: 480px) {
  .login-container .page-container .login-form,
  .login-container .page-container .registration-form {
    width: 100%;
  }
}
.login-container .page-container .nav-tabs.nav-justified {
  margin-bottom: 0;
}
.login-container .page-container .nav-tabs.nav-justified > li > a {
  border-top: 0!important;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f5f5f5;
}
.login-container .page-container .nav-tabs.nav-justified > li:first-child > a {
  border-left: 0;
  border-radius: 3px 0 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li:last-child > a {
  border-right: 0;
  border-radius: 0 3px 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li.active > a {
  background-color: transparent;
}
@media (max-width: 768px) {
  .login-container .page-container .nav-tabs.nav-justified {
    padding: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .login-container .page-container .nav-tabs.nav-justified:before {
    content: none;
  }
  .login-container .page-container .nav-tabs.nav-justified > li > a {
    border-width: 0!important;
  }
}
.login-container .footer {
  left: 0;
  right: 0;
}
@media (max-width: 768px) {
  .login-options,
  .login-options .text-right {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Timeline
 *
 *  Styles for timeline in 3 layouts: left, right and centered
 *
 *  Version: 1.1
 *  Latest update: Dec 11, 2015
 *
 * ---------------------------------------------------------------------------- */
.timeline {
  position: relative;
  height: 100%;
}
.timeline:before,
.timeline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  z-index: 1;
}
.timeline:after {
  top: auto;
  bottom: 0;
}
.timeline-container {
  position: relative;
  padding-top: 10px;
  margin-top: -10px;
  padding-bottom: 1px;
}
.timeline-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  background-color: #ccc;
  height: 100%;
  width: 2px;
  z-index: -1;
}
.timeline-row {
  position: relative;
}
.timeline-content {
  margin-bottom: 20px;
}
.timeline-date {
  text-align: center;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.timeline-date:before,
.timeline-date:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  z-index: 1;
}
.timeline-date:after {
  top: auto;
  bottom: 0;
}
.panel .timeline-date {
  background-color: #fff;
}
.timeline-time {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
  position: relative;
  margin-bottom: 20px;
}
.timeline-time:before,
.timeline-time:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  height: 4px;
  width: 4px;
  border-radius: 50%;
}
.timeline-time:after {
  top: auto;
  bottom: 0;
}
.panel .timeline-time {
  background-color: #fff;
}
.timeline-time span {
  display: block;
  color: #999999;
  font-size: 12px;
}
.timeline-time span:before {
  content: '•';
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 1px;
}
@media (min-width: 1025px) {
  .timeline-time:before,
  .timeline-time:after {
    content: none;
  }
}
.timeline-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
  background-color: #fff;
  border: 4px solid #f5f5f5;
}
.panel .timeline-icon {
  border-color: #fff;
}
.timeline-icon div {
  border-radius: 50%;
  height: 100%;
  -webkit-box-shadow: 0 0 0 2px #ccc inset;
  box-shadow: 0 0 0 2px #ccc inset;
}
.timeline-icon div[class*=bg-] {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.timeline-icon div > i {
  display: block;
  line-height: 40px;
  top: 0;
}
.timeline-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
@media (min-width: 1025px) {
  .timeline-center .timeline-row {
    margin-right: 50%;
    padding-right: 44px;
  }
  .timeline-center .post-even {
    margin-right: 0;
    padding-right: 0;
    margin-left: 50%;
    padding-left: 44px;
  }
  .timeline-center .post-full {
    margin: 0;
    padding: 0;
  }
  .timeline-center .post-full .timeline-content {
    position: relative;
  }
  .timeline-center .post-full .timeline-content:before,
  .timeline-center .post-full .timeline-content:after {
    content: '';
    border-style: solid;
    border-color: #f5f5f5;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }
  .timeline-center .post-full .timeline-content:before {
    border-width: 4px 0 0 0;
    top: -5px;
  }
  .timeline-center .post-full .timeline-content:after {
    border-width: 0 0 4px 0;
    bottom: -5px;
  }
  .timeline-left .timeline-container:after,
  .timeline-left:before,
  .timeline-left:after,
  .timeline-left .timeline-date:before,
  .timeline-left .timeline-date:after {
    left: 24px;
  }
  .timeline-left .timeline-row,
  .timeline-left .timeline-date {
    padding-left: 68px;
  }
  .timeline-right .timeline-container:after,
  .timeline-right:before,
  .timeline-right:after,
  .timeline-right .timeline-date:before,
  .timeline-right .timeline-date:after {
    left: auto;
    right: 24px;
    margin-left: 0;
    margin-right: -2px;
  }
  .timeline-right .timeline-container:after {
    margin-right: -1px;
  }
  .timeline-right .timeline-row,
  .timeline-right .timeline-date {
    padding-right: 68px;
  }
  .timeline-left .timeline-date,
  .timeline-right .timeline-date {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .timeline-icon {
    position: absolute;
    top: 10px;
  }
  .timeline-icon:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    height: 2px;
    width: 16px;
    background-color: #ccc;
    z-index: 1;
  }
  .timeline-left .timeline-icon {
    left: 0;
  }
  .timeline-left .timeline-icon:after {
    left: 100%;
    margin-left: 4px;
  }
  .timeline-right .timeline-icon {
    right: 0;
  }
  .timeline-right .timeline-icon:after {
    right: 100%;
    margin-right: 4px;
  }
  .timeline-center .timeline-row:not(.post-full) .timeline-icon {
    left: 100%;
    margin-left: -24px;
  }
  .timeline-center .timeline-row.post-even .timeline-icon {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: -24px;
  }
  .timeline-center .timeline-icon:after {
    right: 100%;
    margin-right: 4px;
  }
  .timeline-center .post-even .timeline-icon:after {
    left: 100%;
    margin-left: 4px;
  }
  .timeline-center .post-full .timeline-icon {
    position: static;
  }
  .timeline-center .post-full .timeline-icon:after {
    content: none;
  }
  .timeline-time {
    padding: 0;
    text-align: inherit;
    background-color: transparent;
  }
  .timeline-time:before {
    content: none;
  }
  .timeline-left .timeline-time,
  .timeline-right .timeline-time {
    padding-top: 10px;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  .timeline-right .timeline-time {
    text-align: right;
    padding-left: 0;
    padding-right: 10px;
  }
  .timeline-center .timeline-time,
  .timeline-center .post-full .timeline-time {
    position: absolute;
    left: 100%;
    top: 15px;
    width: 100%;
    padding-left: 44px;
  }
  .timeline-center .post-even .timeline-time,
  .timeline-center .post-full .timeline-time {
    left: auto;
    right: 100%;
    padding-left: 0;
    padding-right: 44px;
    text-align: right;
  }
  .timeline-center .post-full .timeline-time {
    right: 50%;
    top: 5px;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Chat layouts
 *
 *  Conversation chat styles - layouts, chat elements, colors, options
 *
 *  Version: 1.1
 *  Latest update: Oct 20, 2015
 *
 * ---------------------------------------------------------------------------- */
.chat-list,
.chat-stacked {
  max-height: 520px;
  overflow: auto;
}
.chat-list .media-left {
  padding-right: 20px;
}
.chat-list .media-right {
  padding-left: 20px;
}
.chat-list .media-content {
  position: relative;
  padding: 10px 12px;
  width: auto;
  border-radius: 3px;
  display: inline-block;
}
.chat-list .media-content:not([class*=bg-]) {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.chat-list .media-content > i {
  top: 0;
  margin: 2px;
}
.chat-list .reversed .media-content {
  text-align: left;
  color: #fff;
}
.chat-list .reversed .media-content:not([class*=bg-]) {
  background-color: #42A5F5;
  border-color: #42A5F5;
}
.chat-list .reversed .media-content > a {
  color: #fff;
}
.chat-list .reversed .media-body {
  text-align: right;
}
.chat-list .date-step.media {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  color: #999;
}
.chat-list .chat-thumbs img {
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: 100px;
  height: auto;
  max-width: 100%;
  display: inline-block;
  border-radius: 3px;
}
.chat-list .media-content:before,
.chat-list .reversed .media-content:before {
  content: '';
  left: -5px;
  top: 15px;
  position: absolute;
  margin-left: 0;
}
.chat-list .media-content:before {
  border-top: 5px solid transparent;
  border-right: 5px solid;
  border-right-color: inherit;
  border-bottom: 5px solid transparent;
}
.chat-list .reversed .media-content:before {
  left: auto;
  right: -5px;
  border-right: 0;
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-left-color: inherit;
  border-bottom: 5px solid transparent;
}
@media (min-width: 769px) {
  .chat-list .media {
    margin-right: 20%;
  }
  .chat-list .media.reversed {
    margin-right: 0;
    margin-left: 20%;
  }
}
.chat-list-inverse .media-content:not([class*=bg-]) {
  color: #fff;
  background-color: #42A5F5;
  border-color: #42A5F5;
}
.chat-list-inverse .media-content:before {
  border-right: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid #42A5F5;
  border-bottom: 5px solid transparent;
}
.chat-list-inverse .media-content > a {
  color: #fff;
}
.chat-list-inverse .reversed .media-content:not([class*=bg-]) {
  background-color: #f5f5f5;
  color: inherit;
}
.chat-list-inverse .reversed .media-content:before {
  border-top: 5px solid transparent;
  border-left: 5px solid #f5f5f5;
  border-bottom: 5px solid transparent;
}
/* ------------------------------------------------------------------------------
 *
 *  # Search
 *
 *  Styles for search results and input
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.panel .main-search input[type=text] {
  background-color: #FAFAFA;
}
.search-results-list .list-inline {
  margin-bottom: 5px;
}
.related-searches {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.website-search-results {
  padding: 20px;
  padding-bottom: 0;
  border-bottom: 1px solid #eeeeee;
}
.media-preview {
  width: auto;
  height: auto;
  max-width: none;
  max-height: 90px;
}
@media (max-width: 768px) {
  .search-option-buttons {
    text-align: center;
  }
  .search-option-buttons > .text-right {
    text-align: inherit;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Error pages
 *
 *  Styles for error and offline pages
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.error-title {
  color: #fff;
  font-size: 200px;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 300;
  text-stroke: 1px transparent;
  display: block;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
@media (max-width: 768px) {
  .error-title {
    font-size: 130px;
  }
}
.offline-title {
  font-size: 160px;
}
@media (max-width: 768px) {
  .offline-title {
    font-size: 90px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Heading elmeents
*
*  Display default and custom components in page header and panel heading
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.heading-elements-toggle {
  cursor: pointer;
  display: block;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
}
.breadcrumb-elements-toggle {
  float: right;
  cursor: pointer;
  line-height: 1;
  margin-top: 12px;
}
.breadcrumb-line-component .breadcrumb-elements-toggle {
  margin-right: 20px;
}
.heading-elements-toggle,
.breadcrumb-elements-toggle,
.heading-elements-toggle:hover,
.breadcrumb-elements-toggle:hover,
.heading-elements-toggle:focus,
.breadcrumb-elements-toggle:focus {
  color: inherit;
}
.heading-elements-toggle > i,
.breadcrumb-elements-toggle > i {
  top: 0;
}
@media (min-width: 769px) {
  .heading-elements-toggle,
  .breadcrumb-elements-toggle {
    display: none;
  }
}
.heading-elements {
  background-color: inherit;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 36px;
  margin-top: -18px;
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible) {
    position: static;
    margin-top: 0;
    height: auto;
  }
}
.panel-body > .heading-elements {
  top: 0;
  margin-top: 14px;
  z-index: 10;
}
@media (max-width: 768px) {
  .panel-body > .heading-elements-toggle {
    top: 20px;
    margin-top: 0;
  }
  .panel-body > .heading-elements.visible-elements {
    top: 56px;
    background-color: #fff;
  }
}
.panel-footer > .heading-elements {
  position: static;
  margin-top: 0;
  padding-right: 20px;
}
.panel-footer > .heading-elements:after {
  content: '';
  display: table;
  clear: both;
}
.panel-footer-bordered > .heading-elements:not(.visible-elements) {
  margin-left: -20px;
  padding-right: 0;
}
@media (max-width: 768px) {
  .panel-footer > .heading-elements:not(.not-collapsible) > .pull-right {
    float: none!important;
  }
  .panel-footer .heading-elements-toggle {
    position: static;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0;
    display: block;
    text-align: center;
  }
}
.heading-thumbnails {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
}
.heading-thumbnails > li {
  position: relative;
  display: inline-block;
  font-size: 13px;
}
.heading-thumbnails > li + li {
  margin-left: 10px;
}
.heading-thumbnails > li img {
  height: auto;
  max-height: 36px;
  max-width: 100%;
  border-radius: 100px;
}
.heading-thumbnails > li .label,
.heading-thumbnails > li .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  border: 2px solid #fcfcfc;
}
.panel-default > .panel-heading .heading-thumbnails > li .label,
.panel-default > .panel-heading .heading-thumbnails > li .badge,
.panel-footer-transparent .heading-thumbnails > li .label,
.panel-footer-transparent .heading-thumbnails > li .badge {
  border-color: #fff;
}
.heading-thumbnails > li .status-mark {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 0 0 2px #fcfcfc;
}
.heading-thumbnails > li .status-mark[class*=border-] {
  background-color: #fcfcfc;
}
.panel-default > .panel-heading .heading-thumbnails > li .status-mark[class*=border-],
.panel-footer-transparent .heading-thumbnails > li .status-mark[class*=border-] {
  background-color: #fff;
}
.panel-default > .panel-heading .heading-thumbnails > li .status-mark,
.panel-footer-transparent .heading-thumbnails > li .status-mark {
  background-color: #fff;
}
.heading-elements .heading-btn + .heading-btn,
.page-header .heading-elements .icons-list > li + li,
.thumbnail-heading .heading-elements .icons-list > li + li {
  margin-left: 10px;
}
.page-header-inverse .heading-elements .btn-link {
  color: #fff;
}
.heading-elements .list-inline {
  margin-bottom: 0;
}
.heading-elements .breadcrumb {
  padding-top: 0;
  padding-bottom: 0;
}
.heading-elements.panel-nav .nav > li > a {
  text-align: left;
}
.heading-elements .daterange-custom {
  margin-top: 4px;
}
.heading-elements .heading-btn-group {
  font-size: 0;
}
.heading-elements .heading-btn-group > .btn + .btn:not(.btn-link) {
  margin-left: 10px;
}
.heading-elements.visible-elements {
  text-align: center;
}
.heading-elements.visible-elements .heading-text,
.heading-elements.visible-elements .heading-btn,
.heading-elements.visible-elements .heading-btn-group > .btn,
.heading-elements.visible-elements .ui-slider,
.heading-elements.visible-elements .noui-slider,
.heading-elements.visible-elements .nav-tabs,
.heading-elements.visible-elements .nav-pills,
.heading-elements.visible-elements .pagination,
.heading-elements.visible-elements .progress,
.heading-elements.visible-elements .icons-list,
.heading-elements.visible-elements .pager,
.heading-elements.visible-elements .breadcrumb,
.heading-elements.visible-elements .daterange-custom,
.heading-elements.visible-elements .heading-thumbnails,
.heading-elements.visible-elements .heading-form .form-group,
.heading-elements.visible-elements > .btn-group {
  margin-top: 20px;
}
.heading-elements.visible-elements .nav-tabs,
.heading-elements.visible-elements .nav-pills {
  margin-bottom: 0;
}
.heading-elements.visible-elements .heading-text:not(.label):not(.badge) {
  display: block;
}
.heading-elements.visible-elements .select2-container,
.heading-elements.visible-elements .selectboxit-container,
.heading-elements.visible-elements .selectboxit-options,
.heading-elements.visible-elements .multiselect + .btn-group,
.heading-elements.visible-elements div.bootstrap-select {
  width: 100%!important;
}
.heading-elements.visible-elements .input-group,
.heading-elements.visible-elements .input-group-btn,
.heading-elements.visible-elements .btn-group,
.heading-elements.visible-elements .dropdown,
.heading-elements.visible-elements .dropup {
  position: static;
}
.heading-elements.visible-elements .dropdown-menu:not(.multiselect-container) {
  left: -1px;
  right: -1px;
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.heading-elements.visible-elements .multiselect-container {
  left: 0;
  right: 0;
}
.heading-elements:not(.visible-elements) .heading-text,
.heading-elements:not(.visible-elements) .heading-btn,
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider,
.heading-elements:not(.visible-elements) .nav-tabs,
.heading-elements:not(.visible-elements) .nav-pills,
.heading-elements:not(.visible-elements) .pagination,
.heading-elements:not(.visible-elements) .progress,
.heading-elements:not(.visible-elements) .icons-list,
.heading-elements:not(.visible-elements) .breadcrumb,
.heading-elements:not(.visible-elements) .pager,
.heading-elements:not(.visible-elements) .heading-form,
.heading-elements:not(.visible-elements) .daterange-custom,
.heading-elements:not(.visible-elements) .heading-thumbnails,
.heading-elements:not(.visible-elements) > .btn-group {
  float: left;
  margin-left: 20px;
}
.heading-elements:not(.visible-elements) .heading-text {
  display: inline-block;
}
.heading-elements:not(.visible-elements) .heading-text + .heading-text {
  margin-left: 20px;
}
.heading-elements:not(.visible-elements) .selectbox-fixed + .selectboxit-container,
.heading-elements:not(.visible-elements) .selectbox-fixed + .selectboxit-options,
.heading-elements:not(.visible-elements) .progress,
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider,
.heading-elements:not(.visible-elements) .input-group,
.heading-elements:not(.visible-elements) .selectboxit-container .selectboxit-options,
.heading-elements:not(.visible-elements) .uploader,
.heading-elements:not(.visible-elements) .heading-form .form-control {
  width: 220px;
}
.heading-elements:not(.visible-elements) .select-sm,
.heading-elements:not(.visible-elements) .input-sm,
.heading-elements:not(.visible-elements) .input-group-sm,
.heading-elements:not(.visible-elements) .uploader-sm,
.heading-elements:not(.visible-elements) .pagination-sm,
.heading-elements:not(.visible-elements) .pager-sm,
.heading-elements:not(.visible-elements) .selectbox-sm + .selectboxit-container,
.heading-elements:not(.visible-elements) .btn-sm,
.heading-elements:not(.visible-elements) .btn-group-sm > .btn {
  margin-top: 1px;
}
.heading-elements:not(.visible-elements) .select-xs,
.heading-elements:not(.visible-elements) .input-xs,
.heading-elements:not(.visible-elements) .input-group-xs,
.heading-elements:not(.visible-elements) .uploader-xs,
.heading-elements:not(.visible-elements) .pagination-xs,
.heading-elements:not(.visible-elements) .pager-xs,
.heading-elements:not(.visible-elements) .selectbox-xs + .selectboxit-container,
.heading-elements:not(.visible-elements) .btn-xs,
.heading-elements:not(.visible-elements) .btn-group-xs > .btn {
  margin-top: 2px;
}
.heading-elements:not(.visible-elements) .btn-float {
  margin-top: -8px;
}
.heading-elements:not(.visible-elements) .btn-float.has-text {
  margin-top: -19.5px;
}
.heading-elements:not(.visible-elements) .btn-float.btn-link {
  margin-top: -14px;
}
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider {
  margin-top: 15px;
}
.heading-elements:not(.visible-elements) .ui-slider-lg,
.heading-elements:not(.visible-elements) .noui-slider-lg {
  margin-top: 14px;
}
.heading-elements:not(.visible-elements) .ui-slider-sm,
.heading-elements:not(.visible-elements) .noui-slider-sm {
  margin-top: 16px;
}
.heading-elements:not(.visible-elements) .ui-slider-xs,
.heading-elements:not(.visible-elements) .noui-slider-xs {
  margin-top: 17px;
}
.heading-elements:not(.visible-elements) .progress {
  margin-top: 9px;
}
.heading-elements:not(.visible-elements) .progress-lg {
  margin-top: 7px;
}
.heading-elements:not(.visible-elements) .progress-sm {
  margin-top: 11px;
}
.heading-elements:not(.visible-elements) .progress-xs {
  margin-top: 13px;
}
.heading-elements:not(.visible-elements) .progress-xxs {
  margin-top: 15px;
}
.heading-elements:not(.visible-elements) .progress-micro {
  margin-top: 17px;
}
.heading-elements:not(.visible-elements) .icons-list {
  margin-top: 10px;
}
.heading-elements:not(.visible-elements) .heading-text {
  margin-top: 8px;
}
.heading-elements:not(.visible-elements).panel-nav > .nav {
  margin-bottom: 0;
}
@media (min-width: 769px) {
  .heading-elements:not(.visible-elements).panel-nav > .nav {
    margin-top: -1px;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-tabs {
    border-bottom-width: 0;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-tabs.nav-tabs-bottom > li.active > a:after {
    background-color: transparent;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-sm {
    margin-top: 2px;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-xs {
    margin-top: 5px;
  }
}
@media (max-width: 768px) {
  .heading-elements:not(.visible-elements).panel-nav {
    position: static;
    height: auto;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav {
    float: none;
  }
  .panel-heading .heading-elements:not(.visible-elements).panel-nav {
    margin-top: 15px;
  }
  .panel-flat .panel-heading .heading-elements:not(.visible-elements).panel-nav {
    margin-top: 20px;
  }
  .panel-heading .heading-elements:not(.visible-elements).panel-nav > .nav {
    margin-left: 0;
  }
  .panel-footer .heading-elements:not(.visible-elements).panel-nav.not-collapsible > .nav {
    margin-top: 13px;
    margin-bottom: 13px;
  }
}
.heading-form .form-group {
  margin-bottom: 0;
}
.heading-form .checkbox-switchery,
.heading-form .checkbox-switchery[class*="switchery-"] {
  margin-bottom: 0;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-inline,
.heading-elements:not(.visible-elements) .heading-form .radio-inline {
  margin-top: 8px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch {
  margin-top: 2px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch-sm {
  margin-top: 3px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch-xs {
  margin-top: 4px;
}
.heading-elements:not(.visible-elements) .heading-form .input-group {
  max-width: 220px;
}
.heading-elements:not(.visible-elements) .heading-form .input-group .form-control {
  width: 100%;
  margin-top: 0;
}
.heading-elements:not(.visible-elements) .heading-form .input-group.input-group-sm .btn,
.heading-elements:not(.visible-elements) .heading-form .input-group.input-group-xs .btn {
  margin-top: 0;
}
@media (min-width: 769px) {
  .heading-form .form-group {
    float: left;
  }
  .heading-form .form-group + .form-group {
    margin-left: 15px;
  }
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible),
  .breadcrumb-elements:not(.not-collapsible) {
    display: none;
  }
  .heading-elements.visible-elements,
  .breadcrumb-elements.visible-elements {
    display: block;
  }
  .panel .has-visible-elements {
    padding-bottom: 0;
  }
  .panel-flat .panel-heading.has-visible-elements {
    padding-bottom: 20px;
  }
  .panel .visible-elements {
    border-top: 1px solid #ddd;
    padding: 20px;
    padding-top: 0;
  }
  .panel-heading .visible-elements {
    margin: 15px -20px 0 -20px;
    background-color: #fcfcfc;
  }
  .panel-flat .panel-heading .visible-elements {
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
  }
  .panel-footer .visible-elements {
    margin-top: 7px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel-footer-condensed .visible-elements {
    margin-top: 2px;
  }
  .panel-footer-transparent .visible-elements {
    margin-top: 12px;
    background-color: #fff;
  }
  .panel[class*=bg-] .visible-elements,
  .panel-heading[class*=bg-] .visible-elements,
  .panel-primary .visible-elements,
  .panel-danger .visible-elements,
  .panel-success .visible-elements,
  .panel-warning .visible-elements,
  .panel-info .visible-elements {
    border-color: rgba(255, 255, 255, 0.2);
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .visible-elements.breadcrumb-elements.not-collapsible {
    display: none;
  }
  .page-header-content > .visible-elements {
    background-color: #f5f5f5;
    left: 0;
    right: 0;
    border-top: 1px solid #dcdcdc;
    z-index: 1002;
    padding-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-header-default .page-header-content > .visible-elements {
    background-color: #fcfcfc;
    border-color: #ddd;
  }
  .page-header-inverse .page-header-content > .visible-elements {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
  }
}
/* ------------------------------------------------------------------------------
*
*  # Helper classes
*
*  Custom helper classes
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.no-edge-top {
  top: 0;
}
.no-edge-bottom {
  bottom: 0;
}
.no-edge-left {
  left: 0;
}
.no-edge-right {
  right: 0;
}
@media (min-width: 1200px) {
  .pull-right-lg {
    float: right;
  }
}
@media (min-width: 1025px) {
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 769px) {
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 480px) {
  .pull-right-xs {
    float: right;
  }
}
.valign-top {
  vertical-align: top;
}
.valign-middle {
  vertical-align: middle;
}
.valign-bottom {
  vertical-align: bottom;
}
.valign-baseline {
  vertical-align: baseline;
}
.valign-text-top {
  vertical-align: top;
}
.valign-text-bottom {
  vertical-align: text-bottom;
}
.position-relative {
  position: relative;
}
.position-static {
  position: static;
}
.display-block,
label.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-default {
  cursor: default;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-auto {
  overflow: auto;
}
.content-group-xs {
  margin-bottom: 10px !important;
}
.content-group-sm {
  margin-bottom: 15px !important;
}
.content-group {
  margin-bottom: 20px !important;
}
.content-group-lg {
  margin-bottom: 30px !important;
}
.no-margin {
  margin: 0!important;
}
.no-margin-top {
  margin-top: 0!important;
}
.no-margin-bottom {
  margin-bottom: 0!important;
}
.no-margin-left {
  margin-left: 0!important;
}
.no-margin-right {
  margin-right: 0!important;
}
.m-5 {
  margin: 5px!important;
}
.m-10 {
  margin: 10px!important;
}
.m-15 {
  margin: 15px!important;
}
.m-20 {
  margin: 20px!important;
}
.mt-5 {
  margin-top: 5px!important;
}
.mt-10 {
  margin-top: 10px!important;
}
.mt-15 {
  margin-top: 15px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mb-5 {
  margin-bottom: 5px!important;
}
.mb-10 {
  margin-bottom: 10px!important;
}
.mb-15 {
  margin-bottom: 15px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.ml-5 {
  margin-left: 5px!important;
}
.ml-10 {
  margin-left: 10px!important;
}
.ml-15 {
  margin-left: 15px!important;
}
.ml-20 {
  margin-left: 20px!important;
}
.mr-5 {
  margin-right: 5px!important;
}
.mr-10 {
  margin-right: 10px!important;
}
.mr-15 {
  margin-right: 15px!important;
}
.mr-20 {
  margin-right: 20px!important;
}
.no-padding {
  padding: 0!important;
}
.no-padding-top {
  padding-top: 0!important;
}
.no-padding-bottom {
  padding-bottom: 0!important;
}
.no-padding-left {
  padding-left: 0!important;
}
.no-padding-right {
  padding-right: 0!important;
}
.p-5 {
  padding: 5px!important;
}
.p-10 {
  padding: 10px!important;
}
.p-15 {
  padding: 15px!important;
}
.p-20 {
  padding: 20px!important;
}
.pt-5 {
  padding-top: 5px!important;
}
.pt-10 {
  padding-top: 10px!important;
}
.pt-15 {
  padding-top: 15px!important;
}
.pt-20 {
  padding-top: 20px!important;
}
.pb-5 {
  padding-bottom: 5px!important;
}
.pb-10 {
  padding-bottom: 10px!important;
}
.pb-15 {
  padding-bottom: 15px!important;
}
.pb-20 {
  padding-bottom: 20px!important;
}
.pl-5 {
  padding-left: 5px!important;
}
.pl-10 {
  padding-left: 10px!important;
}
.pl-15 {
  padding-left: 15px!important;
}
.pl-20 {
  padding-left: 20px!important;
}
.pr-5 {
  padding-right: 5px!important;
}
.pr-10 {
  padding-right: 10px!important;
}
.pr-15 {
  padding-right: 15px!important;
}
.pr-20 {
  padding-right: 20px!important;
}
.no-border {
  border: 0!important;
}
.no-border-top {
  border-top: 0!important;
}
.no-border-bottom {
  border-bottom: 0!important;
}
.no-border-left {
  border-left: 0!important;
}
.no-border-right {
  border-right: 0!important;
}
.no-border-radius {
  border-radius: 0;
}
.no-border-radius-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.no-border-radius-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.border-top {
  border-top: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.border-right {
  border-right: 1px solid;
}
.border-lg {
  border-width: 2px;
}
.border-top-lg {
  border-top: 2px solid;
}
.border-bottom-lg {
  border-bottom: 2px solid;
}
.border-left-lg {
  border-left: 2px solid;
}
.border-right-lg {
  border-right: 2px solid;
}
.border-xlg {
  border-width: 3px;
}
.border-top-xlg {
  border-top: 3px solid;
}
.border-bottom-xlg {
  border-bottom: 3px solid;
}
.border-left-xlg {
  border-left: 3px solid;
}
.border-right-xlg {
  border-right: 3px solid;
}
.full-width {
  width: 100%;
}
@media (min-width: 769px) {
  .width-200 {
    min-width: 200px;
  }
  .width-250 {
    min-width: 250px;
  }
  .width-300 {
    min-width: 300px;
  }
  .width-350 {
    min-width: 350px;
  }
  .width-400 {
    min-width: 400px;
  }
  .width-450 {
    min-width: 450px;
  }
  .width-500 {
    min-width: 500px;
  }
  .width-550 {
    min-width: 550px;
  }
  .width-600 {
    min-width: 600px;
  }
  .width-650 {
    min-width: 650px;
  }
}
.img-lg {
  width: 44px!important;
  height: 44px!important;
}
.img-sm {
  width: 36px!important;
  height: 36px!important;
}
.img-xs {
  width: 32px!important;
  height: 32px!important;
}
/* ------------------------------------------------------------------------------
*
*  # Snippets
*
*  Custom snippets set
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.progress-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.progress-list > li + li {
  margin-top: 20px;
}
.progress-list > li > label {
  display: block;
}
.progress-list > li span {
  float: right;
  display: block;
}
