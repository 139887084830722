/* ------------------------------------------------------------------------------
 *
 *  # Google Maps overrides
 *
 *  Override Google Maps default styles
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */


// Container
.map-container {
	height: 500px;
}

// Override popover text styles
.gm-style {
	font-size: @font-size-base;

	.gm-style-iw {
		font-size: @font-size-base;
		font-weight: 400;
	}
}
